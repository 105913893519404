import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams }  from '@ionic/angular';

import { SensoryProfileUI } from '../sensory-profile-ui';

import { ProfileSensoryProfile } from '../../../entities/profile-sensory-profile';
import { ProfileSensoryProfileQuestion } from '../../../entities/profile-sensory-profile-question';

@Component({
  selector: 'app-sensory-profile-modal',
  templateUrl: './sensory-profile-modal.component.html',
  styleUrls: ['./sensory-profile-modal.component.scss'],
})
export class SensoryProfileModalComponent implements OnInit {


  public sensoryProfile: ProfileSensoryProfile;
  public questions: ProfileSensoryProfileQuestion[] = [];

  public modalTitle: string;
  public modalIcon: string;

  // *** C
  constructor(

    private modalCtrl: ModalController,
    private navParams: NavParams,
  ) { }


  // ** Lifecycle ** //
  ngOnInit() {

    this.initSensoryProfileType();
  }


  // ** sensory-profile type set **//
  private initSensoryProfileType() {

    this.sensoryProfile =  this.navParams.get('pSensoryProfile');
    this.questions = this.sensoryProfile.questions;

    this.modalTitle = this.sensoryProfile.sensoryProfile.title;

    let sensoryProfileType = this.sensoryProfile.sensoryProfile.type;
    let sensoryProfileSpec = this.sensoryProfile.sensoryProfile.spec;


    this.modalIcon =
      SensoryProfileUI.SENSORY_PROFILES[sensoryProfileType][sensoryProfileSpec].icon;



  }

  // *** modal UI *** //
  public onClickCloseModal() {
    this.modalCtrl.dismiss();
  }

}
