import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';

import { RCsService } from './rcs.service';

@Injectable({
  providedIn: 'root'
})
export class RCsOpsService {

  private selected: string[] = [];
  private selectionActive: boolean = false;
  private selectionActive$ = new Subject<any>();
  private selection$ = new Subject<any>();

  private rmActive: boolean = false;
  private rmOpStatus$ = new Subject<any>();

  // *** C
  constructor(
    private rcsS: RCsService
  ) {}


  // *** operations toogle
  public toogleRMOp() {

    this.rmActive = !this.rmActive;
  }


  // *** operations status
  public getRMOpStatus() {

    return this.rmActive;
  }


  // *** operations
  public rmSelected$() {

    return new Promise((resolve, reject) => {

      this.rcsS.removeRCs$( this.selected )
      .then(
        () => {

          this.selected = [];

          this.selection$.next( false);

          resolve();
        }
      )
      .catch((err) => {

        reject(err);
      });

    });

  }


  // *** selection mode
  public toogleSelectionActive() {

    this.selectionActive = !this.selectionActive;

    if( !this.selectionActive ) {

      this.selected = [];
    }

    this.selectionActive$.next( this.selectionActive );
  }

  public isSelectionActive() {

    return this.selectionActive;
  }


  // *** selection ops
  public selectRC( rcID ) {

    this.selected.push( rcID );

    this.selection$.next( true );
  }

  public unselectRC( rcID ) {

    var index = this.selected.indexOf( rcID );

    if (index > -1) {

      this.selected.splice(index, 1);

      this.selection$.next( false );
    }
  }

  public isSelected( rcID ) {

    let selected = false;

    var index = this.selected.indexOf( rcID );

    if (index > -1) {

      selected = true;
    }

    return selected;

  }

  public toogleRCSelection( rcID ) {

    if( this.isSelected( rcID )) {

      this.unselectRC( rcID );

    } else {

      this.selectRC( rcID );
    }
  }

  public getSelectedCount() {

    return this.selected.length;
  }

  public resetSelectionActive() {

    this.selectionActive = false;

    this.selectionActive$.next( this.selectionActive );
  }

  // *** observables
  public getSelectionActive$() {

    return this.selectionActive$.asObservable();
  }

  public getRMOpStatus$() {

    return this.rmOpStatus$.asObservable();
  }

  public getSelection$() {

    return this.selection$.asObservable();
  }
}
