import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { NavigationService } from '../../../services/navigation.service';
import { Paths } from '../../../navigation/paths';

@Component({
  selector: 'app-view-header',
  templateUrl: './view-header.component.html',
  styleUrls: ['./view-header.component.scss'],
})
export class ViewHeaderComponent implements OnInit, OnDestroy {

  public visibleHeader: Boolean = false;
  public visibleMainHeader: Boolean = true;
  public visibleProfileHeader: Boolean = true;
  private $currentURL: Subscription;

  // *** C *** //
  constructor(
    private navS: NavigationService
  ) {

    this.setNavEventHandlers();
  }


  // *** Lifecycle events ** //
  ngOnInit() {}

  ngOnDestroy() {
        // unsubscribe to ensure no memory leaks¡
        this.$currentURL.unsubscribe();
  }



  // *** PRIVATE *** //
  private setNavEventHandlers() {

    // src: https://jasonwatmore.com/post/2019/02/07/angular-7-communicating-between-components-with-observable-subject
    // on Current URL changes
    this.$currentURL = this.navS
    .getCurrentURLObservable()
    .subscribe( url => {
      this.onCurrentURLChange( url );
    });

  }

  private onCurrentURLChange( url ) {

    if ( url ) {
      switch( url ) {

        case Paths.ACCESS:
        case Paths.ERROR:
          this.visibleHeader = false;
          break;

        case Paths.MEMBERS + '/' + Paths.PROFILES:
          this.visibleHeader = true;
          this.visibleMainHeader = true;
          this.visibleProfileHeader = false;
          break;

        case Paths.MEMBERS + '/' + Paths.RECORDS + '/' + Paths.RECORDS_DAILY:
        case Paths.MEMBERS + '/' + Paths.RECORDS + '/'
            + Paths.RECORDS_DAILY + '/' + Paths.NOT_TODAY:
        case Paths.MEMBERS + '/' + Paths.RECORDS + '/' + Paths.RECORDS_WEEKLY:
        case Paths.MEMBERS + '/' + Paths.INTERVENTIONS:
        case Paths.MEMBERS + '/' + Paths.INTERVENTIONS + '/' + Paths.INTERVENTIONS_MAIN:
        case Paths.MEMBERS + '/' + Paths.PROGRESS:
        case Paths.MEMBERS + '/' + Paths.PROGRESS + '/' + Paths.PROGRESS_MAIN:
        case Paths.MEMBERS + '/' + Paths.SENSORY_PROFILES:
        case Paths.MEMBERS + '/' + Paths.SENSORY_PROFILES + '/' + Paths.SENSORY_PROFILES_MAIN:
        case Paths.MEMBERS + '/' + Paths.INTERESTS:
        case Paths.MEMBERS + '/' + Paths.INTERESTS + '/' + Paths.INTERESTS_MAIN:
        case Paths.MEMBERS + '/' + Paths.DOCUMENTS:
        case Paths.MEMBERS + '/' + Paths.DOCUMENTS + '/' + Paths.DOCUMENTS_MAIN:
        case Paths.MEMBERS + '/' + Paths.GUIDES:
        case Paths.MEMBERS + '/' + Paths.GUIDES + '/' + Paths.GUIDES_MAIN:
        case Paths.MEMBERS + '/' + Paths.RC + '/' + Paths.RC_DAILY:
        case Paths.MEMBERS + '/' + Paths.RC + '/'
            + Paths.RC_DAILY + '/' + Paths.NOT_TODAY:
        case Paths.MEMBERS + '/' + Paths.RC + '/' + Paths.RC_WEEKLY:
          this.visibleHeader = true;
          this.visibleMainHeader = false;
          this.visibleProfileHeader = true;
          break;

        default:
          this.visibleHeader = false;
          this.visibleMainHeader = false;
          this.visibleProfileHeader = false;

          this.setHeadersVisibility( url );

          break;

      }

    }
  }

  private setHeadersVisibility( url ) {

    if( url.indexOf( Paths.PROFILE + '/' ) > -1 ) {

        this.visibleHeader = true;
        this.visibleMainHeader = false;
        this.visibleProfileHeader = true;

    }


  }

}
