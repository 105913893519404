import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
})
@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
})
export class MainHeaderComponent implements OnInit {


  // *** C *** //
  constructor(
    public alertCtrl: AlertController
  ) { }

  // ** Lifecycle events ** //
  ngOnInit() {}


  // *** PRIVATE *** //
  public async showAlert() {

    const alert = await this.alertCtrl.create({
      header: 'CONFIGURACIÓN',
      subHeader: 'REGTEA proto 001',
      message: 'Centro de configuración de la aplicación',
      buttons: ['IR']
    });

    await alert.present();
  }

}
