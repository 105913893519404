import { Component, OnInit, Input } from '@angular/core';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';

import { Document } from '../../../entities/document';
import { DocumentClass } from '../../../entities/document-class';

import { Platform }  from '@ionic/angular';

@Component({
  selector: 'app-document-card',
  templateUrl: './document-card.component.html',
  styleUrls: ['./document-card.component.scss'],
})
export class DocumentCardComponent implements OnInit {

  // *** document
  @Input() document: Document;


  public documentClass: number;
  public icon: String;

  private fileTransfer: FileTransferObject;

  // *** C
  constructor(
    private transfer: FileTransfer,
    private file: File,
    private platform: Platform
  ) {


  }


  // *** lifecycle
  ngOnInit() {

    this.setDocumentClass();

    this.setIcon();
  }



  // *** document
  private setDocumentClass() {

    this.documentClass = this.document.class;

  }


  // *** icon
  private setIcon() {

    switch( this.documentClass ) {

      case DocumentClass.UNDEFINED:
        this.icon = 'undefined-type-document';
        break;
      case DocumentClass.MEDICAL:
        this.icon = 'medical-report';
        break;
      case DocumentClass.PSICOPEDAGOGIC:
        this.icon = 'psicopedagogic-report'
        break;
      case DocumentClass.SCHOOL:
        this.icon = 'school-report';
        break;
    }
  }


  // *** download
  public onClickCard() {

    this.downloadURL();
  }


  // src: https://forum.ionicframework.com/t/ionic-4-others-methods-to-download-files/163042/19
  private downloadURL() {

    const url = this.document.url;

    if (this.platform.is('cordova')) {

      this.fileTransfer = this.transfer.create();
  		this.file.checkDir(this.file.externalRootDirectory, 'downloads')
  		.then(
  			// Directory exists, check for file with the same name
  			_ => this.file.checkFile(this.file.externalRootDirectory, 'downloads/' + name + '.geojson')
  			.then(_ => {alert("A file with the same name already exists!")})
  			// File does not exist yet, we can save normally
  			.catch(err =>
  				this.fileTransfer.download(url, this.file.externalRootDirectory + '/downloads/' + name + '.geojson').then((entry) => {
  			    	alert('File saved in:  ' + entry.nativeURL);
  			    })
  			    .catch((err) =>{
  			    	alert('Error saving file: ' + err.message);
  			    })
  			))
  		.catch(
  			// Directory does not exists, create a new one
  			err => this.file.createDir(this.file.externalRootDirectory, 'downloads', false)
  			.then(response => {
  				alert('New folder created:  ' + response.fullPath);
  			    this.fileTransfer.download(url, this.file.externalRootDirectory + '/downloads/' + name + '.geojson').then((entry) => {
  			    	alert('File saved in : ' + entry.nativeURL);
  			    })
  			    .catch((err) =>{
  			    	alert('Error saving file:  ' + err.message);
  			    });

  			}).catch(err => {
  				alert('It was not possible to create the dir "downloads". Err: ' + err.message);
  			})
  		);

  	} else {
  		// If downloaded by Web Browser
  		let link = document.createElement("a");
      link.target = '_blank';
  		link.download = 'file.pdf';
  		link.href = url;
  		document.body.appendChild(link);
  		link.click();
  		document.body.removeChild(link);
  		link = null;
  	}

  }
}
