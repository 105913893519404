import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { Profile } from '../../../entities/profile';

import { ProfilesService } from '../../../services/profiles.service';

@Component({
  selector: 'app-profile-tools',
  templateUrl: './profile-tools.component.html',
  styleUrls: ['./profile-tools.component.scss'],
})
export class ProfileToolsComponent implements OnInit {

  public profile: Profile;

  private $currentProfile: Subscription;

  private editionEnabled: boolean;

  // ** C ** //
  constructor(
    private profilesS: ProfilesService
  ) {

    this.setNavEventHandlers();

  }

  // ** Lifecycle events ** //
  ngOnInit() {

    //this.profile = this.profilesS.getCurrentProfile();


  }

  ngOnDestroy() {
        // unsubscribe to ensure no memory leaks¡
        this.$currentProfile.unsubscribe();
  }



  // *** PRIVATE *** //
  private setNavEventHandlers() {

    // src: https://jasonwatmore.com/post/2019/02/07/angular-7-communicating-between-components-with-observable-subject
    // on Current URL changes
    this.$currentProfile = this.profilesS
    .getCurrentProfileObservable()
    .subscribe( profile => {
      this.onCurrentProfileChange( profile );
    });

  }

  private onCurrentProfileChange( profile ) {


    if ( profile ) {

      this.profile = profile;

      this.editionEnabled = this.profilesS.isCurrentUserEditor();

    }
  }


}
