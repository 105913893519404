import { Component, OnInit } from '@angular/core';
import { ModalController }  from '@ionic/angular';

import { CESFRecordModal } from '../control-esfinter-record-modal/control-esfinter-record-modal.component';
import { BehaviorRecordModal } from '../behavior-record-modal/behavior-record-modal.component';
import { ActionRecordModal } from '../action-record-modal/action-record-modal.component';
import { EfaRecordModal } from '../efa-record-modal/efa-record-modal.component';
import { EventRecordModal } from '../event-record-modal/event-record-modal.component';
import { CustomRecordModal } from '../custom-record-modal/custom-record-modal.component';

import { EfaStateType }from '../../../entities/efa-state-type';

@Component({
  selector: 'app-record-creation-center-modal',
  templateUrl: './record-creation-center-modal.component.html',
  styleUrls: ['./record-creation-center-modal.component.scss'],
})
export class RecordCreationCenterModalComponent implements OnInit {


  private modal: any;


  // *** C
  constructor(
    private modalCtrl: ModalController
  ) { }

  // *** Lifecycle
  ngOnInit() {}


  // *** modal
  public onClickCloseModal() {

    this.modalCtrl.dismiss();

  }


  // *** CESF
  public onClickCESF() {

    this.openCESFRecordModal();

  }

  private async openCESFRecordModal() {

    this.modal = await this.modalCtrl.create({
      component: CESFRecordModal
    })

    this.modal.onDidDismiss()
    .then(
      ( data ) => {

       if ( data.data && data.data.error ) {
         this.modalCtrl.dismiss();
       }

    });

    await this.modal.present();
  }


  // *** Behavior
  public onClickBehavior() {

    this.openCBehaviorRecordModal();

  }

  private async openCBehaviorRecordModal() {

    this.modal = await this.modalCtrl.create({
      component: BehaviorRecordModal
    })

    this.modal.onDidDismiss()
    .then(
      ( data ) => {

       if ( data.data && data.data.error ) {
         this.modalCtrl.dismiss();
       }

    });

    await this.modal.present();
  }


  // *** action
  public onClickAction() {

    this.openActionRecordModal();
  }

  private async openActionRecordModal() {

    this.modal = await this.modalCtrl.create({
      component: ActionRecordModal
    })

    this.modal.onDidDismiss()
    .then(
      ( data ) => {

       if ( data.data && data.data.error ) {
         this.modalCtrl.dismiss();
       }

    });

    await this.modal.present();

  }


  // *** event
  public onClickEvent() {

    this.openEventRecordModal();
  }

  private async openEventRecordModal() {

    this.modal = await this.modalCtrl.create({
      component: EventRecordModal
    })

    this.modal.onDidDismiss()
    .then(
      ( data ) => {

       if ( data.data && data.data.error ) {
         this.modalCtrl.dismiss();
       }

    });

    await this.modal.present();

  }


  // *** custom
  public onClickCustom() {

    this.openCustomRecordModal();
  }

  private async openCustomRecordModal() {

    this.modal = await this.modalCtrl.create({
      component: CustomRecordModal
    })

    this.modal.onDidDismiss()
    .then(
      ( data ) => {

       if ( data.data && data.data.error ) {
         this.modalCtrl.dismiss();
       }

    });

    await this.modal.present();

  }


  // *** efa record
  private async openEfaRecordModal( efaType ) {

    this.modal = await this.modalCtrl.create({
      component: EfaRecordModal,
      componentProps: {
         efaType: efaType
      }
    })

    this.modal.onDidDismiss()
    .then(
      ( data ) => {

       if ( data.data && data.data.error ) {
         this.modalCtrl.dismiss();
       }

    });

    await this.modal.present();

  }


  // *** emotional
  public onClickEmotional() {

    this.openEfaRecordModal( EfaStateType.EMOTIONAL );
  }


  // *** physical
  public onClickPhysical() {

    this.openEfaRecordModal( EfaStateType.PHYSICAL );
  }


  // *** animical
  public onClickAnimical() {

    this.openEfaRecordModal( EfaStateType.ANIMICAL );
  }

}
