import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { ProgressService } from '../../../services/progress.service';
import { ProfilesService } from '../../../services/profiles.service';

import { ProfileProgressQuestion } from '../../../entities/profile-progress-question';

import { ProgressConf } from '../../../progress/progress-conf';

import { Paths } from '../../../navigation/paths';

@Component({
  selector: 'app-progress-question-card',
  templateUrl: './progress-question-card.component.html',
  styleUrls: ['./progress-question-card.component.scss'],
})
export class ProgressQuestionCardComponent implements OnInit {

  @Input() question: ProfileProgressQuestion;

  public max: number = ProgressConf.QUESTION_MAXIMUN_SCORE;

  public valorationTXT: string;

  public editionEnabled: boolean;


  // *** C
  constructor(
    private router: Router,
    private progressS: ProgressService,
    private profilesS: ProfilesService
  ) {

    this.setEditionEnabled();

  }


  // *** Lifecycle events
  ngOnInit() {

    this.updateValoration();

  }


  // *** actions
  public onIncrementValue() {

    if( this.profilesS.isCurrentUserEditor() && this.question.answerValue < 100 ) {

      this.question.answerValue += 33;

      this.updateValoration();

      this.updateQuestion();

    }
  }

  public onDecrementValue() {

    if( this.profilesS.isCurrentUserEditor() && this.question.answerValue > 0 ) {

      this.question.answerValue -= 33;

      this.updateValoration();

      this.updateQuestion();

    }
  }

  public updateValoration() {

    let answerValue = this.question.answerValue;

    if( answerValue === 0 ) {

      this.valorationTXT = 'no introducido';

    } else if( answerValue > 0 && answerValue <= 33 ) {

      this.valorationTXT = 'en proceso';

    } else if( answerValue > 25 && answerValue <= 66 ) {

      this.valorationTXT = 'adquirido';

    } else if( answerValue > 66 ) {

      this.valorationTXT = 'generalizado';
    }
  }


  // *** question
  private updateQuestion() {

    this.progressS.updateQuestion$( this.question )
    .then(
      (question) => {}
    )
    .catch(
      err => {
        let errJson = JSON.stringify(err);

        this.router.navigate(['/' + Paths.ERROR, errJson]);
      }
    )

  }


  // * edition permissions
  private setEditionEnabled() {

    this.editionEnabled = false;

    if( this.profilesS.isCurrentUserEditor() ) {

      this.editionEnabled = true;
    }

  }
}
