import { RecordValoration } from './record-valoration';

export class ActionRecordInfo {

  actionName: string;
  actionDescription?: string;
  actionReaction?: string;


  constructor(obj) {
    obj && Object.assign(this, obj);
  }


  public getValoration() {

    return RecordValoration.UNDEFINED;

  }
}
