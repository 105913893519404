import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {Observable, Subject} from 'rxjs';



@Injectable({
  providedIn: 'root'
})

export class RecordsOpsService {

  private selectedRecords = [];

  private removeRecordsActive$ = new Subject<any>();
  private removeRecordsActive: boolean = false;


  private recordsSelected$ = new Subject<any>();
  private recordsSelected: boolean = false;

  private calendarViewChanged$ = new Subject<any>();


  // *** C *** //
  constructor(
    public router: Router
  ) { }


  // *** selected records
  public getSelectedRecords() {

    return this.selectedRecords;

  }

  public pushSelectedRecords( recordID ) {

    this.selectedRecords.push( recordID );

    this.recordsSelected$.next( true );
    this.recordsSelected = true;

  }

  public popSelectedRecords( recordID ) {

    let index = this.selectedRecords.indexOf( recordID );

    if ( index !== -1 ) {

      this.selectedRecords.splice(index, 1);

      let recordSelected = this.selectedRecords.length > 0;

      this.recordsSelected$.next( recordSelected );
      this.recordsSelected = recordSelected;

    }

  }

  public resetSelectedRecords() {

    this.selectedRecords = [];

  }

  public isRecordSelected( recordID ) {

    let res = false;

    if( this.selectedRecords.includes(recordID ) ) {

      res = true;

    }

    return res;
  }

  public isAnyRecordSelected() {

    let res = false;

    if( this.selectedRecords.length > 0 ) {

      res = true;

    }

    return res;

  }

  public isRecordTypeSelected( type ) {

    let isSelected = false;

    this.selectedRecords.forEach(( record ) => {

      if( record.type !== type ) {

        isSelected = true;
      }

    });

    return isSelected;

  }

  // *** remove records OPS
  public getRemoveRecordsActiveObservable() {

    return this.removeRecordsActive$.asObservable();

  }

  public getRecordsSelected$Observable() {

    return this.recordsSelected$.asObservable();

  }

  public getRemoveRecordsActive() {

    return this.removeRecordsActive;

  }

  public toogleRemoveRecordsActive() {

    this.resetSelectedRecords();

    if( this.removeRecordsActive ) {

      this.removeRecordsActive = false;

    } else {

      this.removeRecordsActive = true;

    }

    this.removeRecordsActive$.next( this.removeRecordsActive );

  }

  // *** calendar views OPS
  public getCalendarViewChanged$() {

    return this.calendarViewChanged$.asObservable();

  }

  public toogleCalendarView( view ) {

    this.calendarViewChanged$.next( view );

  }

}
