import { Component, OnInit, Input } from '@angular/core';

import { RCInfoField } from '../../../entities/rc-info-field';
import { RCTInfoField } from '../../../entities/rct-info-field';
import { RCTemplate } from '../../../entities/rc-template';
import { RCTInfoFieldType } from '../../../entities/rct-info-field-type';

import { RCTemplatesCacheService } from '../../../cache/rc-templates-cache.service';


@Component({
  selector: 'app-rc-card-info-field',
  templateUrl: './rc-card-info-field.component.html',
  styleUrls: ['./rc-card-info-field.component.scss'],
})
export class RCCardInfoFieldComponent implements OnInit {

  @Input() infoField: RCInfoField;
  @Input() templateRef: string;
  @Input() index: number;

  public rctInfoField: RCTInfoField;
  public visible: boolean = false;

  public TXT: number = RCTInfoFieldType.TXT;
  public TXT_AREA: number = RCTInfoFieldType.TXT_AREA;
  public SELECT_ONE: number = RCTInfoFieldType.SELECT_ONE;
  public SELECT_MULTI: number = RCTInfoFieldType.SELECT_MULTI;

  // *** C
  constructor(
    private rcTemplatesCache: RCTemplatesCacheService
  ) { }


  // *** Lifecycle
  ngOnInit() {

    this.init();
  }


  // *** initialization
  private init() {

    this.initVisibility();
    this.initRCTInfoField();

  }

  private initVisibility() {

    if( this.infoField.name !=null && this.infoField.value != null ) {

      this.visible = true;

    }
  }


  // RCT Info field
  private initRCTInfoField() {

    if( this.visible ) {

      let template =
        new RCTemplate(
          this.rcTemplatesCache.getTemplate( this.templateRef ) );


      template.info.fields.forEach((rctField) => {

        if( rctField.name != null && this.infoField.name === rctField.name ) {

          this.rctInfoField = rctField;
        }

      });

    }
  }




}
