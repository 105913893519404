export class RCDefaultTemplates {

  static UNDEFINED = "UNDEF";
  static FOOD = 'FOOD';
  static DRINK = 'DRINK';
  static WC = 'WC';
  static SPORT = 'SPORT';
  static LEISURE = 'LEISURE';
  static INTERIOR_GAMES = 'INTERIOR_GAMES';
  static EXTERIOR_GAMES = 'EXTERIOR_GAMES';
  static SHOPPING = 'SHOPPING';
  static DISPLACEMENT = 'DISPLACEMENT';
  static SCHOOL_ACTIVITY = 'SCHOOL_ACTIVITY';
  static MEDICAMENT_DOSE = 'MEDICAMENT_DOSE';
}
