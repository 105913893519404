import { InterventionType } from './intervention-type';
import { InterventionAlarm } from './intervention-alarm';

export class Intervention {

  active: boolean = false;
  owner: string;
  profile: string;
  device: string;
  type: number = InterventionType.UNDEFINED;
  alarms: InterventionAlarm[] = [];

  constructor(obj) {
    obj && Object.assign(this, obj);
  }
}
