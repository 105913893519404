import { ProfileSensoryProfileSpec } from '../../entities/profile-sensory-profile-spec';
import { ProfileSensoryProfileType } from '../../entities/profile-sensory-profile-type';

export class SensoryProfileUI {

  static SENSORY_PROFILES = {

    [ProfileSensoryProfileType.RANGE]: {

      [ProfileSensoryProfileSpec.AUDITORY_PROCESS]: {
        icon: 'ear'
      },
      [ProfileSensoryProfileSpec.VISUAL_PROCESS]: {
        icon: 'eye'
      },
      [ProfileSensoryProfileSpec.VESTIBULAR_PROCESS]: {
        icon: 'unicycle'
      },
      [ProfileSensoryProfileSpec.TACTIL_PROCESS]: {
        icon: 'tactil'
      },
      [ProfileSensoryProfileSpec.MULTISENSORIAL_PROCESS]: {
        icon: 'multisensorial'
      },
      [ProfileSensoryProfileSpec.ORAL_SENSORIAL_PROCESS]: {
        icon: 'oral-sensorial'
      },
      [ProfileSensoryProfileSpec.RESISTANCE_RELATED_SENSORY_PROCESS]: {
        icon: 'resistance-sensory'
      },
      [ProfileSensoryProfileSpec.POSITION_MOVEMENT_RELATED_MODULATION]: {
        icon: 'position-modulation'
      },
      [ProfileSensoryProfileSpec.ACTIVITY_LEVEL_MOVEMENT_MODULATION]: {
        icon: 'activity-modulation'
      },
      [ProfileSensoryProfileSpec.SENSORIAL_INFORMATION_EMOTIONAL_MODULATION]: {
        icon: 'sensorial-information'
      },
      [ProfileSensoryProfileSpec.VISUAL_INFORMATION_EMOTIONAL_MODULATION]: {
        icon: 'visual-information'
      },
      [ProfileSensoryProfileSpec.EMOTIONAL_SOCIAL_REACTIONS]: {
        icon: 'emotional-reactions'
      },
      [ProfileSensoryProfileSpec.SENSORIAL_PROCESS_BEHAVIOUR]: {
        icon: 'sensorial-process'
      },
      [ProfileSensoryProfileSpec.RESPONSE_UMBRAL]: {
        icon: 'response-umbral'
      }
    }

  }

}
