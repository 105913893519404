import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Router } from '@angular/router';
import { NavigationService } from '../../../services/navigation.service';
import { RecordsOpsService } from '../../../services/records-ops.service';

import { ModalController }  from '@ionic/angular';

import { Paths } from '../../../navigation/paths';

@Component({
  selector: 'app-calendar-ops-modal',
  templateUrl: './calendar-ops-modal.component.html',
  styleUrls: ['./calendar-ops-modal.component.scss'],
})
export class CalendarOpsModalComponent implements OnInit {


  public dailyView: boolean = false;
  public weeklyView: boolean = true;


  // *** C *** //
  constructor(
    private modalCtrl: ModalController,
    private navS: NavigationService,
    private recordsOpsS: RecordsOpsService,
    private navCtrl: NavController,
    private router: Router
  ) {

    this.setCurrentCalendarView();

  }


  // *** Lifecycle Events *** //
  ngOnInit() {}


  // *** modal
  public onClickCloseModal() {

    this.modalCtrl.dismiss();

  }


  // *** click weekly
  public onClickWeekView() {

    // cerrar este modal
    this.modalCtrl.dismiss();

    let url = Paths.MEMBERS + '/' + Paths.RECORDS + '/' + Paths.RECORDS_WEEKLY;

    this.navS.ghostPush(url);

    this.recordsOpsS.toogleCalendarView( Paths.RECORDS_WEEKLY );

  }

  // *** click daily
  public onClickDailyView() {

    // cerrar este modal
    this.modalCtrl.dismiss();

    let url =
      Paths.MEMBERS + '/'
      + Paths.RECORDS + '/'
      + Paths.RECORDS_DAILY
      + '/nottoday';

    this.navS.ghostPush(url);

    this.recordsOpsS.toogleCalendarView( Paths.RECORDS_DAILY );

  }

  // *** click today
  public onClickToday() {}


  // *** current calendar view
  private setCurrentCalendarView() {

    // get current view :
    // src: https://stackoverflow.com/a/37909349
    const currentURL= this.router.url

    switch( currentURL ) {

      case '/' + Paths.MEMBERS + '/' + Paths.RECORDS
            + '/' + Paths.RECORDS_DAILY:
      case '/' + Paths.MEMBERS + '/' + Paths.RECORDS + '/'
            + Paths.RECORDS_DAILY + '/' + Paths.NOT_TODAY:

        this.dailyView = true;
        this.weeklyView = false;

        break;

      case '/' + Paths.MEMBERS + '/' + Paths.RECORDS + '/' + Paths.RECORDS_WEEKLY:

        this.dailyView = false;
        this.weeklyView = true;

        break;

    }

  }

}
