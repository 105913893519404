import { Injectable } from '@angular/core';

import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

import { AuthenticationService } from './authentication.service';


const apiUrl = environment.REGTEA_DATA_API;

@Injectable({
  providedIn: 'root'
})



export class DataService {

  private _httpOptions = {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'timeout': `${30000}`
      })
  };

  private _getHttpOptions$() {

    let headers = {
      'Content-Type': 'application/json',
      'timeout': `${30000}`
    }

    return new Promise((resolve, reject) => {

      this.authS.getTokenKey$().then((tk) => {
        
        if(tk) {
          headers["misterious-token"] = tk;
        }

        resolve({
          headers: headers
        });

      })
      .catch(err => {
        reject(err)
      });
    });

  }

  // *** C
  constructor(
    private http: HttpClient,
    private authS: AuthenticationService
   ) { }

  // *** API *** //
  get$( query ) {

    console.log('DataS: GET ' + query);

    return new Promise( ( resolve, reject ) => {

      this._getHttpOptions$().then((httpOptions) => {
        this.http.get( apiUrl + '/' + query, httpOptions )
        .subscribe(
          ( response: any) => {
            resolve( response );
          },
          ( err ) => {
            reject( err );
          }
        );
      })
    });

  }

  post$( query, body ) {

    console.log('DataS: POST ' + query );
    console.log('DataS: body: ' + JSON.stringify(body));

    return new Promise(( resolve, reject ) => {

      this._getHttpOptions$().then((httpOptions) => {
        this.http.post( apiUrl + '/' + query, body, httpOptions )
        .subscribe(
          ( response: any) => {
            resolve( response );
          },
          err => {
            reject( err );
          }
        );
      })
    });
  }
  

  put$( query, body ) {

    console.log('DataS: UPDATE ' + query );
    console.log('DataS: body: ' + JSON.stringify(body));

    return new Promise( ( resolve, reject ) => {

      this._getHttpOptions$().then((httpOptions) => {
        this.http.put( apiUrl + '/' + query, body, httpOptions )
        .subscribe(
          ( response: any) => {
            resolve( response );
          },
          err => {
            reject( err );
          }
        );
      })
    });
  }

  public delete$( query ) {

    console.log('DataS: DELETE ' + query );

    return new Promise( ( resolve, reject ) => {

      this._getHttpOptions$().then((httpOptions) => {
        this.http.delete( apiUrl + '/' + query, httpOptions )
        .subscribe(
          ( response: any) => {
            resolve( response );
          },
          err => {
            reject( err );
          }
        );
      })
    });
  }
}
