import { Injectable } from '@angular/core';
// src: https://github.com/ionic-team/ionic-native/blob/master/src/%40ionic-native/plugins/local-notifications/index.ts
import {
  LocalNotifications,
  ELocalNotificationTriggerUnit } from '@ionic-native/local-notifications/ngx';

import { LocalNotificationsConf } from '../notifications/local-notifications-conf';

import { InterventionsService } from './interventions.service';
import { ProfilesService } from './profiles.service';
import { InterventionType } from '../entities/intervention-type';


@Injectable({
  providedIn: 'root'
})
export class LocalNotificationsService {


  private NOTIFICATIONS_COUNT: number = 200;

  // *** C *** //
  constructor(
    private localNotifications: LocalNotifications,
    private profilesS: ProfilesService
  ) {

    this. setNotificationsEventsHandlers();
  }


  // *** Notifications events handlers
  private setNotificationsEventsHandlers() {

    this.localNotifications.on('trigger')
    .subscribe(( notification ) => {});
  }


  // *** OK *** //
  public scheduleInterventionNotification( intervention ) {

    switch( intervention.type ) {

      case InterventionType.CONTROL_ESFINTER:

        this.scheduleCEsfinterNotifications( intervention );
        break;

      default:
        break;
    }
  }

  private scheduleCEsfinterNotifications( intervention ) {


    let title = 'Control esfinter (' + this.profilesS.getCurrentProfile().name + ')';
    let text = 'Actualiza los registros';
    let id = intervention.alarms[0].alarmID;

    // hoy
    let today = new Date();

    let after = today;


    // si aun no es la hora de inicio:
    // retrasa hasta la hora de inicio
    if( today.getHours() < intervention.alarms[0].iniHour ) {

      after = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        intervention.alarms[0].iniHour , 0, 0 );
    }


    // si ya ha pasado la hora de fin de notificaciones las
    // configura para el día siguiente:
    if( today.getHours() >= intervention.alarms[0].endHour  ) {

      after = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        intervention.alarms[0].iniHour , 0, 0 );

      after = new Date( after.getTime() + (24*60*60*1000 ));
    }


    let before = new Date(
      after.getFullYear(),
      after.getMonth(),
      after.getDate(),
      intervention.alarms[0].endHour , 0, 0 );

    this.createNotification(
      id,
      title,
      text,
      LocalNotificationsConf.CESF_LOCAL_NOTIFICATION_TIME,
      after,
      before );
  }

  private createNotification( id, title, text, time, after, before ) {

    let notification = {

        id: id,
        title: title,
        text: text,
        foreground: true,
        sticky: true,
        priority: 2,
        smallIcon: 'res://iconwc',
        icon: 'file://assets/app-icons/interventions/wc.png',

        trigger: {
          in: time,
          unit: ELocalNotificationTriggerUnit.MINUTE,
          count: LocalNotificationsConf.LOCAL_NOTIFICATION_COUNT,
          firstAt: after,
          before: before

        }
    }

    this.localNotifications.schedule( notification );
  }

  public cancelAll() {

    this.localNotifications.clearAll();
    this.localNotifications.cancelAll();
  }

  public cancelAllByID( ids ) {

    ids.forEach( (id) => {

      this.localNotifications.clear( id );
      this.localNotifications.cancel( id );

    });
  }

  public getScheduledNotifications() {

    return this.localNotifications.getScheduledIds();
  }


  public getTriggeredNotifications() {

    return this.localNotifications.getTriggeredIds();
  }

}
