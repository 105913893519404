import { Component, OnInit, Input } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { ModalController }  from '@ionic/angular';

import { EfaStatesService } from '../../../services/efa-states.service';
import { ProfilesService } from '../../../services/profiles.service';
import { EfaStateType } from '../../../entities/efa-state-type';
import { EfaStateUI } from '../efa-state-ui';

import { EfaCenterModalComponent } from '../efa-center-modal/efa-center-modal.component';

@Component({
  selector: 'app-efa-state-card-action',
  templateUrl: './efa-state-card-action.component.html',
  styleUrls: ['./efa-state-card-action.component.scss'],
})
export class EfaStateCardActionComponent implements OnInit {

  // efa state changes
  private efaStateChangedO$: Observable<any>;
  private efaStateChanged$: Subscription;

  public icon:string = 'remorse';
  public efaText:string = 'N/A';

  // efa type
  @Input() efaType: string;
  private efaValue: number = -1;


  // modal
  private efaCenterModal: any;



  // *** C *** //
  constructor(
    private efaServiceS: EfaStatesService,
    private profilesS: ProfilesService,
    private modalCtrl: ModalController ) {}


  // *** Lifecycle *** //
  ngOnInit() {

    this.initEfaType();
  }

  ngOnDestroy() {

    // unsubscribe to ensure no memory leaks¡
    this.efaStateChanged$.unsubscribe();

  }


  // *** efa UI *** //
  public onEfaStateClick() {

    if( this.profilesS.isCurrentUserEditor() ) {

      this.openEfaStateCenterModal();
    }
  }


  private async openEfaStateCenterModal() {

    // src: https://forum.ionicframework.com/t/ionic-v4-modal-parameters/137326/2
    this.efaCenterModal = await this.modalCtrl.create({
      component: EfaCenterModalComponent,
      componentProps: {
         efaType: this.efaType,
         efaValue: this.efaValue
      }
    })

    await this.efaCenterModal.present();
  }


  // *** efa Type set ** //
  private initEfaType() {


    switch( this.efaType ) {

      case EfaStateType.EMOTIONAL:

        this.efaStateChangedO$ = this.efaServiceS.getEmotionalState$();


        break;

      case EfaStateType.ANIMICAL:

        this.efaStateChangedO$ = this.efaServiceS.getAnimicalState$();


        break;

      case EfaStateType.PHYSICAL:

        this.efaStateChangedO$ = this.efaServiceS.getPhysicalState$();


        break;

    }

    this.setEfaType();

  }


  private setEfaType() {

    this.efaStateChanged$ = this.efaStateChangedO$.subscribe(
      ( newEfaValue ) => {

        this.efaValue = newEfaValue;

        this.updateEfaUI();
      }
    );

    switch( this.efaType ) {

      case EfaStateType.EMOTIONAL:

        this.efaServiceS.loadEmotionalState();

        break;

      case EfaStateType.ANIMICAL:

        this.efaServiceS.loadAnimicalState();

        break;

      case EfaStateType.PHYSICAL:

        this.efaServiceS.loadPhysicalState();

        break;

    }


  }


  // *** UI *** //
  private updateEfaUI() {

    let vals = EfaStateUI.STATES[ this.efaType ];

    let efaStateInfo = vals[ this.efaValue ];

    if( typeof efaStateInfo !== 'undefined' ) {

      this.icon = efaStateInfo.icon;
      this.efaText = efaStateInfo.displayName;

    }




  }



}
