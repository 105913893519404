export class GuideContent {

  guideRef: string;
  index: number;
  title?: string;
  subtitle?: string;
  img?: string[];
  blocks?: string[];

  constructor(obj) {
    obj && Object.assign(this, obj);
  }
}
