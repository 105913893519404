// ** User ** //

export class Profile {

  _id: string;
  name: string;
  creationDate: Date;
  birthDate: Date;
  gender: string;
  image: string;
  owner: string;
  ownerDisplayName: string;
  editor: boolean;
  lector: boolean;
  profileOwner: boolean;
  editors: string[];
  readers: string[];

  constructor(obj) {
    obj && Object.assign(this, obj);
  }

}
