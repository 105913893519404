import { Injectable } from '@angular/core';

import { DataService } from './data.service';
import{ User } from '../entities/user';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private currentUser: User;
  private deviceID: any;

  // *** C *** //
  constructor(
    private dataS: DataService
  ) { }


  // *** API *** //
  loadCurrentUser$( us, pass ) {

    return new Promise( ( resolve, reject ) => {

      let body = {
        us: us,
        pass: pass
      }


      this.dataS.post$(environment.API_USER, body ).then(

        ( response: any ) => {

          if( response.status === 'success' ) {


            this.onGetUserResponse( response, resolve );

          } else {

            this.onGetUserError( response, reject );

          }
        }

      ).catch( err => {

        this.onLoadDataError(err, reject );

      });
    })

  }


  getCurrentUser() {

    return this.currentUser;

  }

  getCurrentDeviceID() {

    return this.deviceID;

  }

  getCurrentUserID() {

    if( this.currentUser ) {

      return this.currentUser._id;

    } else {

      let error = new Error();

      error.name = "Error en la obtencion de perfiles";
      error.message = "No existe el usuario actual";

      throw( error );
    }

  }

  private onGetUserResponse( response: any, resolve ) {

    this.currentUser = new User( response.data );

    resolve(response);

  }

  private onGetUserError( response, reject ) {

    let error = new Error();

    error.name = 'Error en obtención de usuario';
    error.message = 'Error indefinido';

    if( response.status === 'error') {
      if( response.message === 'credenciales no válidas') {
          error.message = 'credenciales no válidas';
      }
    }

    reject( error );

  }


  private onLoadDataError(err, reject) {

    let error = new Error();

    switch(err.name) {
      case 'HttpErrorResponse':
        error.name = 'Servidor de datos inaccesible';
        error.message = `Intentalo más tarde. Si el 
        problema persiste contacta con el administrador`
        break;
      default:
        error.name = err.name;
        error.message = err.message;
        break;
    }

    reject(error);

  }


}
