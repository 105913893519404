import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';

import { DataService } from './data.service';
import { UsersService } from './users.service';
import{ Profile } from '../entities/profile';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfilesService {

  private currentProfile: Profile;
  private $currentProfile = new Subject<any>();

  private currentUserEditor: boolean;
  private currentUserOwner: boolean;

  // *** C *** //
  constructor(
    private usersS: UsersService,
    private dataS: DataService
  ) { }

  // *** API *** //
  public getUserProfiles$( ownerID:any ) {

    return new Promise(( resolve, reject ) => {

      let q: string = environment.API_USER_PROFILE + '/' + ownerID;

      this.dataS.get$( q )
      .then(
        ( response: any ) => {

          if( response.status === 'success' ) {

            this.onGetProfilesResponse( response, resolve );

          } else {

            this.onGetProfilesError( reject );
          }
        }
      )
      .catch( err => {

        reject( err );
      });
    });
  }

  public loadCurrentProfile$( profileID: any ) {

    return new Promise(( resolve, reject ) => {

      let q: string = environment.API_PROFILE + '/' + profileID;

      this.dataS.get$( q )
      .then(
        ( response: any ) => {

          if( response.status === 'success' ) {

            this.onGetCurrentProfileResponse( response, resolve );

          } else {

            this.onGetCurrentProfileError( reject );
          }
        }
      )
      .catch( err => {

        reject( err );
      });

    });

  }

  public getCurrentProfile() {

    return this.currentProfile;

  }

  public getCurrentProfileObservable() {

    return this.$currentProfile.asObservable();
  }

  public getCurrentProfileID() {

    return this.currentProfile._id;

  }

  public getCurrentProfileName() {

    return this.currentProfile.name;

  }


  // *** PRIVATE *** //
  private onGetProfilesResponse( response: any, resolve ) {

    let responseData: any = response.data;

    let profiles: Profile[] = [];

    responseData.forEach( ( profile, index, responseData ) => {

      let newProfile = new Profile( profile );

      profiles.push( newProfile );

    });

    resolve( profiles );

  }

  private onGetProfilesError( reject ) {

    let error = new Error();

    error.name = 'Error en obtención de perfiles';

    error.message = 'Error indefinido';

    reject( error );

  }

  private onGetCurrentProfileResponse( response: any, resolve ) {

    let responseData: any = response.data;

    let profile = new Profile( responseData );

    this.currentProfile = profile;

    this.setCurrentUserEditionStatus();

    this.$currentProfile.next( profile );

    resolve( profile );

  }

  private onGetCurrentProfileError( reject ) {

    let error = new Error();

    error.name = 'Error en obtención de perfil';

    error.message = 'Error indefinido';

    reject( error );

  }


  // *** current user edition status
  public setCurrentUserEditionStatus() {

    let currentUserID = this.usersS.getCurrentUserID();

    this.currentUserEditor = false;
    this.currentUserOwner = false;

    if( this.currentProfile.editors.includes( currentUserID )) {

      this.currentUserEditor = true;

    }

    if( this.currentProfile.owner === currentUserID ) {

      this.currentUserEditor = true;
      this.currentUserOwner = true;
    }







  }

  public isCurrentUserEditor() {

    return this.currentUserEditor;
  }

  public isCurrentUserOwner() {

    return this.currentUserOwner;
  }

}
