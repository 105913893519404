import { User } from './user';
import { Profile } from './profile';

import { RCTimeUnit } from './rc-time-unit';
import { RCReactionValues } from './rc-reaction-values';
import { RCInfoField } from './rc-info-field';


export class RC {

  // *** Attrs
  _id?: string;

  dateTime: Date;

  duration?: number;
  durationUnit?: number = RCTimeUnit.UNDEFINED;

  templateRef: string;

  owner: string;
  profile: string;

  title?: string = null;
  bgColor?: string;

  reaction?: number = RCReactionValues.UNDEFINED;

  info?: any;


  // *** C
  constructor( obj ) {

    if( obj ) {

      Object.assign(this, obj);
    }
  }


  // ***
  public haveTitle():boolean {

    let have = false;

    if( this.title !== 'undefined' && this.title !== null && this.title.length > 0 ) {

      have = true;
    }

    return have;
  }

  public haveDuration(): boolean {

    let have = false;

    if( typeof this.duration !== 'undefined'
    && this.duration !== null
    && this.durationUnit !== RCTimeUnit.UNDEFINED ) {

      have = true;
    }

    return have;
  }

  public haveReaction(): boolean {

    let have = false;

    if( typeof this.reaction !== 'undefined'
      && this.reaction !== null
      && this.reaction !== RCReactionValues.UNDEFINED ) {

      have = true;
    }

    return have;
  }

  public haveBGColor(): boolean {

    let have = false;

    if( typeof this.bgColor !== 'undefined'
      && this.bgColor !== null ) {

      have = true;
    }

    return have;
  }


  // ***
  public haveInfoFields(): boolean {

    let have = false;

    if( typeof this.info !== 'undefined'
      && this.info !== null
      && typeof this.info.fields !== 'undefined'
      && this.info.fields !== null
      && this.info.fields.length > 0 ) {

        this.info.fields.forEach(( field ) => {

          if( typeof field !== 'undefined' && field != null ) {

            let fieldO = new RCInfoField( field );

            if( fieldO.haveValue()) {

              have = true;
            }
          }
        });

    }

    return have;

  }


  // *** get info field
  public getInfoField( name ): RCInfoField {

    let infoField = null;

    if( this.haveInfoFields() ) {

      this.info.fields.forEach(( field ) => {

        if( typeof field !== 'undefined'
          && field != null
          && field.name === name ) {

          infoField = new RCInfoField( field );


        }
      });
    }

    return infoField;
  }
}
