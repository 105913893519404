

export class RCInfoField {

  // *** Attrs
  name: string = null;
  value: any = null;

  // *** C
  constructor( obj ) {

    if( obj ) {

      Object.assign(this, obj);
    }
  }


  // ***
  public haveValue() {

    let value = false;

    if( typeof this.value !== 'undefined' && this.value !== null ) {

      value = true;
    }

    return value;
  }
}
