import { DocumentClass } from './document-class';

export class Document {

  name: string;
  class: number = DocumentClass.UNDEFINED;
  creation: Date = new Date();
  url: string;
  profile: string;


  constructor(obj) {
    obj && Object.assign(this, obj);
  }

}
