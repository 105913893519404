import { Component, OnInit, OnDestroy, Output, EventEmitter, ChangeDetectorRef  } from '@angular/core';
import { LoadingController }  from '@ionic/angular';
import { Subscription } from 'rxjs';
import { GeolocationService } from '../../../services/geolocation.service';
import { RCsService } from '../../../services/rcs.service';
import { RC } from '../../../entities/rc';
import { TimeHelper } from '../../../helpers/time-helper';

import * as moment from 'moment';

@Component({
  selector: 'app-rcs-daily-timetable',
  templateUrl: './rcs-daily-timetable.component.html',
  styleUrls: ['./rcs-daily-timetable.component.scss'],
})
export class RCsDailyTimetableComponent implements OnInit, OnDestroy {

  @Output() scrollRequest: EventEmitter<any> = new EventEmitter<any>();

  public timeTableEntries: any[] = [];
  private sunTime: any = null;
  public loadingAlert: any;
  private currentDatetime$: Subscription;
  //private updatedRCs$: Subscription;

  public emptyViewVisible: boolean = false;
  public emptyViewMSG: string = '';

  // *** C
  constructor(
    private loadingCtrl: LoadingController,
    private geolocationS: GeolocationService,
    private rcsS: RCsService,
    private cd: ChangeDetectorRef
  ) {}

  // *** lifecycle
  ngOnInit() {

    this.init();

  }

  ngOnDestroy() {

    this.currentDatetime$.unsubscribe();
    //this.updatedRCs$.unsubscribe();
  }


  // *** initialization
  private init() {

    this.initSubscriptions();
    this.initSunTime();
    this.initTimetable();
    this.initEmptyView();
    this.loadRCs$().then(
      () => {

        this.requestScrollToTime();
      }
    );

    setTimeout(
      () => {

        //this.initDatetime();

      },
      1000
    );

  }

  private initSunTime() {

    let coords = this.geolocationS.getCurrentCoords();

    this.sunTime = TimeHelper.getSunTime( coords, this.rcsS.getCurrentDatetime() );
  }

  private initTimetable() {

    this.timeTableEntries = [];

    for( let i = 0; i < 48; i++ ) {

      let entry = this.getTimetableEntry( i );

      this.timeTableEntries.push( entry );
    }
  }

  private initEmptyView() {

    this.emptyViewMSG = 'No se crearon registros este día';
  }


  // *** RCs
  private loadRCs$() {

    return new Promise(( resolve, reject ) => {

      this.showLoadingAlert('Cargando registros')
      .then(
        () => {

          this.rcsS.getCurrentDateTimeRCs$()
          .then(
            (rcs: any) => {

              if( rcs.length > 0 ) {

                this.pushRCs( rcs );

                this.emptyViewVisible = false;

              } else {

                this.emptyViewVisible = true;
              }

              this.cd.detectChanges();
              this.dismissLoadingAlert();
              resolve(rcs);

            }
          ).catch(
            (err) => {

              this.dismissLoadingAlert();
              reject(err);
            });
        }
      )
    });
  }

  private pushRCs( rcs ) {

    this.clearRCs();

    rcs.forEach((rc) => {

      let index = this.getRCIndex( rc );

      this.pushRC(rc, index);

      this.adjustTimeTableEntriesLimits(index);

    });
  }

  private getRCIndex( rc ) {

    let rcDateTime = moment( rc.dateTime );

    let h = rcDateTime.hour();
    let m = rcDateTime.minutes();

    let index = h*2;

    if( m >= 30 ) {

      index++;
    }

    return index;
  }

  private pushRC( rc, index ) {

    if( this.timeTableEntries[index].rcs === null ) {

      this.timeTableEntries[index].rcs = [];
    }

    this.timeTableEntries[index].rcs.push(rc);
    this.timeTableEntries[index].limit = false;
  }

  private clearRCs() {

    this.initSunTime();
    this.initTimetable();
    /*
    this.timeTableEntries.forEach((entry) => {

      entry.rcs = null;
    })*/
  }


  // *** time table
  private getTimetableEntry( index ) {

    // hour && min
    let hour = Math.floor(index / 2);

    let min = 0;

    if( index % 2 > 0 ) {

      min = 30;
    }

    let daylight = true;

    // night && day
    if( this.sunTime != null ) {

      if( hour <= this.sunTime.sunrise.h || hour >= this.sunTime.sunset.h ) {

        daylight = false;
      }
    }

    // now
    let now = moment(new Date());

    let isNow = false;

    if( hour === now.hour()) {

      if( min === 0 && now.minutes() < 30 ) {

        isNow = true;

      } else if( min === 30 && now.minutes() >= 30 ) {

        isNow = true;
      }
    }

    // limit
    let isBeforeLimit = false;
    let isAfterLimit = false;
    let isLimit = false;

    if( index === 0 || index === 47 ) {

      isBeforeLimit = true;
      isLimit = true;
    }

    if( index === 47 ) {

      isAfterLimit = true;
      isLimit = true;
    }

    let entry = {

      h: hour,
      m: min,
      daylight: daylight,
      isNow: isNow,
      rcs: null,
      limit: isLimit,
      beforeLimit: isBeforeLimit,
      afterLimit: isAfterLimit,
    }

    return entry;
  }

  private adjustTimeTableEntriesLimits( index ) {

    if( index > 0 ) {

      if( this.timeTableEntries[index -1].rcs === null ) {

        this.timeTableEntries[index - 1].limit = true;
        this.timeTableEntries[index - 1].beforeLimit = true;
      }
    }

    if( index < this.timeTableEntries.length - 1 ) {

      if( this.timeTableEntries[index + 1].rcs === null ) {

        this.timeTableEntries[index + 1].limit = true;
        this.timeTableEntries[index + 1].afterLimit = true;
      }
    }

  }

  // *** subscriptions
  private initSubscriptions() {

    this.currentDatetime$ = this.rcsS.getCurrentDatetime$()
    .subscribe(
      ( data ) => {

        this.loadRCs$()
        .then(
          (rcs: any) => {

            if( (data as RC).dateTime ) {

              let newRC = data;

              this.requestScrollToRC(newRC);

            } else {

              if( rcs.length > 0 ) {

                this.requestScrollToTime();
              }
            }


          }
        )
        .catch((err) => { throw new Error(err)});
      }
    )

  }


  // *** time
  private initDatetime() {

    let dateTime = this.rcsS.getCurrentDatetime();

    this.rcsS.setCurrentDatetime( dateTime );
  }


  // *** scroll request
  private requestScrollToTime() {

    let index = this.getNowEntryIndex();

    this.scrollRequest.emit(
      {
        elID: 'entry-' + index
      }
    );

  }

  private getNowEntryIndex() {

    let nowM = moment( new Date());

    return this.getEntryIndex( nowM );
  }

  private requestScrollToRC( rc ) {

    let index = this.getRCEntryIndex( rc );

    this.scrollRequest.emit(
      {
        elID: 'entry-' + index
      }
    );

  }

  private getRCEntryIndex( rc ) {

    let rcDatetimeM = moment( rc.dateTime );

    return this.getEntryIndex( rcDatetimeM );
  }

  private getEntryIndex( timeM ) {

    let h = timeM.hours();
    let m = timeM.minutes();

    let index = h * 2;

    if( m >= 30 ) {

      index++;
    }

    if( index > 2) {

      index = index - 2;
    }

    return index;
  }


  // *** loading alert
  private showLoadingAlert( msg ) {

    return new Promise((resolve, reject) => {

      this.loadingCtrl.create({
        message: msg
      }).then(
        ( spinner ) => {

          this.loadingAlert = spinner;
          this.loadingAlert.present();

          resolve();

        }
      );
    });
  }

  private dismissLoadingAlert() {

    return new Promise((resolve, reject) => {

      setTimeout(
        () => {
          this.loadingAlert.dismiss()
          .then(() => { resolve(); });
        },
        500
      );
    });
  }

}
