import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  isLoading = false;

  constructor(
    public loadingController: LoadingController
  ) { }


  async present( options ) {

    this.isLoading = true;

    return await this.loadingController.create(options)
    .then(a => {

      a.present().then(() => {

        if (!this.isLoading) {
          a.dismiss().then();
        }
      });
    });
  }

  async dismiss() {

    if( this.isLoading === true ) {

      this.isLoading = false;

      return await this.loadingController.dismiss();

    }


  }
}
