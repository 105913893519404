import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ModalController, LoadingController, AlertController }  from '@ionic/angular';

import { RCsService } from '../../../services/rcs.service';
import { RCTemplate } from '../../../entities/rc-template';

import { RCRCTModalComponent } from '../rc-rct-modal/rc-rct-modal.component';


@Component({
  selector: 'app-rc-rct-creation-action',
  templateUrl: './rc-rct-creation-action.component.html',
  styleUrls: ['./rc-rct-creation-action.component.scss'],
})
export class RCRCTCreationActionComponent implements OnInit {


  @Input() template: RCTemplate;

  @Output() modalReq$ = new EventEmitter<any>();


  private modal: any;
  private loading: any;
  private dialog: any;

  // *** C
  constructor(
    private loadingCtrl: LoadingController,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private rcsS: RCsService
  ) { }


  // *** Lifecycle
  ngOnInit() {}


  // *** Action
  public onClickAction() {

    this.launchLoading$()
    .then(
      () => {

        this.rcsS.getRCsByTemplateCount$( this.template.ref ).
        then(
          (count ) => {

            this.loadingCtrl.dismiss();

            //this.launchRCCreationModeDialog( count );

            this.launchNewRCCreationModal();
          }
        );
      }
    )
    .catch(
      ( err ) => {

        throw new Error(err);
      }
    );
  }


  // **** loadinh
  private launchLoading$() {

    return new Promise(( resolve, reject ) => {

      this.loadingCtrl.create({
        message: 'cargando información de registros...'
      }).then(
        ( spinner ) => {

          this.loading = spinner;
          this.loading.present();

          resolve();

        }
      );
    });
  }

  // *** rc creation dialog
  private async launchRCCreationModeDialog( rcCount ) {


    let buttons = [
      {
        text: 'Nuevo',
        cssClass: 'secondary',
        handler: () => {

          this.launchNewRCCreationModal();
          return;
        }
      }, {
        text: 'Nuevo (rápido)',
        cssClass: 'secondary',
        handler: () => {

          //this.interestsS.removeInterest$( this.interest );

          return;
        }
      }
    ];

    if( rcCount > 0 ) {

      buttons.push({
        text: 'Copiar anterior',
        cssClass: 'secondary',
        handler: () => {
          return;
        }
      });
    }


    this.dialog = await this.alertCtrl.create({
      header: 'Nuevo registro',
      message: 'Pulsa una de las opciones para crear un nuevo registro',
      buttons: buttons
    });

    await this.dialog.present();
  }


  // *** RC creation
  private async launchNewRCCreationModal() {

    this.modal = await this.modalCtrl.create({
      component: RCRCTModalComponent,
      componentProps: {
         template: this.template
      }
    });

    this.modal.onDidDismiss()
    .then(
      ( rData ) => {

        if( rData && rData.data.newRC ) {

          this.modalReq$.emit( rData );
        }
      }
    );

    await this.modal.present();
  }


}
