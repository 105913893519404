import { EfaStateType } from './efa-state-type';
import { RecordValoration } from './record-valoration';

export class EfaRecordInfo {

  type: string = EfaStateType.UNDEFINED;
  value: number;

  constructor(obj) {
    obj && Object.assign(this, obj);
  }


  public getValoration() {

    return RecordValoration.UNDEFINED;

  }
}
