import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { SensoryProfilesService } from '../../../services/sensory-profiles.service';
import { ProfilesService } from '../../../services/profiles.service';

import { ProfileSensoryProfileQuestion } from '../../../entities/profile-sensory-profile-question';

import { SensoryProfileConf } from '../../../sensory-profile/sensory-profile-conf';

import { Paths } from '../../../navigation/paths';



@Component({
  selector: 'app-sensory-profile-question-card',
  templateUrl: './sensory-profile-question-card.component.html',
  styleUrls: ['./sensory-profile-question-card.component.scss'],
})
export class SensoryProfileQuestionCardComponent implements OnInit {

  @Input() question: ProfileSensoryProfileQuestion;

  public max: number = SensoryProfileConf.QUESTION_MAXIMUN_SCORE;

  public valorationTXT: string;

  public editionEnabled: boolean;


  // *** C
  constructor(
    private router: Router,
    private sensoryProfilesS: SensoryProfilesService,
    private profilesS: ProfilesService
  ) {

    this.setEditionEnabled();
  }


  // *** Lifecycle events
  ngOnInit() {

    this.updateValoration();

  }


  // *** actions
  public onIncrementValue() {

    if( this.profilesS.isCurrentUserEditor() && this.question.answerValue < 4 ) {

      this.question.answerValue += 1;

      this.updateValoration();

      this.updateQuestion();

    }
  }

  public onDecrementValue() {

    if( this.profilesS.isCurrentUserEditor() && this.question.answerValue > 0 ) {

      this.question.answerValue -= 1;

      this.updateValoration();

      this.updateQuestion();

    }
  }

  public updateValoration() {

    let answerValue = this.question.answerValue;

    switch( answerValue ) {

      case SensoryProfileConf.NEVER:
        this.valorationTXT = 'nunca';
        break;

      case SensoryProfileConf.RARELY:
        this.valorationTXT = 'casi nunca';
        break;

      case SensoryProfileConf.SOMETIMES:
        this.valorationTXT = 'a veces';
        break;

      case SensoryProfileConf.FRECUENTLY:
        this.valorationTXT = 'frecuentemente';
        break;

      case SensoryProfileConf.ALWAYS:
        this.valorationTXT = 'casi siempre';
        break;

    }

  }


  // *** question
  private updateQuestion() {

    this.sensoryProfilesS.updateQuestion$( this.question )
    .then(
      (question) => {}
    )
    .catch(
      err => {
        let errJson = JSON.stringify(err);

        this.router.navigate(['/' + Paths.ERROR, errJson]);
      }
    )

  }


  // * edition permissions
  private setEditionEnabled() {

    this.editionEnabled = false;


    if( this.profilesS.isCurrentUserEditor() ) {

      this.editionEnabled = true;
    }

  }

}
