import { Component, OnInit } from '@angular/core';
import { ModalController }  from '@ionic/angular';

import { RCTemplatesService } from '../../../services/rc-templates.service';

import { RCTTag } from '../../../entities/rct-tag';

import { RCUI } from '../rc-ui';


@Component({
  selector: 'app-rc-creation-modal',
  templateUrl: './rc-creation-modal.component.html',
  styleUrls: ['./rc-creation-modal.component.scss'],
})
export class RCCreationModalComponent implements OnInit {


  public tags: RCTTag[];


  public MOST_USED: number = RCUI.MODES.MOST_USED;
  public LAST: number = RCUI.MODES.LAST;

  // *** C
  constructor(
    private modalCtrl: ModalController,
    private rcTemplatesS: RCTemplatesService
  ) {

    this.rcTemplatesS.getRCVisibleTags$()
    .then(
      ( tags: RCTTag[] ) => {

        this.tags = tags;
      }
    )
    .catch((err) => {

    });
  }


  // *** Lifecycle
  ngOnInit() {}


  // *** modal
  public onClickCloseModal() {

    this.modalCtrl.dismiss();

  }

  public onModalReq(event) {

    if( event && event.data.newRC ) {

      this.modalCtrl.dismiss();
    }
  }
}
