import { Injectable } from '@angular/core';
import { Geolocation } from '@ionic-native/geolocation/ngx';

// src: https://github.com/apache/cordova-plugin-geolocation

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {

  private coords = null;

  // ***
  constructor(
    private geolocation: Geolocation
  ) {}


  // *** initialization
  public init$() {

    return new Promise((resolve, reject) => {

      this.geolocation.getCurrentPosition()
      .then(
        ( geoData ) => {

          if( geoData.coords ) {

            this.coords = {
              lat: geoData.coords.latitude,
              long: geoData.coords.longitude
            }

            this.enableGeolocationWatch();

            resolve();
          }


        }
      ).catch((err) => {

        console.log(`No fue posible activar la geolocalización ${err.message}`);

        reject();
      })
    })
  }


  // *** access
  public getCurrentCoords() {

    return this.coords;
  }




  // *** geolocation watch
  private enableGeolocationWatch() {

    let watch = this.geolocation.watchPosition();

    watch.subscribe(
      ( geoData ) => {

        if( geoData.coords.latitude && geoData.coords.longitude ) {

          this.coords = {

            lat: geoData.coords.latitude,
            long: geoData.coords.longitude
          }
        }
      }
    );
  }


}
