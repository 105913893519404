import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, PickerController, NavParams }  from '@ionic/angular';
import { PickerOptions, PickerButton } from '@ionic/core';

import { Validators, FormBuilder, FormGroup } from '@angular/forms';

import { RecordsService } from '../../../services/records.service';
import { UsersService } from '../../../services/users.service';
import { ProfilesService } from '../../../services/profiles.service';

import { Record } from '../../../entities/record';
import { RecordType } from '../../../entities/record-type';
import { RecordTimeUnit } from '../../../entities/record-time-unit';
import { RecordValoration } from '../../../entities/record-valoration';
import { BehaviorType } from '../../../entities/behavior-type';
import { Behavior } from '../../../entities/behavior';
import { BehaviorByType } from '../../../entities/behavior-by-type';
import { BehaviorRecordInfo } from '../../../entities/behavior-record-info';
import { BehaviorUI } from './behavior-ui';

import { Paths } from '../../../navigation/paths';



@Component({
  selector: 'app-behavior-record-modal',
  templateUrl: './behavior-record-modal.component.html',
  styleUrls: ['./behavior-record-modal.component.scss'],
})
export class BehaviorRecordModal implements OnInit {

  // *** Form
  public recordForm: FormGroup;

  public submitted: boolean = false;
  public disabledSubmit: boolean = true;
  private clone: boolean = false;
  public submitBtnTxt = 'CREAR';
  public enabledDateField: boolean = false;
  public hiddenCloneButton: boolean = true;


  // *** Record
  private record: Record;


  // *** Behavior
  public hiddenBehaviorTXT: boolean = true;
  public hiddenOtherBehaviorTXT: boolean = true;

  // *** color
  public bgColors: number[] = [];
  public selectedBGColor;

  // *** C *** //
  constructor(
    public modalCtrl: ModalController,
    public navParams: NavParams,
    private pickerCtrl: PickerController,
    private formBuilder: FormBuilder,
    private recordsS: RecordsService,
    private profilesS: ProfilesService,
    private usersS: UsersService,
    private router: Router
  ) {}


  // *** lifecycle
  ngOnInit() {

    this.readModalParams();
    this.createForm();
    this.initBGColors();
  }


  // ** convenience getter for easy access to form fields ** //
  private get f() {
    return this.recordForm.controls;
  }


  // *** modal params
  public readModalParams() {

    this.record = this.navParams.get('record');

    if( this.record ) {

      this.submitBtnTxt = 'MODIFICAR';
      this.enabledDateField = true;
      this.hiddenCloneButton = false;

    }

  }


  // *** form ops *** //
  private createForm() {

    let recordDate;
    let recordTime;
    let durationTXT;
    let durationTime = -1;
    let durationUnit = RecordTimeUnit.UNDEFINED;

    let valoracion = '';
    let valoration = -1;
    let behaviorType = BehaviorType.UNDEFINED;
    let behaviorTypeTXT;
    let behavior = Behavior.UNDEFINED;
    let behaviorTXT;
    let otherBehaviorTXT;
    let detonating = null;
    let description = null;
    let observations = null;
    let bgColor;


    if( !this.record ) {

      let now = new Date()

      recordDate = this.recordsS.getCurrentDate();
      recordTime = recordDate;

    } else {

      durationTime = this.record.durationTime;
      durationUnit = this.record.durationUnit;
      durationTXT = this.getDurationTXT( durationTime, durationUnit );

      behavior = this.record.recordInfo.behavior;
      behaviorType = this.record.recordInfo.behaviorType;

      recordDate = this.record.date;
      recordTime = this.record.time;

      behaviorType = this.record.recordInfo.behaviorType;
      behaviorTypeTXT = this.getBehaviorTypeTXT( behaviorType );
      behavior = this.record.recordInfo.behavior;
      behaviorTXT = this.getBehaviorTXT( behaviorType, behavior );
      otherBehaviorTXT = this.record.recordInfo.otherBehavior;

      detonating = this.record.recordInfo.detonating;
      description = this.record.recordInfo.description;
      observations = this.record.observations;

      bgColor = this.record.bgColor;
    }

    this.recordForm = this.formBuilder.group({

      date: [recordDate.toString(), Validators.required],
      time: [recordTime.toString(), Validators.required],
      durationTXT: [durationTXT],
      durationTime: durationTime,
      durationUnit: durationUnit,
      behaviorType: [behaviorType, Validators.required],
      behaviorTypeTXT: [behaviorTypeTXT],
      behavior: [behavior],
      behaviorTXT: [behaviorTXT],
      otherBehaviorTXT: [otherBehaviorTXT],
      detonating: [detonating],
      description: [description],
      observations: [observations],
      bgColor: [bgColor]
    });

    this.disabledSubmit = !this.validFormValues();

    this.setSelectedBGColor();

    this.setBehaviorTXTVisibility( behaviorType );

    this.setOtherBehaviorTXTVisibility( behaviorType, behavior);

    this.setFormEventsHandlers();

  }

  private setFormEventsHandlers() {

    this.recordForm.valueChanges.subscribe(
      ( value ) => {

        this.disabledSubmit = !this.validFormValues();
      }
    );
  }

  public async onSubmit() {

    if( this.record ) {

      this.onSubmitModifyRecord();

    } else {

      this.onSubmitNewRecord();
    }
  }

  public onClone() {
    this.onSubmitNewRecord();
  }

  private onSubmitNewRecord() {

    this.submitted = true;

    if( !this.validFormValues() ) {

      this.submitted = false;

      return;

    } else {

      let values = this.getRecordValues();

      let newRecord = new Record( values );


      this.recordsS.storeNewRecord$( newRecord )
      .then(
        ( data ) => {

          this.modalCtrl.dismiss();
        }
      )
      .catch(
        err => {

          let errJson = JSON.stringify(err);

          this.router.navigate(['/' + Paths.ERROR, errJson]);

          let data = {
            error: 'error'
          }

          this.submitted = false;

          this.modalCtrl.dismiss( data );
        }
      );
    }
  }

  private onSubmitModifyRecord() {

    this.submitted = true;

    if( !this.validFormValues()) {

      this.submitted = false;

      return;

    } else {

      this.updateRecord();


      this.recordsS.updateRecord$( this.record )
      .then(
        ( data ) => {

          this.modalCtrl.dismiss();
        }
      )
      .catch(
        err => {

          let errJson = JSON.stringify(err);

          this.router.navigate(['/' + Paths.ERROR, errJson]);

          let data = {
            error: 'error'
          }

          this.submitted = false;

          this.modalCtrl.dismiss( data );
        }
      );
    }

  }

  private getRecordValues() {

    let recordInfo: BehaviorRecordInfo = new BehaviorRecordInfo(
      {
        behavior: parseInt(this.recordForm.value.behavior),
        behaviorType: parseInt(this.recordForm.value.behaviorType),
        otherBehavior: this.recordForm.value.otherBehaviorTXT,
        detonating: this.recordForm.value.detonating,
        description: this.recordForm.value.description
      }
    )


    let isoDate = new Date( this.recordForm.value.date ).toISOString();
    let isoTime = new Date( this.recordForm.value.time ).toISOString();

    let values = {
      type: RecordType.BEHAVIOR,
      date: isoDate,
      time: isoTime,
      durationTime: this.recordForm.value.durationTime,
      durationUnit: this.recordForm.value.durationUnit,
      observations: this.recordForm.value.observations,
      author: this.usersS.getCurrentUserID(),
      profile: this.profilesS.getCurrentProfileID(),
      recordInfo: recordInfo,
      bgColor: this.recordForm.value.bgColor
    }

    return values;
  }

  private validFormValues() {

    let valid: boolean = false;

    if( !this.recordForm.invalid ) {

      let recordValues = this.getRecordValues();

      let behavior = recordValues.recordInfo.behavior;
      let behaviorType = recordValues.recordInfo.behaviorType;
      let otherBehavior = this.recordForm.value.otherBehaviorTXT;
      let validOtherBehavior =  false;

      if( typeof otherBehavior !== 'undefined'
        && otherBehavior !== null
        && otherBehavior.length > 0 ) {

          validOtherBehavior = true;
        }

      // si se ha definido tipo de conducta (behavior type)
      if( typeof behaviorType !== 'undefined'
        && behaviorType !== BehaviorType.UNDEFINED ) {

        // si tipo de conducta es otra (behavior type)
        if( behaviorType === BehaviorType.OTHER ) {

          // si se ha definido el valor de otra conducta
          if( validOtherBehavior ) {

            valid = true;
          }
        } else { // si se ha definido un tipo de conducta

          if( typeof behavior !== undefined
            && behavior !== Behavior.UNDEFINED ) { // si se ha definido una conducta

            if( behavior !== Behavior.OTHER ) { // si la conducta es distinta de otra

              valid = true;

            } else { // si la conducta es otra

              if( validOtherBehavior ) { // si se ha definido el valor de otra conducta

                valid = true;
              }
            }
          }
        }
      }
    }


    return valid;
  }


  // record ops
  public updateRecord() {

    let values = this.getRecordValues();

    let recordID = this.record._id;

    this.record = new Record( values );

    this.record._id = recordID;

  }


  // *** duration
  public onClickDuration() {

    this.showDurationPicker();

  }

  private async showDurationPicker() {

    let timeValues = [];

    for( let timeValue = 1; timeValue <= 60; timeValue++ ) {

      let time = {
        text: timeValue + "",
        value: timeValue
      }

      timeValues.push( time );
    }

    let opts: PickerOptions = {
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel'
        },
        {
          text: 'Sin duración',
          handler: () => {
            this.onDurationReset();
          }
        },
        {
          text: 'Hecho',
          role: 'done',
          handler: (data) => {
            this.onDurationSelected( data );
          }
        }
      ],
      columns: [
        {
          name: 'time',
          options: timeValues
        },
        {
          name: 'units',
          options: [
            {
              value: RecordTimeUnit.MINUTE,
              text: 'minutos'
            },
            {
              value: RecordTimeUnit.HOUR,
              text: 'horas'
            },
            {
              value: RecordTimeUnit.DAY,
              text: 'dias'
            },
          ]
        }
      ]
    };



    let picker = await this.pickerCtrl.create(opts);

    picker.present();

    //picker.onDidDismiss().then(async data => {});

  }

  private onDurationSelected( durationData ) {

    let time = durationData.time.value;
    let unit = durationData.units.value;

    this.recordForm.controls['durationTime'].setValue(time);
    this.recordForm.controls['durationUnit'].setValue(unit);

    this.setDurationTXT( time, unit );

  }

  private onDurationReset() {

    this.recordForm.controls['durationTime'].setValue(-1);
    this.recordForm.controls['durationUnit'].setValue(RecordTimeUnit.UNDEFINED);

    this.recordForm.controls['durationTXT'].setValue(null);

  }

  private setDurationTXT( time, unit ) {

    let txt;

    if( time !== -1 && unit !== RecordTimeUnit.UNDEFINED ) {

      txt = time + ' ' + this.getDurationUnitTXT( time, unit );

      this.recordForm.controls['durationTXT'].setValue(txt);
    }

  }

  private getDurationTXT( time, unit ) {

    let txt;

    if(
      typeof time !== 'undefined' &&
      typeof unit !== 'undefined' &&
      time !== -1 &&
      unit !== RecordTimeUnit.UNDEFINED ) {

      txt = time + ' ' + this.getDurationUnitTXT( time, unit );

    }

    return txt;
  }

  private getDurationUnitTXT( time, unit ) {

    let txt;

    switch( unit ) {

      case RecordTimeUnit.MINUTE:
        txt = 'minuto';
        break;
      case RecordTimeUnit.HOUR:
        txt = 'hora';
        break;
      case RecordTimeUnit.DAY:
        txt = 'dia';
        break;
    }

    if( time > 1 ) {

      txt += 's';
    }

    return txt;
  }


  // *** behavior type
  public onClickBehaviorType() {

    this.showBehaviorTypePicker();
  }

  private async showBehaviorTypePicker() {

    let behaviorTypes = [];

    for( let key in BehaviorUI.BEHAVIOR_TYPES ) {

      if( BehaviorUI.BEHAVIOR_TYPES.hasOwnProperty(key)) {

        let behavior = BehaviorUI.BEHAVIOR_TYPES[key];

        behaviorTypes.push({
          text: behavior.displayName,
          value: key
        })

      }

    }


    let opts: PickerOptions = {
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel'
        },
        {
          text: 'Limpiar conducta',
          handler: () => {
            this.onBehaviorTypeReset();
          }
        },
        {
          text: 'Hecho',
          role: 'done',
          handler: (data) => {
            this.onBehaviorTypeSelected( data );
          }
        }
      ],
      columns: [
        {
          name: 'behaviorTypes',
          options: behaviorTypes
        }
      ]
    };

    let picker = await this.pickerCtrl.create(opts);

    picker.present();

  }

  private onBehaviorTypeReset() {

    let behaviorType = BehaviorType.UNDEFINED;
    let behavior = Behavior.UNDEFINED;

    let behaviorTypeTXT = this.getBehaviorTypeTXT( behaviorType );
    let behaviorTXT = this.getBehaviorTXT( behaviorType, behavior );

    this.recordForm.controls['behaviorType'].setValue( behaviorType );
    this.recordForm.controls['behaviorTypeTXT'].setValue( behaviorTypeTXT);
    this.recordForm.controls['behavior'].setValue( behavior );
    this.recordForm.controls['behaviorTXT'].setValue( behaviorTXT);
    this.recordForm.controls['otherBehaviorTXT'].setValue( null );

    this.setBehaviorTXTVisibility( behaviorType );
    this.setOtherBehaviorTXTVisibility( behavior, behaviorType );

  }

  private onBehaviorTypeSelected( data ) {

    let behaviorType = data.behaviorTypes.value;
    let behaviorTypeTXT = this.getBehaviorTypeTXT( behaviorType );

    this.recordForm.controls['behaviorType'].setValue( behaviorType );
    this.recordForm.controls['behaviorTypeTXT'].setValue( behaviorTypeTXT);
    this.recordForm.controls['behavior'].setValue( Behavior.UNDEFINED);
    this.recordForm.controls['otherBehaviorTXT'].setValue( null );

    if( behaviorType && behaviorType !== BehaviorType.UNDEFINED ) {

      let behavior = null;

      this.recordForm.controls['behaviorTXT']
        .setValue(this.getBehaviorTXT(behaviorType, behavior));
      //this.hiddenBehaviorTXT = false;

    }


    this.setOtherBehaviorTXTVisibility( behaviorType, Behavior.UNDEFINED );
  }

  private getBehaviorTypeTXT( behaviorType ) {

    let txt;

    if( typeof behaviorType !== 'undefined'
      && behaviorType !== null
      && behaviorType !== BehaviorType.UNDEFINED ) {

      txt = BehaviorUI.BEHAVIOR_TYPES[behaviorType].displayName;
    }

    this.setBehaviorTXTVisibility( behaviorType );

    return txt;
  }


  // *** behavior
  public onClickBehavior() {

    this.showBehaviorPicker();

  }

  private async showBehaviorPicker() {

    let behaviorType = parseInt(this.recordForm.value.behaviorType);

    let behaviors: any = BehaviorByType.BEHAVIORS[behaviorType];

    let behaviorsList = [];

    behaviors.forEach(( behavior ) => {

      let behaviorUITXT = BehaviorUI.BEHAVIORS[behavior].displayName;

      behaviorsList.push({

        text: behaviorUITXT,
        value: behavior
      })
    });

    // *** add other
    behaviorsList.push({
      text: BehaviorUI.BEHAVIORS[Behavior.OTHER].displayName,
      value: Behavior.OTHER
    });


    let opts: PickerOptions = {
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel'
        },
        {
          text: 'Limpiar conducta',
          handler: () => {
            this.onBehaviorReset();
          }
        },
        {
          text: 'Hecho',
          role: 'done',
          handler: (data) => {
            this.onBehaviorSelected( data );
          }
        }
      ],
      columns: [
        {
          name: 'behaviors',
          options: behaviorsList
        }
      ]
    };

    let picker = await this.pickerCtrl.create(opts);

    picker.present();

  }

  private onBehaviorSelected( data ) {

    let behaviorType = this.recordForm.value.behaviorType;

    let behavior = data.behaviors.value;

    let behaviorTXT = this.getBehaviorTXT( behaviorType, behavior );

    this.recordForm.controls['behavior'].setValue( behavior );
    this.recordForm.controls['behaviorTXT'].setValue( behaviorTXT );
    this.recordForm.controls['otherBehaviorTXT'].setValue( null );

    this.setOtherBehaviorTXTVisibility( behaviorType, behavior );
  }

  private onBehaviorReset() {

    let behaviorType = this.recordForm.value.behaviorType;

    let behavior = Behavior.UNDEFINED;
    let behaviorTXT = this.getBehaviorTXT( behaviorType, behavior );

    this.recordForm.controls['behavior'].setValue( behavior );
    this.recordForm.controls['behaviorTXT'].setValue( behaviorTXT);
    this.recordForm.controls['otherBehaviorTXT'].setValue( null );

    this.setOtherBehaviorTXTVisibility( behaviorType, behavior );
    this.setBehaviorTXTVisibility( behaviorType );
  }

  private getBehaviorTXT( behaviorType, behavior ) {

    let txt;

    if( typeof behaviorType !== 'undefined'
      && behaviorType !== null
      && behaviorType != BehaviorType.UNDEFINED
      && behaviorType != BehaviorType.OTHER ) {

      if( typeof behavior !== 'undefined'
        && behavior != null
        && behavior != Behavior.UNDEFINED ) {

        txt = BehaviorUI.BEHAVIORS[behavior].displayName;

      }

    }


    return txt;
  }

  private setBehaviorTXTVisibility( behaviorType ) {

    if( typeof behaviorType !== 'undefined'
      && behaviorType !== null
      && behaviorType != BehaviorType.UNDEFINED
      && behaviorType != BehaviorType.OTHER ) {

      this.hiddenBehaviorTXT = false;



    } else {

      this.hiddenBehaviorTXT = true;

    }


  }


  // *** other behaviour
  private setOtherBehaviorTXTVisibility( behaviorType, behavior ) {

    let hidden = true;

    if( behaviorType == BehaviorType.OTHER || behavior == Behavior.OTHER ) {

      hidden = false;

    }

    this.hiddenOtherBehaviorTXT = hidden;

  }

  // *** color
  private initBGColors() {

    this.bgColors = Array(15).fill(1).map((x,i)=>i); // [0,1,2,3,4]

  }

  private setSelectedBGColor() {

    this.selectedBGColor = this.recordForm.value.bgColor;

  }

  private onSelectBGColor( color ) {

      this.recordForm.controls['bgColor'].setValue( color );

      this.setSelectedBGColor();
  }


  // *** time
  public onClickNow() {

    let now = new Date();

    this.recordForm.controls['date'].setValue(now.toString());
    this.recordForm.controls['time'].setValue(now.toString());

  }

  // *** modal OPS *** //
  public onClickCloseModal() {

    this.modalCtrl.dismiss();

  }
}
