import { RecordValoration } from './record-valoration';

export class CustomRecordInfo {

  recordName: string;
  recordDescription?: string;


  constructor(obj) {
    obj && Object.assign(this, obj);
  }


  public getValoration() {

    return RecordValoration.UNDEFINED;

  }
}
