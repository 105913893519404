import {
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  Output,
  ChangeDetectorRef
} from '@angular/core';
import { LoadingController }  from '@ionic/angular';
import { Subscription } from 'rxjs';
import { GeolocationService } from '../../../services/geolocation.service';
import { RCsService } from '../../../services/rcs.service';
import { TimeHelper } from '../../../helpers/time-helper';
import { NavigationService } from '../../../services/navigation.service';
import { Paths } from '../../../navigation/paths';
import { RC } from '../../../entities/rc';

import * as moment from 'moment';

@Component({
  selector: 'app-rcs-weekly-timetable',
  templateUrl: './rcs-weekly-timetable.component.html',
  styleUrls: ['./rcs-weekly-timetable.component.scss'],
})
export class RCsWeeklyTimetableComponent implements OnInit, OnDestroy {

  @Output() scrollRequest: EventEmitter<any> = new EventEmitter<any>();

  private sunTime: any = null;
  public loadingAlert: any;
  private currentDatetime$: Subscription;

  public timeTableEntries: any[] = [];

  public emptyViewVisible: boolean = false;
  public emptyViewMSG: string = '';

  // *** C
  constructor(
    private loadingCtrl: LoadingController,
    private geolocationS: GeolocationService,
    private rcsS: RCsService,
    private cd: ChangeDetectorRef,
    private navS: NavigationService
  ) {}


  // *** Lifecycle
  ngOnInit() {

    this.init();
  }

  ngOnDestroy() {

    this.currentDatetime$.unsubscribe();
  }


  // *** initialization
  private init() {

    this.initSubscriptions();
    this.initSunTime();
    this.initTimeTable();
    this.initEmptyView();
    this.loadRCs$()
  }

  private initSunTime() {

    let coords = this.geolocationS.getCurrentCoords();

    this.sunTime = TimeHelper.getSunTime( coords );
  }

  private initTimeTable() {

    this.timeTableEntries = [];

    for( let r = 0; r < 48; r++ ) {

      this.timeTableEntries.push(this.getTimeTableEntry(r));

    }
  }

  private initEmptyView() {

    let week = TimeHelper.getLocalWeek( this.rcsS.getCurrentDatetime());

    this.emptyViewMSG = 'No se crearon registros esta semana';
  }


  // *** subscriptions
  private initSubscriptions() {

    this.currentDatetime$ = this.rcsS.getCurrentDatetime$()
    .subscribe(
      ( data ) => {

        this.loadRCs$()
        .then(
          (rcs: any) => {

            if( (data as RC).dateTime ) {

              let newRC = data;

              this.requestScrollToRC(newRC);

            } else {

              if( rcs.length > 0 ) {

                this.requestScrollToTime();
              }

            }
          }
        )
        .catch((err) => {

          throw new Error(err)}
        );
      }
    )
  }


  // *** RCs
  private loadRCs$() {

    return new Promise((resolve, reject) => {

      this.showLoadingAlert('Cargando registros')
      .then(
        () => {

          this.rcsS.getCurrentWeekRCs$()
          .then(
            (rcs: any) => {

              if( rcs.length > 0 ) {

                this.pushRCs( rcs );

                this.emptyViewVisible = false;

              } else {

                this.emptyViewVisible = true;
              }

              this.cd.detectChanges();
              this.dismissLoadingAlert();
              resolve(rcs);

            }
          ).catch(
            (err) => {

              this.dismissLoadingAlert();
              reject(err);
            });
        }
      )
    })
  }

  private pushRCs( rcs ) {

    this.initTimeTable();

    rcs.forEach( (rc) => {

      let rIndex = this.getRIndex( rc );
      let cIndex = this.getCIndex( rc );

      if( this.timeTableEntries[rIndex].entries[cIndex].rcs === null ) {

        this.timeTableEntries[rIndex].entries[cIndex].rcs = [];
      }

      this.timeTableEntries[rIndex].entries[cIndex].rcs.push( rc );

    });


    this.timeTableEntries.forEach((entry, index ) => {

      this.adjustTimeTableEntriesLimits( index );
    })

  }

  private adjustTimeTableEntriesLimits( rIndex ) {

    let entry = this.timeTableEntries[rIndex];

    if( !this.isEmptyEntries(entry)) { // si esta entrada tiene registros

      this.timeTableEntries[rIndex].beforeLimit = false;
      this.timeTableEntries[rIndex].afterLimit = false;

      if( rIndex > 0 ) {

        let bEntry = this.timeTableEntries[rIndex - 1];

        if( this.isEmptyEntries( bEntry ) ) {

          this.timeTableEntries[rIndex - 1].beforeLimit = true;
        }
      }


      if( rIndex < 47 ) {

        let bEntry = this.timeTableEntries[rIndex + 1];

        if( this.isEmptyEntries( bEntry ) ) {

          this.timeTableEntries[rIndex + 1].afterLimit = true;
        }
      }
    } else {

      if( rIndex === 0 || rIndex === 47 ) {

        this.timeTableEntries[rIndex].afterLimit = true;
      }
    }

  }

  private getRIndex( rc ) {

    let dateTimeM = moment( rc.dateTime );

    let index = dateTimeM.hours() * 2;

    if( dateTimeM.minutes() >= 30 ) {

      index++;
    }

    return index;

  }

  private getCIndex( rc ) {

    let week = TimeHelper.getWeek( new Date(rc.dateTime) );

    let rcDatetimeM = moment( rc.dateTime );

    let daysFromInit = rcDatetimeM.hours(0).minutes(0).diff(week.ini, 'days');

    return daysFromInit;
  }


  // *** time table
  private getTimeTableEntry( index ) {

    // hour && min
    let hour = Math.floor(index / 2);

    let min = 0;

    if( index % 2 > 0 ) {

      min = 30;
    }

    let daylight = true;

    // night && day
    if( this.sunTime != null ) {

      if( hour <= this.sunTime.sunrise.h || hour >= this.sunTime.sunset.h ) {

        daylight = false;
      }
    }

    // now
    let now = moment(new Date());

    let isNow = false;

    if( hour === now.hour()) {

      if( min === 0 && now.minutes() < 30 ) {

        isNow = true;

      } else if( min === 30 && now.minutes() >= 30 ) {

        isNow = true;
      }
    }


    // day entries
    let dayEntries: any[] = [];

    for( let i = 0; i < 7; i++ ) {

      let dayEntry = {
        rcs: null
      }

      dayEntries.push( dayEntry );
    }

    let entry = {

      h: hour,
      m: min,
      daylight: daylight,
      isNow: isNow,
      entries: dayEntries,
      beforeLimit: false,
      afterLimit: false

    }

    return entry;

  }

  public isEmptyEntries( entry ) {

    let empty = true;

    entry.entries.forEach((eentry) => {

      if( eentry.rcs !== null && eentry.rcs.length > 0) {
        empty = false;
      }
    });

    return empty;

  }


  // *** time
  private initDatetime() {

    this.rcsS.initWeek();
  }


  // *** scroll request
  private requestScrollToTime() {

    let index = this.getNowEntryIndex();

    this.scrollRequest.emit(
      {
        elID: 'entry-' + index
      }
    );

  }

  private getNowEntryIndex() {

    let nowM = moment( new Date());

    return this.getEntryIndex( nowM );
  }

  private requestScrollToRC( rc ) {

    let index = this.getRCEntryIndex( rc );

    this.scrollRequest.emit(
      {
        elID: 'entry-' + index
      }
    );

  }

  private getRCEntryIndex( rc ) {

    let rcDatetimeM = moment( rc.dateTime );

    return this.getEntryIndex( rcDatetimeM );
  }

  private getEntryIndex( timeM ) {

    let h = timeM.hours();
    let m = timeM.minutes();

    let index = h * 2;

    if( m >= 30 ) {

      index++;
    }

    if( index > 2) {

      index = index - 2;
    }

    return index;
  }


  // *** loading alert
  private showLoadingAlert( msg ) {

    return new Promise((resolve, reject) => {

      this.loadingCtrl.create({
        message: msg
      }).then(
        ( spinner ) => {

          this.loadingAlert = spinner;
          this.loadingAlert.present();

          resolve();

        }
      );
    });
  }

  private dismissLoadingAlert() {

    return new Promise((resolve, reject) => {

      setTimeout(
        () => {
          this.loadingAlert.dismiss()
          .then(() => { resolve(); });
        },
        500
      );
    });
  }


  // *** press events
  public onPress( index ) {

    let week = TimeHelper.getLocalWeek( this.rcsS.getCurrentDatetime());

    console.log(`week ini: ${JSON.stringify(week)}`);

    let ini = week.ini.add(index, 'days');

    this.rcsS.setCurrentDatetimeGhost( ini.toDate());

    let url = Paths.MEMBERS + '/' + Paths.RC + '/' + Paths.RC_DAILY;

    this.navS.ghostPush(url);
  }


}
