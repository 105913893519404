export class LocalNotificationsConf {


    // static INTERVENTION_NOTIFICATIONS_ID_RANGE = 99;
    static CESF_LOCAL_NOTIFICATION_ID = 100;
    static CESF_LOCAL_NOTIFICATION_TIME = 60; // minutes
    static LOCAL_NOTIFICATION_COUNT = 1000;
    static LOCAL_NOTIFICATION_INIT_HOUR = 7;
    static LOCAL_NOTIFICATION_END_HOUR = 23;
    static LOCAL_NOTIFICATION_MAX_ID = 999; // maximo incremento que puede alcanzar el ID

}
