import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ModalController } from "@ionic/angular";
import { Subscription } from 'rxjs';
import { Ionic4DatepickerModalComponent } from '@logisticinfotech/ionic4-datepicker';
import { RCsService } from '../../../services/rcs.service';
import * as moment from 'moment';
import { CalendarDatePickerHelper } from '../../../helpers/calendar-date-picker-helper';
import { TimeHelper } from '../../../helpers/time-helper';

@Component({
  selector: 'app-rcs-date-nav',
  templateUrl: './rcs-date-nav.component.html',
  styleUrls: ['./rcs-date-nav.component.scss'],
})
export class RCsDateNavComponent implements OnInit, OnDestroy {

  public DAY = 0;
  public WEEK = 1;

  @Input() mode: number;

  public currentDatetime: Date;
  private currentDatetime$: Subscription;

  public currentWeekLabel: string;

  // *** C
  constructor(
    public modalCtrl: ModalController,
    private rcsS: RCsService
  ) {}


  // *** Lifecycle
  ngOnInit() {

    this.init();

  }

  ngOnDestroy() {

    this.currentDatetime$.unsubscribe();
  }


  // *** initialization
  private init() {

    this.initSubscriptions();
    this.updateCurrentDate();
  }


  // *** date actions
  public onClickIncrease() {

    switch( this.mode ) {

      case this.DAY:
        this.rcsS.increaseDay();
        break;
      case this.WEEK:
        this.rcsS.increaseWeek();
        break;
    }

  }

  public onClickDecrease() {

    switch( this.mode ) {

      case this.DAY:
        this.rcsS.decreaseDay();
        break;
      case this.WEEK:
        this.rcsS.decreaseWeek();
        break;
    }

  }

  public onClickDate() {

    this.openDatePicker();
  }


  // *** date ops
  private updateCurrentDate() {

    this.currentDatetime = this.rcsS.getCurrentDatetime();
    this.currentWeekLabel =
      this.getCurrentWeekLabel(this.rcsS.getCurrentDatetime());

  }


  // *** week ops
  private getCurrentWeekLabel( date ) {

    let week = TimeHelper.getWeek( date );

    let label = `${week.ini.format("D . M . YYYY")} a ${week.end.format("D . M . YYYY")}`;

    return label;
  }


  // *** datepicker
  private async openDatePicker() {

    const datePickerModal = await this.modalCtrl.create({
      component: Ionic4DatepickerModalComponent,
      cssClass: "li-ionic4-datePicker",
      componentProps: {
        objConfig: CalendarDatePickerHelper
          .getDatePickerConf( this.currentDatetime )
      }
    });

    await datePickerModal.present();

    datePickerModal.onDidDismiss()
    .then(
      ( data ) => {

        this.onSelectDateInPicker( data );
      }
    );
  }

  private onSelectDateInPicker( data ) {

    if( data.data.date !== 'Invalid date' ) {

      let date = data.data.date;

      //this.selectedDate = date;

      let formattedDate = moment(date,'DD-MM-YYYY');

      this.rcsS.setCurrentDatetime( formattedDate.toDate());
    }
  }


  // *** subscription
  private initSubscriptions() {

    this.currentDatetime$ = this.rcsS.getCurrentDatetime$()
    .subscribe(
      () => {

        this.updateCurrentDate();
      }
    )
  }

}
