export class CalendarDatePickerHelper {


  // *** months list
  public static getMonthsList() {

    let ls = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Augosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ];


    return ls;

  }


  // *** disabled dates
  public static getDailyDisabledDates() {

    let disabledDates: Date[] = [
      new Date(1545911005644),
      new Date(),
      new Date(2018, 12, 12), // Months are 0-based, this is August, 10th.
      new Date("Wednesday, December 26, 2018"), // Works with any valid Date formats like long format
      new Date("12-14-2018") // Short format
    ];

    return disabledDates;

  }

  public static getWeeklyDisabledDates() {

    let disabledDates: Date[] = [
      new Date(1545911005644),
      new Date(),
      new Date(2018, 12, 12), // Months are 0-based, this is August, 10th.
      new Date("Wednesday, December 26, 2018"), // Works with any valid Date formats like long format
      new Date("12-14-2018") // Short format
    ];

    return disabledDates;

  }


  // *** calenadar date picker conf
  public static getDailyCalendarPickerConf( inputDate ) {


    let conf = {
      // inputDate: new Date('12'), // If you want to set month in dateObject of date-picker
      // inputDate: new Date('2018'), // If you want to set year in dateObject of date-picker
      //inputDate: new Date('2018-12'), // If you want to set year & month in dateObject of date-picker
      // inputDate: new Date('2018-12-01'), // If you want to set date in dateObject of date-picker
      // inputDate: '12', // If you want to set date as a string in date-picker
      // inputDate: '2018', // If you want to set date as a string in date-picker
      // inputDate: '2018-12', // If you want to set date as a string in date-picker
      // inputDate: '2018-12-12', // If you want to set date as a string in date-picker
      // inputDate: moment(new Date('12')), // If you want to set date as a moment object in date-picker
      // inputDate: moment(new Date('2018')), // If you want to set date as a moment object in date-picker
      // inputDate: moment(new Date('2018-12')), // If you want to set date as a moment object in date-picker
      //inputDate: moment(this.currentDate), // If you want to set date as a moment object in date-picker
      inputDate: inputDate,
      fromDate: new Date('2019-01-01'), // need this in order to have toDate
      toDate: new Date('2030-01-01'),
      // showTodayButton: false,
      closeOnSelect: true,
      // disableWeekDays: CalendarDatePickerHelper.getDailyDisabledDates(),
      mondayFirst: true,
      setLabel: 'Seleccionar',
      todayLabel: 'Hoy',
      closeLabel: 'Cerrar',
      // disabledDates: [],
      titleLabel: 'Selecciona una fecha',
      monthsList: CalendarDatePickerHelper.getMonthsList(),
      weeksList: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
      dateFormat: "DD-MM-YYYY",
      // clearButton: false,
      // momentLocale: 'pt-BR',
      // yearInAscending: true,
      // btnCloseSetInReverse: true,

      btnProperties: {
        expand: "block", // "block" | "full"
        fill: "", // "clear" | "default" | "outline" | "solid"
        size: "small", // "default" | "large" | "small"
        disabled: "", // boolean (default false)
        strong: "", // boolean (default false)
        color: ""
        // "primary", "secondary", "tertiary", "success", "warning", "danger", "light", "medium", "dark" , and give color in string
      }
    }


    return conf;

  }


  public static getWeeklyCalendarPickerConf( inputDate ) {

    return CalendarDatePickerHelper.getDailyCalendarPickerConf( inputDate );

  }



  public static getDatePickerConf( inputDate ) {

    let conf = {
      // inputDate: new Date('12'), // If you want to set month in dateObject of date-picker
      // inputDate: new Date('2018'), // If you want to set year in dateObject of date-picker
      //inputDate: new Date('2018-12'), // If you want to set year & month in dateObject of date-picker
      // inputDate: new Date('2018-12-01'), // If you want to set date in dateObject of date-picker
      // inputDate: '12', // If you want to set date as a string in date-picker
      // inputDate: '2018', // If you want to set date as a string in date-picker
      // inputDate: '2018-12', // If you want to set date as a string in date-picker
      // inputDate: '2018-12-12', // If you want to set date as a string in date-picker
      // inputDate: moment(new Date('12')), // If you want to set date as a moment object in date-picker
      // inputDate: moment(new Date('2018')), // If you want to set date as a moment object in date-picker
      // inputDate: moment(new Date('2018-12')), // If you want to set date as a moment object in date-picker
      //inputDate: moment(this.currentDate), // If you want to set date as a moment object in date-picker
      inputDate: inputDate,
      fromDate: new Date('2019-01-01'), // need this in order to have toDate
      toDate: new Date('2030-01-01'),
      // showTodayButton: false,
      closeOnSelect: true,
      // disableWeekDays: CalendarDatePickerHelper.getDailyDisabledDates(),
      mondayFirst: true,
      setLabel: 'Seleccionar',
      todayLabel: 'Hoy',
      closeLabel: 'Cerrar',
      // disabledDates: [],
      titleLabel: 'Selecciona una fecha',
      monthsList: CalendarDatePickerHelper.getMonthsList(),
      weeksList: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
      dateFormat: "DD-MM-YYYY",
      // clearButton: false,
      // momentLocale: 'pt-BR',
      // yearInAscending: true,
      // btnCloseSetInReverse: true,

      btnProperties: {
        expand: "block", // "block" | "full"
        fill: "", // "clear" | "default" | "outline" | "solid"
        size: "small", // "default" | "large" | "small"
        disabled: "", // boolean (default false)
        strong: "", // boolean (default false)
        color: ""
        // "primary", "secondary", "tertiary", "success", "warning", "danger", "light", "medium", "dark" , and give color in string
      }
    }

    return conf;

  }


}
