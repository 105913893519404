import { Injectable } from '@angular/core';

import { DataService } from './data.service';
import { LocalNotificationsService } from './local-notifications.service';
import { ProfilesService } from './profiles.service';
import { UsersService } from './users.service';

import{ Intervention } from '../entities/intervention';
import{ InterventionType } from '../entities/intervention-type'

import { LocalNotificationsConf } from '../notifications/local-notifications-conf';

import { Device } from '@ionic-native/device/ngx';



import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InterventionsService {

  private cesfID =  LocalNotificationsConf.CESF_LOCAL_NOTIFICATION_ID;

  private interventions: Intervention[] = [];

  private deviceID = 'cia2000_test_device';

  // *** C *** //
  constructor(
    private dataS: DataService,
    private localNotificationsS: LocalNotificationsService,
    private usersS: UsersService,
    private profilesS: ProfilesService,
    private device: Device
  ) {

    let now = new Date();

    this.cesfID = now.getTime();

    if( this.device && this.device.uuid ) {

      this.deviceID = device.uuid;

    }
  }


  // *** API *** //
  public loadCurrentProfileInterventions() {

    let ownerID = this.usersS.getCurrentUserID();
    let profileID = this.profilesS.getCurrentProfileID();

    return this.loadInterventions$( ownerID, profileID );

  }

  public loadInterventions$( userID, profileID ) {

    return new Promise(( resolve, reject ) => {

      let deviceID = this.device.uuid;

      let q: string =
        environment.API_INTERVENTION
        + '/' + userID + '/' + profileID + '/' + this.deviceID;

      this.dataS.get$( q)
        .then(
          ( response: any ) => {

            if( response.status === 'success' ) {

              this.onLoadInterventionsResponse( response, resolve );

            } else {

              this.onLoadInterventionsError( response, reject );
            }
          }
        )
        .catch( err => { reject( err )});
    });
  }

  public addIntervention$( intervention ) {

    return new Promise(( resolve, reject ) => {

      let q: string = environment.API_INTERVENTION;

      this.dataS.post$( q, intervention )
      .then(
        ( response: any ) => {

          if( response.status === 'success' ) {

            this.onAddInterventionResponse( resolve );

          } else {

            this.onAddInterventionError( response, reject );

          }

        }
      )
      .catch( err => {

        reject( err );

      });


    });


  }

  public removeInterventions$( interventionsIDs ) {

    return new Promise(( resolve, reject ) => {

      let q: string =
        environment.API_INTERVENTION + '/' + environment.DELETE_OP;

      let body = {
        interventions: JSON.stringify( interventionsIDs )
      }

      this.dataS.post$( q, body )
      .then(
        ( response: any ) => {

          if( response.status === 'success' ) {

            this.onRemoveInterventionsResponse( resolve );



          } else {

            this.onRemoveInterventionsError( response, reject );

          }

        }
      )
      .catch( err => { reject( err )});

    });

  }

  public isInterventionActive( type ) {

    let active = false;

    this.interventions.forEach(( intervention ) => {

      if( intervention.type === type ) {

        active = true;

      }

    });

    return active;

  }

  public getActiveInterventions( type ) {

    let interventions = [];

    this.interventions.forEach(( intervention ) => {

      if( intervention.type === type ) {

        interventions.push( intervention );

      }

    });

    return interventions;

  }

  public getCurrentInterventions() {

    return this.interventions;
  }

  public activateCESFIntervention() {

    return new Promise( ( resolve, reject ) => {

    let newCESFIntervention = this.createCESFIntervention();

    this.addIntervention$( newCESFIntervention )
    .then( () => {

      this.loadCurrentProfileInterventions().then(
        () => {

          let activeCESFInterventions =
            this.getActiveInterventions(
              InterventionType.CONTROL_ESFINTER
            );

          activeCESFInterventions.forEach((intervention) => {

            this.localNotificationsS.scheduleInterventionNotification( intervention );

          });

          resolve();

        }).catch( ( err ) => {

          reject( err );
        })
      })

    })

  }

  private createCESFIntervention() {

    let ownerID = this.usersS.getCurrentUserID();
    let profileID = this.profilesS.getCurrentProfileID();

    this.updateCESFID();

    // esto aún no se usa
    let interventionAlarm = {
      iniHour: LocalNotificationsConf.LOCAL_NOTIFICATION_INIT_HOUR,
      endHour: LocalNotificationsConf.LOCAL_NOTIFICATION_END_HOUR,
      period: LocalNotificationsConf.CESF_LOCAL_NOTIFICATION_TIME,
      alarmID: this.cesfID

    }



    // nueva intervención
    let newIntervention = new Intervention({
      active: true,
      owner: ownerID,
      profile: profileID,
      device: this.deviceID,
      type: InterventionType.CONTROL_ESFINTER,
      alarms: [
        interventionAlarm
      ]
    });

    return newIntervention;

  }

  private updateCESFID() {

    this.cesfID++;

    /*
    if( this.cesfID >= (
      LocalNotificationsConf.CESF_LOCAL_NOTIFICATION_ID
      + LocalNotificationsConf.LOCAL_NOTIFICATION_MAX_ID ) ) {

        this.cesfID = LocalNotificationsConf.CESF_LOCAL_NOTIFICATION_ID;

    } else {

      this.cesfID++;

    }*/

  }

  public deactivateCESFIntervention() {

    return new Promise((resolve, reject) => {

      let activeInterventions =
        this.getActiveInterventions(InterventionType.CONTROL_ESFINTER );

      let ids = [];
      let alarmIDs = [];

      activeInterventions.forEach( (intervention) => {

        ids.push( intervention._id );
        alarmIDs.push( intervention.alarms[0].alarmID );
      })

      this.removeInterventions$( ids )
      .then(
        () => {

          this.localNotificationsS.cancelAllByID( alarmIDs );
          resolve();
        }
      )
      .catch((err) => {

        reject( err );
      });

    });


  }

  // *** Interventions load *** //
  private onLoadInterventionsResponse( response, resolve ) {

    let responseData: any = response.data;

    this.interventions = [];

    if( responseData && responseData.length > 0 ) {


      responseData.forEach( ( intervention, index, responseData ) => {

        let newIntervention = new Intervention( intervention );

        this.interventions.push( newIntervention );
      });

    }

    resolve( this.interventions );
  }

  private onLoadInterventionsError( resolve, reject ) {

    let error = new Error();

    error.name = 'Error en la obtención de intervenciones';
    error.message = 'Error indefinido';


    reject( error );
  }




  // *** Interventions ops *** //
  private onAddInterventionResponse( resolve ) {

    resolve();
  }

  private onAddInterventionError( resolve, reject ) {

    let error = new Error();

    error.name = 'Error en la activacion de intervencion';
    error.message = 'Error indefinido';


    reject( error );
  }

  private onRemoveInterventionsResponse( resolve ) {

    resolve();
  }

  private onRemoveInterventionsError( resolve, reject ) {

    let error = new Error();

    error.name = 'Error en la eliminación de intervenciones';
    error.message = 'Error indefinido';


    reject( error );
  }



}
