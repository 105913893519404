import { Component, OnInit, Input } from '@angular/core';

import { Record } from '../../../entities/record';
import { RecordType } from '../../../entities/record-type';

import { EfaStateUI } from '../../efa-state/efa-state-ui';
import { EfaStateType } from '../../../entities/efa-state-type';


@Component({
  selector: 'app-record-weekly-card-icon',
  templateUrl: './record-weekly-card-icon.component.html',
  styleUrls: ['./record-weekly-card-icon.component.scss'],
})
export class RecordWeeklyCardIconComponent implements OnInit {

  @Input() record: Record;

  public recordType: number = RecordType.UNDEFINED;
  public icon: string = '/app/broken-robot';

  public visible: boolean = true;


  // *** C
  constructor() {}


  // *** Lifecycle
  ngOnInit() {

    this.setType();
    this.setVisibility();

  }



  // *** Record
  private setType() {

    switch( this.record.type ) {

      case RecordType.CONTROL_ESFINTER:
        this.icon = 'records/wc';
        this.recordType = RecordType.CONTROL_ESFINTER;
        break;
      case RecordType.BEHAVIOR:
        this.icon = 'records/mind';
        this.recordType = RecordType.BEHAVIOR;
        break;
      case RecordType.ACTION:
        this.icon = 'records/action';
        this.recordType = RecordType.ACTION;
        break;
      case RecordType.EFA:
        this.setEfaType();
        this.recordType = RecordType.EFA;
        break;
      case RecordType.EVENT:
        this.icon = 'records/event';
        this.recordType = RecordType.EVENT;
        break;
      case RecordType.CUSTOM:
      this.icon = 'records/document';
        this.recordType = RecordType.CUSTOM;
        break;
    }

  }

  private setEfaType() {

    let recordEfaType = this.record.recordInfo.type;

    let vals = EfaStateUI.STATES[ recordEfaType ];

    let efaStateInfo = vals[ this.record.recordInfo.value ];

    this.icon = 'emotions/' + efaStateInfo.icon;

  }


  private setVisibility() {

    switch( this.recordType ) {

      case RecordType.CONTROL_ESFINTER:
        this.visible = false;
        break;
    }

  }




}
