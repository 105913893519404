import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalController }  from '@ionic/angular';

import { CESFRecordModal } from '../../records/control-esfinter-record-modal/control-esfinter-record-modal.component';
import { BehaviorRecordModal } from '../../records/behavior-record-modal/behavior-record-modal.component';
import { ActionRecordModal } from '../../records/action-record-modal/action-record-modal.component';
import { EfaRecordModal } from '../../records/efa-record-modal/efa-record-modal.component';
import { EventRecordModal } from '../../records/event-record-modal/event-record-modal.component';
import { CustomRecordModal } from '../../records/custom-record-modal/custom-record-modal.component';

import { Record } from '../../../entities/record';
import { RecordValoration } from '../../../entities/record-valoration';
import { RecordType } from '../../../entities/record-type';
import { RecordTimeUnit } from '../../../entities/record-time-unit';
import { Behavior } from '../../../entities/behavior';
import { BehaviorType} from '../../../entities/behavior-type';
import { ProfilesService } from '../../../services/profiles.service';
import { RecordsOpsService } from '../../../services/records-ops.service';
import { CESFPlace } from '../../../entities/cesf-place';
import { CESFRecordInfo } from '../../../entities/cesf-record-info';
import { EfaStateType } from '../../../entities/efa-state-type';

import { EfaStateUI } from '../../efa-state/efa-state-ui';

import { BehaviorUI } from '../../records/behavior-record-modal/behavior-ui';

@Component({
  selector: 'app-record-daily-card',
  templateUrl: './record-daily-card.component.html',
  styleUrls: ['./record-daily-card.component.scss'],
})
export class RecordDailyCardComponent implements OnInit {

  private removeRecordsActive$: Subscription;
  public selected: boolean = true;

  public recordInfoEntries: any[];

  private modal: any;

  @Input() record: Record;

  public recordType: number = RecordType.UNDEFINED;

  public recordInfo: string;
  public recordObservations: string;
  public bgColorClass: string = '';
  public valorationClass: string = '';
  public icon: string = '/app/broken-robot';
  public title: string = 'Registro no definindo';
  public actionsVisible: boolean = false;
  public removeRecordVisible: boolean = false;

  public footerIconsVisible: boolean = true;

  // *** Record types
  public CESF: number = RecordType.CONTROL_ESFINTER;
  public BEHAVIOR: number = RecordType.BEHAVIOR;
  public ACTION: number = RecordType.ACTION;

  // *** CESF Records
  public inDiaper: boolean;
  public inWC: boolean;
  public inSelf: boolean;


  // *** C *** //
  constructor(
    private profilesS: ProfilesService,
    private recordsOpsS: RecordsOpsService,
    public modalCtrl: ModalController
  ) {

    this.setRecordsOpsEventsHandlers();

    this.removeRecordVisible = this.recordsOpsS.getRemoveRecordsActive();

    if( this.removeRecordVisible ) {

      this.actionsVisible = true;

    }
  }

  // *** Lifecycle events *** //
  ngOnInit() {

    this.setType();

    this.setValorationClass();
    this.setRecordObservations();

    this.initRecordByType();

    this.setSelected();

  }

  ngOnDestroy() {
        // unsubscribe to ensure no memory leaks¡
        this.removeRecordsActive$.unsubscribe();
  }


  // *** record modal
  public onClickRecordCard() {

    if( this.profilesS.isCurrentUserEditor() ) {

      switch( this.recordType) {

        case RecordType.CONTROL_ESFINTER:
          this.openControlEsfinterRecordModal();
          break;
        case RecordType.BEHAVIOR:
          this.openBehaviorRecordModal();
          break;
        case RecordType.ACTION:
          this.openActionRecordModal();
          break;
        case RecordType.EFA:
          this.openEfaRecordModal();
          break;
        case RecordType.EVENT:
          this.openEventRecordModal();
          break;
        case RecordType.CUSTOM:
          this.openCustomRecordModal();
          break;
      }
    }
  }

  private async openControlEsfinterRecordModal() {

    this.modal = await this.modalCtrl.create({
      component: CESFRecordModal,
      componentProps: {
         record: this.record
      }
    })

    this.modal.onDidDismiss()
    .then(
      ( data ) => {}
    );

    await this.modal.present();
  }

  private async openBehaviorRecordModal() {

    this.modal = await this.modalCtrl.create({
      component: BehaviorRecordModal,
      componentProps: {
         record: this.record
      }
    })

    this.modal.onDidDismiss()
    .then(
      ( data ) => {}
    );

    await this.modal.present();
  }

  private async openActionRecordModal() {

    this.modal = await this.modalCtrl.create({
      component: ActionRecordModal,
      componentProps: {
         record: this.record
      }
    })

    this.modal.onDidDismiss()
    .then(
      ( data ) => {}
    );

    await this.modal.present();
  }

  private async openEfaRecordModal() {

    this.modal = await this.modalCtrl.create({
      component: EfaRecordModal,
      componentProps: {
         record: this.record
      }
    })

    this.modal.onDidDismiss()
    .then(
      ( data ) => {}
    );

    await this.modal.present();
  }

  private async openEventRecordModal() {

    this.modal = await this.modalCtrl.create({
      component: EventRecordModal,
      componentProps: {
         record: this.record
      }
    })

    this.modal.onDidDismiss()
    .then(
      ( data ) => {}
    );

    await this.modal.present();
  }

  private async openCustomRecordModal() {

    this.modal = await this.modalCtrl.create({
      component: CustomRecordModal,
      componentProps: {
         record: this.record
      }
    })

    this.modal.onDidDismiss()
    .then(
      ( data ) => {}
    );

    await this.modal.present();
  }


  // *** Record
  private setType() {

    switch( this.record.type ) {

      case RecordType.CONTROL_ESFINTER:
        this.title = 'Control de esfinter';
        this.icon = 'records/wc';
        this.recordType = RecordType.CONTROL_ESFINTER;
        break;
      case RecordType.BEHAVIOR:
        this.title = 'Conducta';
        this.icon = 'records/mind';
        this.recordType = RecordType.BEHAVIOR;
        break;
      case RecordType.ACTION:
        this.title = 'Acción';
        this.icon = 'records/action';
        this.recordType = RecordType.ACTION;
        break;
      case RecordType.EFA:
        this.setEfaType();
        this.recordType = RecordType.EFA;
        break;
      case RecordType.EVENT:
        this.title = 'Evento';
        this.icon = 'records/event';
        this.recordType = RecordType.EVENT;
        break;
      case RecordType.CUSTOM:
        this.title = this.record.recordInfo.recordName;
        this.icon = 'records/document';
        this.recordType = RecordType.CUSTOM;
        break;
    }

  }

  private setEfaType() {

    let recordEfaType = this.record.recordInfo.type;

    let vals = EfaStateUI.STATES[ recordEfaType ];

    let efaStateInfo = vals[ this.record.recordInfo.value ];

    this.icon = 'emotions/' + efaStateInfo.icon;

    switch( this.record.recordInfo.type ) {

      case EfaStateType.EMOTIONAL:
        this.title = 'Estado emocional';
        break;
      case EfaStateType.PHYSICAL:
        this.title = 'Estado físico';
        break;
      case EfaStateType.ANIMICAL:
        this.title = 'Estado anímico';
        break;

    }

  }

  private initRecordByType() {

    switch( this.record.type ) {

      case RecordType.CONTROL_ESFINTER:
        this.initCESFRecord();
        break;
      case RecordType.BEHAVIOR:
        this.initBehaviorRecord();
        break;
      case RecordType.ACTION:
        this.initActionRecord();
        break;
      case RecordType.EFA:
        this.initEfaRecord();
        break;
      case RecordType.EVENT:
        this.initEventRecord();
        break;
      case RecordType.CUSTOM:
        this.initCustomRecord();
        break;

    }
  }

  public setRecordObservations() {

    if( typeof this.record.observations !== 'undefined'
      && this.record.observations !== null
      && this.record.observations.length > 0 ) {

      this.recordObservations = this.record.observations;

    }

  }

  public setSelected() {

    this.selected =
      this.recordsOpsS.isRecordSelected( this.record._id );

  }

  private setValorationClass() {

    switch( this.record.valoration ) {

      case RecordValoration.EXCELENT:
        this.valorationClass = 'excelent';
        break;

      case RecordValoration.GOOD:
      this.valorationClass = 'good';
      break;

      case RecordValoration.NOT_BAD:
      this.valorationClass = 'not-bad';
      break;

      case RecordValoration.BAD:
      this.valorationClass = 'bad';
      break;


    }
  }


  // *** CEFS Records
  private initCESFRecord() {

    this.setCESFPlace();
    this.setCESFRecordInfo();

  }

  private setCESFPlace() {

    let cesfRecordInfo: CESFRecordInfo = new CESFRecordInfo( this.record.recordInfo );

    this.inWC = cesfRecordInfo.place == CESFPlace.WC;
    this.inDiaper = cesfRecordInfo.place == CESFPlace.DIAPER;
    this.inSelf = cesfRecordInfo.place == CESFPlace.SELF;

  }

  private setCESFRecordInfo() {

    let cesfRecordInfo: CESFRecordInfo = new CESFRecordInfo( this.record.recordInfo );

    let profileName = this.profilesS.getCurrentProfileName();

    let msg = profileName;

    if( cesfRecordInfo.pis && !cesfRecordInfo.caca ) {

      msg += ' hizo pis'


    } else if ( !cesfRecordInfo.pis && cesfRecordInfo.caca ) {

      msg += ' hizo caca';


    } else if ( cesfRecordInfo.pis && cesfRecordInfo.caca ) {

      msg += ' hizo pis y caca';

    } else if( cesfRecordInfo.nada ) {

      msg += ' no hizo nada';

    } else {

      msg = '';

    }

    if( msg.length > 0 ) {

      switch( cesfRecordInfo.place ) {

        case CESFPlace.WC:
          msg += ' en el WC';
          break;

        case CESFPlace.DIAPER:
          msg += ' en el pañal';
          break;

        case CESFPlace.SELF:
          msg += '. Fue un accidente';
          break;

      }


    }

    if( msg.length > 0 ) {

      this.recordInfo = msg;

    }

  }


  // *** Bahvior records
  private initBehaviorRecord() {

    this.setBehaviorRecordInfoEntries();
    this.setBGColor();
  }

  private setBehaviorRecordInfoEntries() {

    this.recordInfoEntries = [];

    this.setBehaviorInfoEntries();

    this.setBehaviorTextEntries();

    this.setDurationInfoEntry();
  }

  private getBehaviorTXT( behaviorType, behavior ) {

    let txt;

    if( typeof behaviorType !== 'undefined' && behaviorType != BehaviorType.UNDEFINED
    && behaviorType != BehaviorType.OTHER ) {

      if( behavior != null && behavior != Behavior.UNDEFINED ) {

        txt = BehaviorUI.BEHAVIORS[behavior].displayName;

      }

    }


    return txt;
  }

  private getBehaviorTypeTXT( behaviorType ) {

    let txt;

    if( typeof behaviorType !== 'undefined'
      && behaviorType !== BehaviorType.UNDEFINED ) {

      txt = BehaviorUI.BEHAVIOR_TYPES[behaviorType].displayName;
    }

    return txt;
  }

  private setBehaviorInfoEntries() {

    let behaviorType = this.record.recordInfo.behaviorType;
    let behavior = this.record.recordInfo.behavior;
    let otherBehavior = this.record.recordInfo.otherBehavior;



    let behaviorTypeTXT = this.getBehaviorTypeTXT( behaviorType );

    let behaviorTXT = this.getBehaviorTXT( behaviorType, behavior );


    if( behaviorType !== BehaviorType.OTHER ) {

      let infoEntry = {
        txt: behaviorTypeTXT
      }

      this.recordInfoEntries.push( infoEntry );


      if( behavior !== Behavior.OTHER ) {

        let infoEntry = {
          txt: behaviorTXT
        }

        this.recordInfoEntries.push( infoEntry );

      } else {

        let infoEntry = {
          txt: otherBehavior
        }

        this.recordInfoEntries.push( infoEntry );

      }

    } else {

      let infoEntry = {
        txt: otherBehavior
      }

      this.recordInfoEntries.push( infoEntry );

    }

  }

  private setBehaviorTextEntries() {

    let detonating = this.record.recordInfo.detonating;
    let description = this.record.recordInfo.description;

    if( typeof detonating !== 'undefined' && detonating !== null && detonating.length > 0 ) {

      let infoEntry = {
        title: 'Detonante',
        txt: detonating
      }

      this.recordInfoEntries.push( infoEntry );

    }

    if( typeof description !== 'undefined' && description !== null && description.length > 0 ) {

      let infoEntry = {
        title: 'Descripción de la conducta',
        txt: description
      }

      this.recordInfoEntries.push( infoEntry );

    }

  }


  // *** Action records
  private initActionRecord() {

    this.setActionRecordInfoEntries();

    this.setDurationInfoEntry();
    this.setBGColor();

  }

  private setActionRecordInfoEntries() {

    this.recordInfoEntries = [];

    this.setActionInfoEntries();
  }

  private setActionInfoEntries() {

    let actionName = this.record.recordInfo.actionName;
    let actionDescription = this.record.recordInfo.actionDescription;
    let actionReaction = this.record.recordInfo.actionReaction;

    if( typeof actionName !== 'undefined'
      && actionName !== null
      && actionName.length > 0) {

        let infoEntry = {
          txt: actionName
        }

        this.recordInfoEntries.push( infoEntry );

    }

    if( typeof actionDescription !== 'undefined'
      && actionDescription !== null
      && actionDescription.length > 0) {

        let infoEntry = {
          title: 'Descripción',
          txt: actionDescription
        }

        this.recordInfoEntries.push( infoEntry );

    }

    if( typeof actionReaction !== 'undefined'
      && actionReaction !== null
      && actionReaction.length > 0) {

        let infoEntry = {
          title: 'Reacción',
          txt: actionReaction
        }

        this.recordInfoEntries.push( infoEntry );

    }

  }


  // *** Event records
  private initEventRecord() {

    this.setEventRecordInfoEntries();

    this.setDurationInfoEntry();
    this.setBGColor();

  }

  private setEventRecordInfoEntries() {

    this.recordInfoEntries = [];

    this.setEventInfoEntries();
  }

  private setEventInfoEntries() {

    let eventName = this.record.recordInfo.eventName;
    let eventDescription = this.record.recordInfo.eventDescription;
    let eventReaction = this.record.recordInfo.eventReaction;

    if( typeof eventName !== 'undefined'
      && eventName !== null
      && eventName.length > 0) {

        let infoEntry = {
          txt: eventName
        }

        this.recordInfoEntries.push( infoEntry );

    }

    if( typeof eventDescription !== 'undefined'
      && eventDescription !== null
      && eventDescription.length > 0) {

        let infoEntry = {
          title: 'Descripción',
          txt: eventDescription
        }

        this.recordInfoEntries.push( infoEntry );

    }

    if( typeof eventReaction !== 'undefined'
      && eventReaction !== null
      && eventReaction.length > 0) {

        let infoEntry = {
          title: 'Reacción',
          txt: eventReaction
        }

        this.recordInfoEntries.push( infoEntry );

    }

  }

  // *** Custom records
  private initCustomRecord() {

    this.setCustomRecordInfoEntries();

    this.setDurationInfoEntry();
    this.setBGColor();

  }

  private setCustomRecordInfoEntries() {

    this.recordInfoEntries = [];

    this.setCustomInfoEntries();
  }

  private setCustomInfoEntries() {

    let recordDescription = this.record.recordInfo.recordDescription;

    if( typeof recordDescription !== 'undefined'
      && recordDescription !== null
      && recordDescription.length > 0) {

        let infoEntry = {
          title: 'Descripción',
          txt: recordDescription
        }

        this.recordInfoEntries.push( infoEntry );

    }

  }


  // *** Efa record
  private initEfaRecord() {

    this.setEfaRecordInfo();
  }

  private setEfaRecordInfo() {

    this.recordInfoEntries = [];

    let recordEfaType = this.record.recordInfo.type;

    let vals = EfaStateUI.STATES[ recordEfaType ];

    let efaStateInfo = vals[ this.record.recordInfo.value ];

    let infoEntry = {
      title: efaStateInfo.displayName
    }

    this.recordInfoEntries.push( infoEntry );

  }


  // *** Duration
  private getRecordDurationTXT() {

    let txt = null;

    let time = this.record.durationTime;
    let unit = this.record.durationUnit;


    if( typeof time !== 'undefined'
      && typeof unit !== 'undefined'
      && time !== -1
      && unit !== RecordTimeUnit.UNDEFINED ) {

      txt = time + ' ' + this.getDurationUnitTXT( time, unit );

    }

    return txt;
  }

  private getDurationUnitTXT( time, unit ) {

    let txt;

    switch( unit ) {

      case RecordTimeUnit.MINUTE:
        txt = 'minuto';
        break;
      case RecordTimeUnit.HOUR:
        txt = 'hora';
        break;
      case RecordTimeUnit.DAY:
        txt = 'dia';
        break;
    }

    if( time > 1 ) {

      txt += 's';
    }

    return txt;
  }

  private setDurationInfoEntry() {

    let durationTXT = this.getRecordDurationTXT();

    if( durationTXT !== null ) {

      let infoEntry = {
        title: 'Duracion',
        txt: durationTXT
      }

      this.recordInfoEntries.push( infoEntry );

    }

  }


  // *** BG color
  private setBGColor() {

    let bgColor = this.record.bgColor;

    if( typeof bgColor !== 'undefined' && bgColor !== null && parseInt(bgColor) > -1 ) {

      this.bgColorClass = 'record-card-bg-color-' + bgColor;

    }

  }


  // *** record ops
  private setRecordsOpsEventsHandlers() {

    this.removeRecordsActive$ = this.recordsOpsS
    .getRemoveRecordsActiveObservable()
    .subscribe( active => {
      this.actionsVisible = active;
      this.removeRecordVisible = active;
      this.setSelected();
    });

  }

  public selectRecord( recordID ) {

    this.recordsOpsS.pushSelectedRecords( recordID );
    this.selected = true;

  }

  public unselectRecord( recordID ) {

    this.recordsOpsS.popSelectedRecords( recordID );

    this.selected = false;

  }

}
