// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  production: false,
  // *** LOCAL
  // REGTEA_DATA_API: "https://192.168.1.15:8080/regtea",

  // *** DEV
  // REGTEA_DATA_API: "https://dev.regtea.munro.systems:40200/regtea",
  REGTEA_DATA_API: "https://wifi.resad.munro.systems:40200/regtea",

  // *** HEROKU
  //REGTEA_DATA_API: "https://regtea-be.herokuapp.com/regtea",
  API_USER: 'usuario',
  API_USER_PROFILE: 'usuario-perfiles',
  API_PROFILE: 'perfil',
  API_RECORD: 'registro',
  API_EFA_RECORD: 'registro-efa',
  API_INTERVENTION: 'intervencion',
  API_PROFILE_PROGRESS: 'profile-progress',
  API_PROFILE_PROGRESS_QUESTIONS: 'profile-progress-questions',
  API_PROFILE_SENSORY_PROFILE: 'profile-sensory-profile',
  API_PROFILE_SENSORY_PROFILE_QUESTIONS: 'profile-sensory-profile-questions',
  API_WEEKLY: 'weekly',
  API_INTEREST: 'interest',
  API_DOCUMENT: 'document',
  API_GUIDE: 'guide',
  API_GUIDE_CONTENT: 'guide-content',
  API_RC_TEMPLATE_TAG: 'rc-template-tag',
  API_RC_TEMPLATE: 'rc-template',
  API_RC: 'rc',

  DELETE_OP: 'delete',
  LAST: 'last',
  MOST_USED: 'most-used',
  COUNT: 'count',
  TAG: 'tag',
  REF: 'ref',
  PROFILE: 'profile',
  TEMPLATE: 'template',
  RC_TYPE: 'rc-type',
  WEEKLY: 'weekly',
  ALL: 'all',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
