import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-rc-color-picker-field',
  templateUrl: './rc-color-picker-field.component.html',
  styleUrls: ['./rc-color-picker-field.component.scss'],
})
export class RCColorPickerFieldComponent implements OnInit {

  @Input() color: string;
  @Input() edition: boolean;

  @Output() color$ = new EventEmitter<string>();

  public colors: string[] = [];


  // *** C
  constructor() {

    this.initColors();
  }


  // *** Lifecycle
  ngOnInit() {

    this.initColor();


  }



  // *** colors
  private initColors() {

    this.colors = [
      '#DB9393',
      '#FFCCCC',
      '#FFE6E6',

      '#AF7696',
      '#CDA4BB',
      '#E1CBD8',

      '#DBB393',
      '#FFE3CC',
      '#FFF1E6',

      '#B6CC89',
      '#DEEEBE',
      '#EDF5DD',

      '#588383',
      '#7A9999',
      '#B0C3C3',

    ];
  }

  private initColor() {

    if( !this.color ) {

      this.color = '#ffffff';
    }
  }


  // *** color ops
  private onSelectColor( color ) {

    if( this.edition ) {

      this.color = color;

      this.color$.emit( this.color );

    }
  }


}
