import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { RCTemplatesCacheService } from '../../../cache/rc-templates-cache.service';
import { ModalController }  from '@ionic/angular';
import { Subscription } from 'rxjs';

import { RCRCTModalComponent } from '../../rcs/rc-rct-modal/rc-rct-modal.component';
import { RCsOpsService } from '../../../services/rcs-ops.service';

import { RC } from '../../../entities/rc';
import { RCInfoField } from '../../../entities/rc-info-field';
import { RCTemplate } from '../../../entities/rc-template';
import { RCTimeUnit } from '../../../entities/rc-time-unit';
import { RCReactionValues } from '../../../entities/rc-reaction-values';

import { RCHelper } from '../../../helpers/rc-helper';



@Component({
  selector: 'app-rc-timetable-card',
  templateUrl: './rc-timetable-card.component.html',
  styleUrls: ['./rc-timetable-card.component.scss'],
})
export class RCTimetableCardComponent implements OnInit {

  @Input() rc;

  private template: RCTemplate;

  public title: string;
  public icon: string;

  public durationVisible: boolean = false;
  public durationTXT: string;

  public reactionVisible: boolean = false;
  public reactionIcon: string;

  public bgColor: string = '#ffffff';

  public infoFieldsVisible: boolean = false;
  public infoFields: RCInfoField[] = [];

  public selected: boolean = false;

  private modal: any;

  private selectionActive$: Subscription;

  // *** C
  constructor(
    private modalCtrl: ModalController,
    private rcTemplatesCache: RCTemplatesCacheService,
    private rcsOpsS: RCsOpsService,
  ) { }


  // *** Lifecycle
  ngOnInit() {

    this.init();
  }

  ngOnDestroy() {

    this.destroySubscriptions();
  }


  // *** initialization
  private init() {

    this.initSubscriptions();
    this.initRC();
    this.initTitle();
    this.initIcon();
    this.initDuration();
    this.initReaction();
    this.initBGColor();
    this.initInfoFields();

  }

  private initRC() {

    this.rc = new RC( this.rc );

    this.template =
      new RCTemplate(
        this.rcTemplatesCache.getTemplate( this.rc.templateRef ) );

  }

  // *** title
  private initTitle() {

    /*
    if( this.rc.haveTitle() ) {

      this.title = this.rc.title;

    } else {

      this.title = this.template.label;
    }*/

    this.title = RCHelper.getRCTitle( this.rc, this.template );
  }


  // *** icon
  private initIcon() {

    this.icon = this.template.icon.icon;
  }

  public getTemplateIcon() {


    return this.template.icon.icon;
  }


  // *** duration
  private initDuration() {

    if( this.rc.haveDuration() ) {

      this.durationVisible = true;

      this.initDurationTXT();
    }
  }

  private initDurationTXT() {

    let valueTXT = this.rc.duration;

    let unitTXT = "";

    switch( this.rc.durationUnit ) {

      case RCTimeUnit.MINUTE:
        unitTXT = "M";
        break;
      case RCTimeUnit.HOUR:
        unitTXT = "H";
        break;
      case RCTimeUnit.DAY:
        unitTXT = "D";
        break;
    }

    this.durationTXT = valueTXT + unitTXT;

  }


  // *** reaction
  private initReaction() {

    if( this.rc.haveReaction() ) {

      this.reactionVisible = true;
    }

    switch( this.rc.reaction ) {

      case RCReactionValues.BAD:
        this.reactionIcon = 'angry';
        break;
      case RCReactionValues.NOT_BAD:
        this.reactionIcon = 'straight';
        break;
      case RCReactionValues.GOOD:
        this.reactionIcon = 'happy';
        break;
    }

  }


  // *** bg color
  private initBGColor() {

    if( this.rc.haveBGColor() ) {

      this.bgColor = this.rc.bgColor;

    } else {

      if( this.template.haveBGColor()) {

        this.bgColor = this.template.color.bgColor;
      }
    }
  }


  // *** info fields
  private initInfoFields() {

    if( this.rc.haveInfoFields() ) {

      this.rc.info.fields.forEach((field) => {

        if( typeof field !== 'undefined' && field != null ) {

            let fieldO = new RCInfoField( field );

            if( fieldO.haveValue()) {

              this.infoFields.push(field);
            }
          }

      });
    }
  }


  // *** actions
  public onClick() {

    if( !this.rcsOpsS.isSelectionActive() ) {

      this.launchNewRCCreationModal();

    } else {

      this.toogleSelected();
    }


  }

  // *** RC creation
  private async launchNewRCCreationModal() {

    this.modal = await this.modalCtrl.create({
      component: RCRCTModalComponent,
      componentProps: {
         rc: this.rc
      }
    });

    this.modal.onDidDismiss()
    .then(
      ( data ) => {}
    );

    await this.modal.present();
  }


  // *** RC selection
  private toogleSelected() {

    this.rcsOpsS.toogleRCSelection(this.rc._id);

    this.selected = this.rcsOpsS.isSelected( this.rc._id);
  }


  // *** subscriptions
  private initSubscriptions() {

    this.selectionActive$ = this.rcsOpsS
    .getSelectionActive$()
    .subscribe( active => {
      if( !active ) {

        this.selected = false;
      }
    });
  }

  private destroySubscriptions() {

    this.selectionActive$.unsubscribe();
  }
}
