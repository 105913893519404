
import { RC } from '../entities/rc';
import { RCInfoField } from '../entities/rc-info-field';
import { RCReactionValues } from '../entities/rc-reaction-values';

import { RCTTag } from '../entities/rct-tag';

import { RCTemplate } from '../entities/rc-template';
import { RCDefaultTemplates } from '../entities/rc-default-templates';
import { RCTInfoField } from '../entities/rct-info-field';
import { RCTInfoFieldType } from '../entities/rct-info-field-type';
import { RCTInfoFieldOption } from '../entities/rct-info-field-option';



export class RCHelper {

  public static validateRC( rc: any, template: RCTemplate ): [] {

    let invalidFields: any = [];

    // validate date time
    let validDateTime = RCHelper.isValidDateTime( rc );

    if( !validDateTime ) {

      invalidFields.push('dateTime')
    }

    // validate fields
    if( template.info && template.info.fields && template.info.fields !== null ) {

        template.info.fields.forEach((infoField) => {

          if( !RCHelper.isValidInfoField( infoField, rc )) {

            invalidFields.push( infoField.name );
          }
        });
    }

    return  invalidFields;
  }

  public static isValidDateTime( rc: RC ) {

    let valid = false;

    if( typeof rc.dateTime !== 'undefined' && rc.dateTime !== null  ) {

      valid = true;
    }

    return valid;
  }

  public static isValidInfoField( infoField, rc ) {

    let valid = false;

    if( infoField.required ) {

      if( rc.info && rc.info.fields ) {

        rc.info.fields.forEach( ( rcInfoField: RCInfoField ) => {

          if( rcInfoField.name === infoField.name ) {

            if( rcInfoField.value && rcInfoField.value !== null && rcInfoField.value.length > 0 ) {

              valid = true;
            }
          }
        })
      }

    } else {

      valid = true;
    }

    return valid;
  }



  // *** info fields
  public static getInfoFields( template ) {

    let templateInfoFields = [];

    RCHelper.addInfoFieldsByTag( template, templateInfoFields );

    RCHelper.addTemplateInfoFields( template, templateInfoFields );

    return templateInfoFields;
  }

  public static addTemplateInfoFields( template, templateInfoFields ) {

    if( template.info && template.info !== null ) {

      if( template.info.fields && template.info.fields !== null ) {

        template.info.fields.forEach((infoField: RCTInfoField ) => {

          templateInfoFields.push( infoField );
        });
      }
    }
  }

  public static addInfoFieldsByTag( template, templateInfoFields ) {

    template.tags.forEach((tag) => {

      switch( tag.ref ) {

        case RCTTag.BEHAVIOR:
          //RCHelper.addBehaviorInfoFields( templateInfoFields );
      }
    })
  }

  public static addBehaviorInfoFields( templateInfoFields ) {

    // detonanting
    let infoField = new RCTInfoField({
      name: 'detonanting',
      label: 'Detonante',
      type: RCTInfoFieldType.TXT_AREA,
    });

    templateInfoFields.push( infoField );

    // detonanting
    infoField = new RCTInfoField({
      name: 'behavior_description',
      label: 'Descripción de la conducta',
      type: RCTInfoFieldType.TXT_AREA,
    });

    templateInfoFields.push( infoField );
  }


  // *** default info fields
  public static getTitleInfoField() {

    let infoField = new RCTInfoField({
      label: 'Título del registro',
      type: RCTInfoFieldType.TXT,
    });

    return infoField;
  }

  public static getReactionInfoField() {

    let infoField = new RCTInfoField({
      label: 'Reacción',
      type: RCTInfoFieldType.SELECT_ONE,
      options: RCHelper.getReactionFieldOptions()
    });

    return infoField;
  }

  public static getReactionFieldOptions() {

    let vals = [
      {
        label: 'Buena',
        value: RCReactionValues.GOOD,
        icon: 'def/reactions/happy'
      },
      {
        label: 'Normal',
        value: RCReactionValues.NOT_BAD,
        icon: 'def/reactions/straight'
      },
      {
        label: 'Mala',
        value: RCReactionValues.BAD,
        icon: 'def/reactions/angry'
      }
    ];

    let options = [];

    vals.forEach((val) => {

      options.push( new RCTInfoFieldOption(val));
    });

    return options;
  }


  // *** title
  public static getRCTitle( rc: RC, rcTemplate: RCTemplate ) {

    let title = null;

    if( rc.haveTitle() ) {

      title = rc.title;

    } else {

      let field = '';

      switch( rc.templateRef ) {

        case RCDefaultTemplates.FOOD:

          field = 'food-group';

          title = RCHelper.getTemplateFieldRCTitle( rc, rcTemplate, field );

          break;

        case RCDefaultTemplates.DRINK:

          field = 'drink';

          title = RCHelper.getTemplateFieldRCTitle( rc, rcTemplate, field );

          break;

        case RCDefaultTemplates.WC:

          title = RCHelper.getWCRCTitle( rc, rcTemplate );

          break;

        case RCDefaultTemplates.SPORT:

          field = 'sport';

          title = RCHelper.getTemplateFieldRCTitle( rc, rcTemplate, field );

          break;

        case RCDefaultTemplates.LEISURE:

          field = 'leisure';

          title = RCHelper.getTemplateFieldRCTitle( rc, rcTemplate, field );

          break;

        case RCDefaultTemplates.INTERIOR_GAMES:
        case RCDefaultTemplates.EXTERIOR_GAMES:

          field = 'game';

          title = RCHelper.getTemplateFieldRCTitle( rc, rcTemplate, field );

          break;

        case RCDefaultTemplates.SHOPPING:

          field = 'where';

          title = RCHelper.getTemplateFieldRCTitle( rc, rcTemplate, field );

          break;

        case RCDefaultTemplates.DISPLACEMENT:

          field = 'what';

          title = RCHelper.getTemplateFieldRCTitle( rc, rcTemplate, field );

          break;

        case RCDefaultTemplates.SCHOOL_ACTIVITY:

          field = 'what';

          title = RCHelper.getTemplateFieldRCTitle( rc, rcTemplate, field );

          break;

        case RCDefaultTemplates.MEDICAMENT_DOSE:

          field = 'medicine';

          title = RCHelper.getRCFieldRCTitle( rc, rcTemplate, field );

          break;




        default:

          title = rcTemplate.label;

      }

    }

    return title;
  }


  public static getRCFieldRCTitle( rc: RC, rcTemplate: RCTemplate, field: string ) {

    let title = rcTemplate.label;

    let infoField = rc.getInfoField(field);

    if( infoField !== null ) {

      title = infoField.value;
    }

    return title;

  }

  public static getTemplateFieldRCTitle( rc: RC, rcTemplate: RCTemplate, field: string ) {

    let title = rcTemplate.label;

    let titleVal = rc.getInfoField(field).value;

    if( titleVal && titleVal !== null ) {

      let rctInfoField = rcTemplate.getInfoField(field);

      if( rctInfoField && rctInfoField !== null ) {

        title = rctInfoField.getOptionByValue( titleVal ).label;

      }

    }

    return title;

  }

  public static getSingleFieldRCTitle( rc: RC, rcTemplate: RCTemplate, field: string ) {

    let title = null;

    let titleVal = rc.getInfoField(field).value;

    if( titleVal && titleVal !== null ) {

      let rctInfoField = rcTemplate.getInfoField(field);

      if( rctInfoField && rctInfoField !== null ) {

        title = rctInfoField.getOptionByValue( titleVal ).label;

      }

    }

    return title;

  }

  public static getMultiFieldRCTitle( rc: RC, rcTemplate: RCTemplate, field: string ) {

    let titleVals = null;

    let fieldValues = rc.getInfoField(field).value;

    let rctInfoField = rcTemplate.getInfoField(field);

    if( fieldValues && fieldValues !== null ) {

      fieldValues.forEach((value) => {

        if( titleVals === null ) {

          titleVals = [];
        }

        titleVals.push(rctInfoField.getOptionByValue( value ).label);

      });

    }

    return titleVals;

  }

  public static getWCRCTitle( rc, rcTemplate ) {


    let title = null;
    //
    let where = RCHelper.getSingleFieldRCTitle( rc, rcTemplate, 'place' );

    let whatVals = RCHelper.getMultiFieldRCTitle( rc, rcTemplate, 'what');

    let sleeping = RCHelper.getSingleFieldRCTitle( rc, rcTemplate, 'sleeping' );


    let whatT = null;
    //
    if( whatVals !== null ) {

      whatVals.forEach((whatVal) => {

          if( whatT !== null ) {

            whatT += ' y ';

          } else {

            whatT = '';
          }

          whatT += whatVal;
        }
      );
    }

    if( whatT !== null ) {

      title = whatT;
    }


    if( sleeping !== null ) {

      title = '';

      title += ' ' + sleeping;

    } else {

      if( where !== null ) {

        if( title != null ) {

          title += ', ' + where;

        } else {

          title = where;

        }

      }
    }

    if( title === null ) {

      let title = rcTemplate.label;
    }

    return title;

  }




}
