import { Component, OnInit } from '@angular/core';
import { EfaStateType } from '../../../entities/efa-state-type';

@Component({
  selector: 'app-efa-state-info-card',
  templateUrl: './efa-state-info-card.component.html',
  styleUrls: ['./efa-state-info-card.component.scss'],
})
export class EfaStateInfoCardComponent implements OnInit {

  public EMOTIONAL = EfaStateType.EMOTIONAL;
  public PHYSICAL = EfaStateType.PHYSICAL;
  public ANIMICAL = EfaStateType.ANIMICAL;

  constructor() { }

  ngOnInit() {}

}
