

export class RCTTag {

  static BEHAVIOR = 'BEHAVIOR';



  // *** Attrs
  ref: string;
  label: string;
  defaultTag: boolean = true;
  owner?: string;

  // *** C
  constructor( obj ) {

    if( obj ) {

      Object.assign(this, obj);
    }
  }
}
