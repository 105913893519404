import { Component, OnInit, Input } from '@angular/core';
import { ModalController }  from '@ionic/angular';

import { Guide } from '../../../entities/guide';
import { GuideClass } from '../../../entities/guide-class';

import { GuideModalComponent } from '../guide-modal/guide-modal.component'

@Component({
  selector: 'app-guide-card',
  templateUrl: './guide-card.component.html',
  styleUrls: ['./guide-card.component.scss'],
})
export class GuideCardComponent implements OnInit {

  // *** guide
  @Input() guide: Guide;
  private guideClass: number = GuideClass.UNDEFINED;

  // *** icon
  public icon: string = 'cubes';

  // *** modal
  private modal: any;


  // *** C
  constructor(
    public modalCtrl: ModalController
  ) {}


  // ** lifecycle
  ngOnInit() {

    this.setClass();
    this.setIcon();
  }


  // ** card conf
  private setClass() {

      this.guideClass = this.guide.class;
  }


  private setIcon() {

    switch( this.guideClass ) {

      case GuideClass.UNDEFINED:
        this.icon = 'other-guides';
        break;

      case GuideClass.BEHAVIOUR:
        this.icon = 'behaviour';
        break;

      case GuideClass.EDUCATION:
        this.icon = 'education';
        break;

      case GuideClass.RELATIONS:
        this.icon = 'relations';
        break;

    }

  }


  // *** Guide modal
  private async openGuideModal() {

    this.modal = await this.modalCtrl.create({
      component: GuideModalComponent,
      componentProps: {
         guide: this.guide
      }
    })

    this.modal.onDidDismiss()
    .then(
      ( data ) => {}
    );

    await this.modal.present();

  }


  // *** actions
  public onClickCard() {
    this.openGuideModal();
  }




}
