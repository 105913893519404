export class ProfileProgressQuestion {

  _id: string;
  answerValue: number;
  question: {
    _id: string;
    index: number;
    statement: string;
  }


  constructor(obj) {
    obj && Object.assign(this, obj);
  }
}
