import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';

import { RCsService } from '../../../services/rcs.service';

import { TimeHelper } from '../../../helpers/time-helper';


@Component({
  selector: 'app-rc-datetime-field',
  templateUrl: './rc-datetime-field.component.html',
  styleUrls: ['./rc-datetime-field.component.scss'],
})
export class RCDatetimeFieldComponent implements OnInit {


  @Input() dateTime: Date;
  @Input() edition: boolean;
  @Input() dateEdition: boolean;
  @Input() timeEdition: boolean;

  @Output() dateTime$ = new EventEmitter<Date>();

  public rcForm: FormGroup;


  // *** C
  constructor(
    private rcsS: RCsService,
    private formBuilder: FormBuilder
  ) {}


  // *** Lifecycle
  ngOnInit() {

    this.init();
  }


  // *** initialization
  public init() {

    this.initDateTime();
    this.initForm();
    //this.initFormEvtHandlers();
  }

  private initDateTime() {

    if( !this.dateTime ) {

      this.dateTime = this.rcsS.getCurrentDatetime();
    }
  }


  // *** form
  private initForm() {

    this.rcForm = this.formBuilder.group({

      date: [this.dateTime.toString()],
      time: [this.dateTime.toString()]
    });


  }


  private initFormEvtHandlers() {

    this.rcForm.valueChanges.subscribe(
      ( value ) => {

        this.updateDatetime();
      }
    );
  }


  // *** time ops
  public onClickNow() {

    let now = new Date();

    this.rcForm.controls['date'].setValue(now.toString());
    this.rcForm.controls['time'].setValue(now.toString());

    this.updateDatetime();

  }

  public onDateChanged() {

    this.updateDatetime();
  }

  public onTimeChanged() {

    this.updateDatetime();
  }


  private updateDatetime() {

    let date = new Date( this.rcForm.value.date );

    this.dateTime = TimeHelper.updateTime( date, new Date( this.rcForm.value.time ) );

    this.dateTime$.emit( this.dateTime );



  }

}
