import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RCTemplatesCacheService {

  private rcTemplates: any = {};
  private rcTemplatesByRef: any = {};
  private rctTags: {} = {};

  // *** C
  constructor() {}


  // *** cache ops
  public reset() {

    //this.rcTemplates = {};
    //this.rcTemplatesByRef = {};
    //this.rctTags = {};

    this.resetNonDefaultTags();
    this.resetNonDefaultTemplates();
  }

  private resetNonDefaultTags() {

    let tags =Object.keys( this.rctTags );

    tags.forEach((tag) => {

      if( !this.rctTags[tag].defaultTag ) {

        delete this.rcTemplates[tag];

        delete this.rctTags[tag];

      }
    });
  }

  private resetNonDefaultTemplates() {

      let templateRefs =Object.keys( this.rcTemplatesByRef );

      templateRefs.forEach((ref) => {

        if( !this.rcTemplatesByRef[ref].defaultTemplate ) {

          this.removeTemplateFromCache(ref);
        }

      });
  }

  private removeTemplateFromCache(ref ) {

    let template = this.rcTemplatesByRef[ref];

    template.tags.forEach((tag) => {

      this.removeTemplateFromTree( tag.ref, ref );
    })

    delete this.rcTemplatesByRef[ref];
  }

  private removeTemplateFromTree( tagRef, templateRef ) {

    let index = -1;

    let i = 0;

    if( this.rcTemplates[tagRef]) {


      this.rcTemplates[tagRef].forEach((template) => {

        if( template.ref === templateRef ) {

          index = i;
        }

        i++;
      });

      if( index > -1 ) {

        this.rcTemplates[tagRef].splice(index, 1);
      }

      if( this.rcTemplates[tagRef].length === 0 ) {

        delete this.rcTemplates[tagRef];

        delete this.rctTags[tagRef];
      }
    }


  }



  public addTemplates( tag, templates ) {

    this.rctTags[tag.ref] = tag;

    this.rcTemplates[tag.ref] = templates;

    templates.forEach((template) => {

      this.rcTemplatesByRef[template.ref] = template;

    });
  }

  public appendTemplates( templates ) {

    console.log(`APPEND TEMPLATES ==> ${JSON.stringify(templates)}` );

    templates.forEach(( template ) => {

      template.tags.forEach((tag) => {

        this.rctTags[tag.ref] = tag;

        if( !this.rcTemplates[tag.ref] ) {

          this.rcTemplates[tag.ref] = [];
        }

          this.rcTemplates[tag.ref].push(template);

      });

      this.rcTemplatesByRef[template.ref] = template;

    });

  }

  public getTemplates( tag ) {

    return this.rcTemplates[tag.ref];
  }

  public containsTag( tag ) {

    let contains = false;

    if( this.rcTemplates[tag.ref] ) {

      contains = true;
    }

    return contains;
  }

  public getTemplate( templateRef ) {

    return this.rcTemplatesByRef[templateRef];

  }

  public logCache() {

    console.log(`templates tree:
      ${JSON.stringify(this.rcTemplates)}

      templates:
      ${JSON.stringify(this.rcTemplatesByRef)}

      tags:
      ${JSON.stringify(this.rctTags)}`);
  }
}
