import { TimeHelper } from './time-helper';


import * as moment from 'moment';

export class RecordsWeeklyHelper {


  public static createWeeklyRecordsList( records ) {

    let weeklyRecordsList = RecordsWeeklyHelper.initRecordsList();

    weeklyRecordsList = RecordsWeeklyHelper.fillRecordsList( records, weeklyRecordsList );

    return weeklyRecordsList;

  }


  // ** records list
  private static initRecordsList() {

    let time = moment().startOf('day');

    let weeklyRecordsList = new Array(48);

    for( let i = 0; i < 48; i++ ) {

      weeklyRecordsList[i] = new Array(8);

      for( let k = 0; k < 8; k++ ) {

        if( k === 0 ) {

          weeklyRecordsList[i][k] = RecordsWeeklyHelper.createTimeLabel( time );

        } else {

          weeklyRecordsList[i][k] = [];

        }

      }

      time.add(30, 'minutes');

    }

    return weeklyRecordsList;

  }


  private static fillRecordsList( records, weeklyRecordsList ) {

    for( let dayI = 0; dayI < 7; dayI++ ) {

      let dayRecords = records[dayI];

      dayRecords.forEach(
        ( record ) => {

          let time = moment( record.time );

          let hour = time.hour();
          let min = time.minute();

          let hourI = hour * 2;

          if( min > 30 ) {

            hourI++;
          }

          weeklyRecordsList[hourI][dayI + 1].push( record );
        });
    }

    return weeklyRecordsList;
  }

  // *** time
  private static createTimeLabel( time ) {

    let hour = time.hour()
    let min = time.minute();


    let label = {
      hour: hour,
      min: min
    }

    return label;

  }


  // *** week label
  public static getWeekLabel( date ) {

    let week = TimeHelper.getWeekByDate( date );

    let startOfWeek = week.ini;
    let endOfWeek = week.end;

    let label = startOfWeek.format('D . M . YY') + ' - ' + endOfWeek.format('D . M . YY');

    return label;
  }
}
