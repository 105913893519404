export class Paths {
    static ACCESS = 'access';
    static MEMBERS = 'members';
    static ERROR = 'error';
    static PROFILES = 'profiles';
    static PROFILE = 'profile';
    static PROFILE_MAIN = 'profile-main';
    static RECORDS = 'records';
    static RECORDS_DAILY = 'records-daily';
    static RECORDS_WEEKLY = 'records-weekly';
    static INTERVENTIONS = 'interventions';
    static INTERVENTIONS_MAIN = 'interventions-main';
    static PROGRESS = 'progress';
    static PROGRESS_MAIN = 'progress-main';
    static SENSORY_PROFILES = 'sensory-profiles';
    static SENSORY_PROFILES_MAIN = 'sensory-profiles-main';
    static INTERESTS = 'interests';
    static INTERESTS_MAIN = 'interests-main';
    static DOCUMENTS = 'documents';
    static DOCUMENTS_MAIN = 'documents-main';
    static GUIDES = 'guides';
    static GUIDES_MAIN = 'guides-main';
    static RC = 'rc';
    static RC_DAILY = 'rc-daily';
    static RC_WEEKLY = 'rc-weekly';

    static NOT_TODAY = 'nottoday';
}
