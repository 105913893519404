import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { ModalController }  from '@ionic/angular';
import { Subscription } from 'rxjs';

import { ProfileProgress } from '../../../entities/profile-progress';
import { ProgressService } from '../../../services/progress.service';

import { ProfileProgressSpec } from '../../../entities/profile-progress-spec';
import { ProfileProgressType } from '../../../entities/profile-progress-type';
import { ProfileProgressState } from '../../../entities/profile-progress-state';

import { ProgressConf } from '../../../progress/progress-conf';

import * as moment from 'moment';

import { ProgressUI } from '../progress-ui';

import { ProgressModalComponent } from '../progress-modal/progress-modal.component';


@Component({
  selector: 'app-progress-info-card',
  templateUrl: './progress-info-card.component.html',
  styleUrls: ['./progress-info-card.component.scss'],
})
export class ProgressInfoCardComponent implements OnInit {

  private progressModal: any;

  @Input() progressType: number;
  @Input() progressSpec: number;


  public UPDATED = ProfileProgressState.UPDATED;
  public UPDATE_NEEDED = ProfileProgressState.UPDATE_NEEDED;
  public REVIEW_NEEDED = ProfileProgressState.REVIEW_NEEDED;

  public LOW = ProfileProgressState.LOW;
  public MEDIUM = ProfileProgressState.MEDIUM;
  public HIGHT = ProfileProgressState.HIGHT;
  public EXCELENT = ProfileProgressState.EXCELENT;



  public progress: ProfileProgress;

  public icon: string = 'cubes';
  public scoreState: number = ProfileProgressState.LOW;

  public reviewState: number = ProfileProgressState.UPDATE_NEEDED;
  public days: number = 999;
  public stateMsg: string = 'No se ha actualizado nunca';
  public score: number = 0;

  private updatedProgress$: Subscription;


  // *** C *** //
  constructor(
    private progressS: ProgressService,
    private modalCtrl: ModalController
  ) {



  }

  // *** lifecycle **//
  ngOnInit() {

    this.setUpdatedProgress$();
    this.setProgress();

  }

  ngOnDestroy() {
      // unsubscribe to ensure no memory leaks¡
      this.updatedProgress$.unsubscribe();
  }


  // *** updated progress event handlers
  private setUpdatedProgress$() {

    this.updatedProgress$ = this.progressS
    .getUpdatedProgress$()
    .subscribe(
      (question) => {

        this.setScore();
        this.setScoreState();
      }
    );
  }

  // *** Settings *** //
  private setProgress() {

    switch( this.progressType ) {

      case ProfileProgressType.RANGE:

        this.setRangeProgress();

        break;
    }

    this.setScore();
    this.setIcons();
    this.setUpdateState();
    this.setUpdateStateMsg();
    this.setScoreState();
  }

  private setRangeProgress() {

    this.progress = this.progressS.getPProgress( this.progressSpec )
  }

  private setIcons() {

    this.icon = ProgressUI.PROGRESS[this.progressType][this.progressSpec].icon;

  }

  private setUpdateState() {

    if( this.progress.lastUpdate ) {

      let lastUpdate = moment(this.progress.lastUpdate);

      let now = moment();

      this.days = lastUpdate.diff(now, 'days');
    }


    if( this.days < ProgressConf.REVIEW_NEEDED_DAYS ) {

      this.reviewState = ProfileProgressState.UPDATED;

    } else if( this.days >= ProgressConf.REVIEW_NEEDED_DAYS
      && this.days < ProgressConf.UPDATE_NEEDED_DAYS ) {

      this.reviewState = ProfileProgressState.REVIEW_NEEDED;

    } else {

      this.reviewState = ProfileProgressState.UPDATE_NEEDED;

    }

    this.reviewState = ProfileProgressState.MEDIUM;
  }

  private setUpdateStateMsg() {

    if( this.progress.lastUpdate ) {

      if( this.days === 0 ) {

        this.stateMsg = 'Actualizado hoy';

      } else if( this.days === 1 ) {

        this.stateMsg = 'Actualizado ayer';

      } else if( this.days > 1 && this.days < 30 ) {

        this.stateMsg = 'Actualizado hace ' + this.days + ' días';

      } else if( this.days > 30 ) {

        let meses = Math.round( this.days/30);

        if( meses === 1 ) {

          this.stateMsg = 'Actualizado hace ' + meses + ' mes';

        } else {

          this.stateMsg = 'Actualizado hace ' + meses + ' meses';
        }

      }

    } else {

      this.stateMsg = 'No se ha actualizado nunca';
    }
  }

  private setScore() {

    let questionsCount = this.progress.questions.length;

    let tmpScore = 0;

    this.progress.questions.forEach((question) => {

      tmpScore += question.answerValue;

    });

    this.score = Math.round( tmpScore / questionsCount );


  }

  private setScoreState() {

    if( this.score < ProgressConf.RANGE_IN_PROCCESS ) {

      this.scoreState = ProfileProgressState.LOW;

    } else if( this.score >= ProgressConf.RANGE_IN_PROCCESS
      && this.score < ProgressConf.RANGE_ADQUIRED ) {

      this.scoreState = ProfileProgressState.MEDIUM;

    } else if( this.score >= ProgressConf.RANGE_ADQUIRED && this.score < ProgressConf.RANGE_GENERALIZED ) {


      this.scoreState = ProfileProgressState.HIGHT;

    } else {

      this.scoreState = ProfileProgressState.EXCELENT;
    }

  }


  // *** LAUNCH MODAL *** //
  // *** modal UI *** //
  public onClickCard() {

    this.openProgressModal();


  }

  private async openProgressModal() {

    // src: https://forum.ionicframework.com/t/ionic-v4-modal-parameters/137326/2
    this.progressModal = await this.modalCtrl.create({
      component: ProgressModalComponent,
      componentProps: {
         progress: this.progress
      }
    })

    await this.progressModal.present();
  }

}
