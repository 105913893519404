import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';

import { DataService } from './data.service';
import{ Record } from '../entities/record';
import { environment } from '../../environments/environment';

import * as moment from 'moment';



@Injectable({
  providedIn: 'root'
})
export class RecordsService {


  private currentDate: Date = new Date();
  private currentDate$ = new Subject<any>();
  private today$ = new Subject<any>();
  private today: boolean = false;
  private updatedRecord$ = new Subject<any>();
  private updatedRecords$ = new Subject<any>();
  private removedRecords$ = new Subject<any>();

  // *** C *** //
  constructor(
    private dataS: DataService
  ) {}


  // *** API *** //

  // *** store ***
  public storeNewRecord$( record ) {

    return new Promise( (resolve, reject) => {

      let q: string = environment.API_RECORD;


      this.dataS.post$( q, record )
      .then(
        ( response: any ) => {

          if( response.status === 'success' ) {

            this.onStoreNewRecordResponse( record, resolve, reject );

          } else {

            this.onStoreNewRecordError( response, reject );

          }

        }
      )
      .catch( err => {

        reject( err );

      });

    });

  }

  public getUpdatedRecordObservable() {

    return this.updatedRecord$.asObservable();

  }

  private onStoreNewRecordResponse( record, resolve, reject ) {

    this.updatedRecord$.next( record );
    this.updatedRecords$.next( true );

    this.updatedRecords$.next( true );
    resolve();
  }

  private onStoreNewRecordError( resolve, reject ) {

    let error = new Error();

    error.name = 'Error en el almacenamiento de registrp';
    error.message = 'Error indefinido';


    reject( error );
  }


  // *** remove
  public removeRecords$( recordsID ) {

    return new Promise( ( resolve, reject ) => {


      let q: string =
        environment.API_RECORD + '/' + environment.DELETE_OP;

        let body = {
          records: JSON.stringify(recordsID),
        }

      this.dataS.post$( q, body )
      .then(
        ( response: any ) => {

          if( response.status === 'success' ) {

            this.onRemoveRecordsResponse( resolve );



          } else {

            this.onRemoveRecordsError( reject );

          }

        }
      )
      .catch( err => { reject( err )});


    });
  }

  private onRemoveRecordsResponse( resolve ) {

    this.removedRecords$.next( true );

    resolve();
  }

  private onRemoveRecordsError( reject ) {

    let error = new Error();

    error.name = 'Error en la eliminación de registros';
    error.message = 'Error indefinido';


    reject( error );
  }


  // *** update records
  public getUpdatedRecords$() {

    return this.updatedRecords$.asObservable();

  }

  public getRemovedRecords$() {

    return this.removedRecords$.asObservable();

  }


  // *** get
  public getById$( recordID ) {

    return new Promise(( resolve, reject ) => {

      let q: string =
      environment.API_RECORD
      + '/' + recordID;

      this.dataS.get$( q )
      .then(
        ( response: any ) => {

          if( response.status === 'success' ) {

            this.onGetRecordResponse( response, resolve );

          } else {

            this.onGetRecordsError( response, reject );
          }
        }).catch(
          err => {
            reject( err )
          }
        );
      }
    );
  }

  private onGetRecordResponse( response, resolve ) {


    let record = new Record( response.data );

    resolve( record );

  }

  private onGetRecordsError( response, reject ) {

    let error = new Error();

    error.name = 'Error en la obtención de registro';
    error.message = 'Error indefinido';


    reject( error );
  }

  // *** get daily
  public getDailyRecords$( profileID, date ) {

    return new Promise( ( resolve, reject ) => {

      let day = date.getDate();
      let month = date.getMonth();
      let year = date.getFullYear();


      let q: string =
        environment.API_RECORD
        + '/' + profileID + '/' + day + '/' + month + '/' + year;

      this.dataS.get$( q )
      .then(
        ( response: any ) => {

          if( response.status === 'success' ) {

            this.onGetDailyRecordsResponse( response, resolve );



          } else {

            this.onGetDailyRecordsError( response, reject );

          }

        }
      )
      .catch(
        (err) => {
          reject( err )
        }
      );

    });
  }

  private onGetDailyRecordsResponse( response, resolve ) {

    let responseData: any = response.data;

    let records = [];

    if( responseData && responseData.length > 0 ) {

      for( let i = 0; i < 48; i++ ) {

        records.push([]);

      }

      responseData.forEach( ( record, index, responseData ) => {

        let newRecord = new Record( record );

        let time = new Date( newRecord.time );

        let hour = time.getHours();
        let minutes = time.getMinutes();

        let pos = Math.floor( hour * 2 );

        if( minutes > 29 ) {

          pos++;

        }


        let recordsGroup = records[pos];

        recordsGroup.push(newRecord);
      });

    }

    this.updatedRecords$.next( true );

    resolve( records );
  }

  private onGetDailyRecordsError( resolve, reject ) {

    let error = new Error();

    error.name = 'Error en la obtención de registros';
    error.message = 'Error indefinido';


    reject( error );
  }


  // *** get weekly
  public getCurrentDateWeeklyRecords$( profileID ) {

    return new Promise( ( resolve, reject ) => {

      let day = this.currentDate.getDate();
      let month = this.currentDate.getMonth();
      let year = this.currentDate.getFullYear();


      let q: string =
        environment.API_RECORD + '/' + environment.API_WEEKLY
        + '/' + profileID + '/' + day + '/' + month + '/' + year;

      this.dataS.get$( q )
      .then(
        ( response: any ) => {

          if( response.status === 'success' ) {

            this.onGetWeeklyRecordsResponse( response, resolve );



          } else {

            this.onGetWeeklyRecordsError( response, reject );

          }

        }
      )
      .catch(
        (err) =>
        {
          reject( err )
        }
      );

    });
  }

  private onGetWeeklyRecordsResponse( response, resolve ) {

    let responseData: any = response.data;

    let records = [];

    if( responseData && responseData.length > 0 ) {

      records = responseData;

    }

    //this.updatedRecords$.next( true );

    resolve( records );
  }

  private onGetWeeklyRecordsError( resolve, reject ) {

    let error = new Error();

    error.name = 'Error en la obtención de registros';
    error.message = 'Error indefinido';


    reject( error );
  }

  // *** get efa record
  public getLastEfaRecord$( profileID, type ) {

    return new Promise(( resolve, reject ) => {

      let q: string =
        environment.API_EFA_RECORD + '/' + profileID + '/' + type;

      this.dataS.get$( q )
      .then(
        ( response: any ) => {

          if( response.status === 'success' ) {

            this.onGetLastEfaRecordResponse( response, resolve );



          } else {

            this.onGetLastEfaRecordError( response, reject );

          }

        }
      )
      .catch( err => { reject( err )});

    });

  }

  private onGetLastEfaRecordResponse( response, resolve ) {

    let lastEfaRecord = null;

    let data: any = response.data;

    if( data != null ) {

      lastEfaRecord = new Record( data );

    }

    resolve( lastEfaRecord );
  }

  private onGetLastEfaRecordError( resolve, reject ) {

    let error = new Error();

    error.name = 'Error en la obtención de registro EFA';
    error.message = 'Error indefinido';


    reject( error );
  }


  // *** update
  public updateRecord$( record ) {

    return new Promise( (resolve, reject) => {

      let q: string = environment.API_RECORD;


      this.dataS.put$( q, record )
      .then(
        ( response: any ) => {

          if( response.status === 'success' ) {

            this.onUpdateRecordResponse( record, resolve, reject );



          } else {

            this.onUpdateRecordError( response, reject );

          }

        }
      )
      .catch( err => {

        reject( err );

      });

    });

  }

  private onUpdateRecordResponse( record, resolve, reject ) {

    //this.newRecord$.next( record );
    this.updatedRecord$.next( record );

    resolve();
  }

  private onUpdateRecordError( resolve, reject ) {

    let error = new Error();

    error.name = 'Error en la actualización de registro';
    error.message = 'Error indefinido';


    reject( error );
  }

  // *** date
  public setCurrentDate( newDate ) {

    this.currentDate = newDate;
    this.currentDate$.next( this.currentDate );

    this.today = this.isDateToday( newDate );

    this.today$.next( this.today );
  }

  public getCurrentDate() {

    return this.currentDate;

  }

  public increaseCurrentDate() {

    // src: https://www.sitepoint.com/managing-dates-times-using-moment-js/
    // src: https://momentjs.com
    // src: https://stackoverflow.com/a/3674550
    let currentMoment = moment( this.currentDate );
    let newMoment = moment(currentMoment).add(1, 'days');

    this.setCurrentDate( newMoment.toDate() );
  }

  public decreaseCurrentDate() {

    let currentMoment = moment( this.currentDate );
    let newMoment = moment(currentMoment).subtract(1, 'days');

    this.setCurrentDate( newMoment.toDate() );

  }

  public increaseCurrentWeek() {

    // src: https://www.sitepoint.com/managing-dates-times-using-moment-js/
    // src: https://momentjs.com
    // src: https://stackoverflow.com/a/3674550
    let currentMoment = moment( this.currentDate );
    let newMoment = moment(currentMoment).add(7, 'days');

    this.setCurrentDate( newMoment.toDate() );
  }

  public decreaseCurrentWeek() {

    let currentMoment = moment( this.currentDate );
    let newMoment = moment(currentMoment).subtract(7, 'days');

    this.setCurrentDate( newMoment.toDate() );

  }

  public getCurrentDateObservable() {

    return this.currentDate$;
  }

  public getCurrentDateRecords$( profileID ) {

    return this.getDailyRecords$( profileID, this.currentDate );

  }


  // *** today
  public getTodayObservable() {

    return this.today$;
  }

  public setToday() {

    let today = new Date();

    this.setCurrentDate( today );

  }

  public isToday() {

    return this.isDateToday( this.currentDate );
  }

  private isDateToday( newDate ) {

      let isNewDateToday = false;

      let today = new Date();

      let todayDay = today.getDate();
      let todayMonth = today.getMonth();
      let todayYear = today.getFullYear();

      let newDateDay = newDate.getDate();
      let newDateMonth = newDate.getMonth();
      let newDateYear = newDate.getFullYear();

      if( todayDay === newDateDay &&
          todayMonth === newDateMonth &&
          todayYear === newDateYear ) {

          isNewDateToday = true;

      }

      return isNewDateToday;

  }

  public getTodayRecords$( profileID ) {

    let today = new Date();

    return this.getDailyRecords$( profileID, today );

  }


}
