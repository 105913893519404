import { Component, OnInit, EventEmitter, Input, Output  } from '@angular/core';
import { PickerController }  from '@ionic/angular';
import { PickerOptions, PickerButton } from '@ionic/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';




import { RCTimeUnit } from '../../../entities/rc-time-unit';

@Component({
  selector: 'app-rc-duration-field',
  templateUrl: './rc-duration-field.component.html',
  styleUrls: ['./rc-duration-field.component.scss'],
})
export class RCDurationFieldComponent implements OnInit {


  @Input() duration: number;
  @Input() durationUnit: number;
  @Input() edition: boolean;

  @Output() duration$ = new EventEmitter<any>();

  public rcForm: FormGroup;

  // *** C
  constructor(
    private formBuilder: FormBuilder,
    private pickerCtrl: PickerController,
  ) { }


  // *** Lifecycle
  ngOnInit() {

    this.init();
  }


  // *** Initialization
  private init() {

    this.initDuration();

    if( this.edition ) {

      this.initEdition();

    } else {

      this.initInfo();
    }
  }

  private initEdition() {

    this.initForm();
  }


  // *** form
  private initForm() {

    this.rcForm = this.formBuilder.group({
      durationTXT: [this.getDurationTXT(this.duration, this.durationUnit)],
      duration: [this.duration],
      durationUnit: [this.durationUnit]
    });
  }

  private updateForm() {

    this.rcForm.controls['duration'].setValue(this.duration);
    this.rcForm.controls['durationUnit'].setValue(this.durationUnit);
    this.updateDurationTXT( this.duration, this.durationUnit );
  }


  // *** info
  private initInfo() {

  }



  // *** duration
  private initDuration() {

    if( !this.duration || !this.durationUnit ) {

      this.duration = -1;
      this.durationUnit = RCTimeUnit.UNDEFINED;
    }
  }

  public onClickDuration() {

    this.showDurationPicker();

  }

  private updateDuration() {

    this.duration$.emit({
      duration: this.duration,
      durationUnit: this.durationUnit
    })
  }

  private async showDurationPicker() {

    let timeValues = this.getPickerTimeValues();

    let opts: PickerOptions = {
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel'
        },
        {
          text: 'Sin duración',
          handler: () => {
            this.onDurationReset();
          }
        },
        {
          text: 'Hecho',
          role: 'done',
          handler: (data) => {
            this.onDurationSelected( data );
          }
        }
      ],
      columns: [
        {
          name: 'time',
          options: timeValues
        },
        {
          name: 'units',
          options: [
            {
              value: RCTimeUnit.MINUTE,
              text: 'minutos'
            },
            {
              value: RCTimeUnit.HOUR,
              text: 'horas'
            },
            {
              value: RCTimeUnit.DAY,
              text: 'dias'
            },
          ]
        }
      ]
    };



    let picker = await this.pickerCtrl.create(opts);

    picker.present();

    //picker.onDidDismiss().then(async data => {});

  }


  private onDurationSelected( data ) {

    this.duration = data.time.value;
    this.durationUnit = data.units.value;

    // *** update form
    this.updateForm();

    // *** emit duration
    this.updateDuration();

  }

  private onDurationReset() {

    this.duration = -1;
    this.durationUnit = RCTimeUnit.UNDEFINED;

    this.updateForm();

    this.updateDuration();

  }

  private getPickerTimeValues() {

    let timeValues = [];

    for( let timeValue = 1; timeValue <= 60; timeValue++ ) {

      let time = {
        text: timeValue + "",
        value: timeValue
      }

      timeValues.push( time );
    }

    return timeValues;
  }


  // *** duration TXT
  private updateDurationTXT( time, unit ) {

    let txt;

    if( time !== -1 && unit !== RCTimeUnit.UNDEFINED ) {

      txt = time + ' ' + this.getDurationUnitTXT( time, unit );


    } else {
      
      txt = null;
    }

    this.rcForm.controls['durationTXT'].setValue(txt);

  }

  private getDurationTXT( time, unit ) {

    let txt;

    if(
      typeof time !== 'undefined' &&
      typeof unit !== 'undefined' &&
      time !== -1 &&
      unit !== RCTimeUnit.UNDEFINED ) {

      txt = time + ' ' + this.getDurationUnitTXT( time, unit );

    }

    return txt;
  }

  private getDurationUnitTXT( time, unit ) {

    let txt;

    switch( unit ) {

      case RCTimeUnit.MINUTE:
        txt = 'minuto';
        break;
      case RCTimeUnit.HOUR:
        txt = 'hora';
        break;
      case RCTimeUnit.DAY:
        txt = 'dia';
        break;
    }

    if( time > 1 ) {

      txt += 's';
    }

    return txt;
    }



}
