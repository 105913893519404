import * as suncalc from 'suncalc'; // https://www.npmjs.com/package/suncalc
import * as moment from 'moment';

export class TimeHelper {

  public static getWeekByDate( date ) {

    let startOfWeek = moment( date ).startOf('isoWeek');
    let endOfWeek = moment( date ).endOf('isoWeek');

    let week = {
      ini: startOfWeek,
      end: endOfWeek
    }

    return week;
  }

  public static getWeek( date ) {

    let startOfWeek = moment( date ).startOf('isoWeek');
    let endOfWeek = moment( date ).endOf('isoWeek');


    let week = {
      ini: startOfWeek,
      end: endOfWeek
    }

    return week;
  }

  public static getLocalWeek( date ) {

    let startOfWeek = moment( date ).startOf('isoWeek');
    let endOfWeek = moment( date ).endOf('isoWeek');

    let offset = moment.parseZone(startOfWeek).utcOffset();

    startOfWeek.add( offset, 'minutes');
    endOfWeek.add(offset, 'minutes');


    let week = {
      ini: startOfWeek.utc(),
      end: endOfWeek.utc()
    }

    return week;
  }


  public static updateTime( date, time ) {

    let dateM = moment( date );
    let timeM = moment( time );

    dateM = dateM.hour( timeM.get('hour'));
    dateM = dateM.minute( timeM.get('minute'));


    return dateM.toDate();
  }

  public static getSunTime( coords, date = new Date() ) {

    let sunTime;

    if( coords !== null ) {

      let times = suncalc.getTimes( date, coords.lat, coords.long );

      let sunrise = moment( times.sunrise );
      let sunset = moment( times.sunset );

      sunTime = {

        sunrise: {
          h: sunrise.hour(),
          m: sunrise.minutes()
        },
        sunset: {
          h: sunset.hour(),
          m: sunset.minutes()
        }
      }

    } else {

      sunTime = {

        sunrise: {
          h: 7,
          m: 0
        },
        sunset: {
          h: 19,
          m: 0
        }
      }
    }

    return sunTime;

  }

}
