import { Component, OnInit, Input } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';

import { Interest } from '../../../entities/interest';
import { InterestValue } from '../../../entities/interest-value';
import { InterestsService } from '../../../services/interests.service';
import { ProfilesService } from '../../../services/profiles.service';



@Component({
  selector: 'app-interest-card',
  templateUrl: './interest-card.component.html',
  styleUrls: ['./interest-card.component.scss'],
})
export class InterestCardComponent implements OnInit {


  @Input() interest: Interest;
  @Input() interestType;

  public removeAlert: any;

  public interestForm: FormGroup;

  public visibleForm: boolean = false;
  public disabledSubmit: boolean = false;

  public loading;

  public icon: string = 'broken-robot';

  // *** C
  constructor(
    private interestsS: InterestsService,
    private formBuilder: FormBuilder,
    private alertCtrl: AlertController,
    private profilesS: ProfilesService,
    public loadingCtrl: LoadingController
  ) {

  }


  // *** Lifecycle
  ngOnInit() {

    this.setType();
    this.setIcon();
    this.createForm();
    this.setFormVisibility();

  }

  // *** interest type
  private setType() {

    if( typeof this.interest !== 'undefined' && this.interest !== null ) {

      this.interestType = this.interest.value;
    }
  }


  // *** action
  public onSubmit() {

    this.loadingCtrl.create({
      message: 'actualizando intereses...'
    }).then(
      ( spinner ) => {

        this.loading = spinner;
        this.loading.present();

      }
    );

    this.updateInterests$()
    .then(
      () => {
        setTimeout(
          () => {

            this.loading.dismiss();
          },
        1000 );


      }).catch((err) => {});
  }

  public onRemove() {

    this.removeInterest$();

  }


  // *** form
  private createForm() {

    this.interestForm = this.formBuilder.group({
      txt: [null, Validators.required]
    });

     this.setFormEventsHandlers();
  }


  // *** form visibility
  private setFormVisibility() {

    if( typeof this.interest === 'undefined' || this.interest === null ) {

      this.visibleForm = true;

    }

  }

  private getFormValues() {

    let values = {

      txt: this.interestForm.value.txt,
      value: this.interestType

    }

    return values;
  }


  // *** form events
  private setFormEventsHandlers() {

    this.interestForm.valueChanges.subscribe(
      ( value ) => {

        this.disabledSubmit = !this.interestForm.invalid;
      }
    );
  }


  // *** interest
  private updateInterests$() {

    this.interest = new Interest( this.getFormValues());

    this.interest.profile = this.profilesS.getCurrentProfileID();

    this.interestForm.reset();

    return this.interestsS.createInterest$( this.interest );

  }

  private removeInterest$() {

    this.launchRmInterestConfirm();

  }



  // *** icon
  private setIcon() {

    switch( this.interestType ) {

      case InterestValue.LIKES:
        this.icon = 'good';
        break;
      case InterestValue.DISLIKES:
        this.icon = 'bad';
        break;
      case InterestValue.INTERESTED:
        this.icon = 'interesed';
        break;
      default:
        this.icon = 'broken-robot';
        break;
    }


  }

  private async launchRmInterestConfirm() {


    this.removeAlert = await this.alertCtrl.create({
      message: '¿deseas borrar esta entrada?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            return;
          }
        }, {
          text: 'Si',
          role: 'accept',
          cssClass: 'secondary',
          handler: () => {

            this.interestsS.removeInterest$( this.interest );

            return;
          }
        }
      ]
    });

    await this.removeAlert.present();
  }
}
