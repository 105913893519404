import { Component, OnInit, Input } from '@angular/core';

import { RCInfoField } from '../../../entities/rc-info-field';
import { RCTInfoField } from '../../../entities/rct-info-field';

@Component({
  selector: 'app-rc-card-txt-info-field',
  templateUrl: './rc-card-txt-info-field.component.html',
  styleUrls: ['./rc-card-txt-info-field.component.scss'],
})
export class RCCardTxtInfoFieldComponent implements OnInit {

  @Input() infoField: RCInfoField;
  @Input() rctInfoField: RCTInfoField;
  @Input() index: number;

  // *** C
  constructor() {}


  // *** Lifecycle
  ngOnInit() {}



}
