export class User {

  _id: string;
  us: string;
  displayName: string;
  pass: string;

  constructor(obj) {
    obj && Object.assign(this, obj);
  }

}
