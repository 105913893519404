

export class RCTInfoFieldOption {


  // *** Attrs
  label: string;
  value: number;
  icon?: string = null;


  // *** C
  constructor( obj ) {

    if( obj ) {

      Object.assign(this, obj);
    }
  }
}
