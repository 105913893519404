import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { Device } from '@ionic-native/device/ngx';
import { Ionic4DatepickerModule } from '@logisticinfotech/ionic4-datepicker';
// ** src: https://devdactic.com/ionic-4-login-angular/ **//
import { IonicStorageModule } from '@ionic/storage'
import { ViewComponentsModule } from './view-components/view-components.module';
import { TimeoutInterceptor, DEFAULT_TIMEOUT } from './interceptors/timeout-interceptor';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';

import { Geolocation } from '@ionic-native/geolocation/ngx';

// permite swipe y scroll vertical en IOS
// src: https://stackoverflow.com/a/41522140
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';

export class MyHammerConfig extends HammerGestureConfig {
    overrides = <any> {
        'pinch': { enable: false },
        'rotate': { enable: false }
    }
}



// *** src: https://angular.io/guide/i18n#setting-up-the-locale-of-your-app *** //
// *** https://es.stackoverflow.com/questions/194958/como-cambiar-lenguaje-de-fecha-data-pipe-angular-6 *** //
import { registerLocaleData } from '@angular/common';
import localeES from '@angular/common/locales/es';
import localeESExtra from '@angular/common/locales/extra/es';

registerLocaleData(localeES, 'es-ES', localeESExtra );


@NgModule({
  declarations: [
    AppComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    ViewComponentsModule,
    Ionic4DatepickerModule,
    FormsModule,
    IonicStorageModule.forRoot()
  ],
  providers: [
    StatusBar,
    FileTransfer,
    File,
    SplashScreen,
    {
      provide:
      RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    {
      provide: LOCALE_ID,
      useValue: 'es-ES'
    },
    {
            // permite swipe y scroll vertical en IOS
            // src: https://stackoverflow.com/a/41522140
            provide: HAMMER_GESTURE_CONFIG,
            useClass: MyHammerConfig
    },
    LocalNotifications,
    Device,
    Geolocation,
    ScreenOrientation,
    [ // src: https://stackoverflow.com/a/45986060
      {
        provide: HTTP_INTERCEPTORS,
        useClass: TimeoutInterceptor,
        multi: true
      }
    ],
    [
      {
        provide: DEFAULT_TIMEOUT,
        useValue: 30000
      }
    ]
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {}
