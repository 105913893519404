import { Component, OnInit, SimpleChanges, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { RecordsService } from '../../../services/records.service';
import { ProfilesService } from '../../../services/profiles.service';
import { RecordsOpsService } from '../../../services/records-ops.service';

import { Record } from '../../../entities/record';

import { Paths } from '../../../navigation/paths';


import * as moment from 'moment';

@Component({
  selector: 'app-records-daily-timetable',
  templateUrl: './records-daily-timetable.component.html',
  styleUrls: ['./records-daily-timetable.component.scss'],
})
export class RecordsDailyTimetableComponent implements OnInit {

  @Output() scrollRequest: EventEmitter<any> = new EventEmitter<any>(); //creating an output event

  private timeEntries: string[] = [];
  public records: any = [];
  private updatedRecord$: Subscription;
  private updatedRecord: boolean = false;
  private lastRecord: Record;
  private currentDate$: Subscription;
  private planningVisible: boolean = false;
  private currentDate: any;
  private removedRecords$: Subscription;

  private iniTimeEntries: any = [];
  private endTimeEntries: any = [];

  private isLoading = false;
  private loading: any;

  private isToday; boolean = false;

  public nowIndex = 0;


  private SCROLL_OFFSET: number = 158;

  // *** C *** //
  constructor(
    private recordsS: RecordsService,
    public loadingCtrl: LoadingController,
    private profilesS: ProfilesService,
    private recordsOpsS: RecordsOpsService,
    private cd: ChangeDetectorRef,
    private router: Router
  ) {

    this.setUpdatedRecordEventHandlers();
    this.setCurrentDateEventHandlers();
    this.setRecordsOpsEventsHandlers();
    this.loadRecords( false );

  }


  // *** Lifecycle events
  ngOnInit() {

    this.loadTimeEntries();

    this.currentDate = this.recordsS.getCurrentDate();

  }


  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks¡
    this.removedRecords$.unsubscribe();
    // unsubscribe to ensure no memory leaks¡
    this.updatedRecord$.unsubscribe();

    this.currentDate$.unsubscribe();


  }


  // *** Current Date *** //
  private setCurrentDateEventHandlers() {

    this.currentDate$ = this.recordsS
    .getCurrentDateObservable().subscribe( currentDate => {

      this.currentDate = currentDate;

      this.loadRecords( this.updatedRecord );
    })
  }


  // *** Records load *** //
  private loadRecords( updatedRecord = false ) {

    this.updatedRecord = updatedRecord;

    let profileID = this.profilesS.getCurrentProfileID();

    this.presentLoading();

    this.recordsS.getCurrentDateRecords$( profileID )
    .then(
      ( records: any ) => {

        this.onLoadRecords( records );
      }

    )
    .catch( err => {

      let errJson = {};

      try {

        if( err ) {

          errJson = JSON.stringify(err);
        }
      } catch(error) {};


      this.dismissLoading();

      this.router.navigate(['/' + Paths.ERROR, errJson]);

    });

  }

  private onLoadRecords( records ) {

    this.isToday = this.recordsS.isToday();
    this.setNowIndex();

    this.records = null;
    this.records = records;

    this.loadTimeEntries();

    if( this.records && this.records.length > 0 ) {

      this.loadEmptyEntries();

    }

    this.cd.detectChanges();    // <--- added


    setTimeout(() => {

      this.dismissLoading();

      let yOffset = 0;

      if( !this.updatedRecord ) {

        yOffset = this.getCurrentTimeEntryYOffset();

      } else {

        yOffset = this.getLastRecordYOffset();
      }

      this.scrollRequest.emit({yOffset:yOffset});

    }, 300 );

  }

  private presentLoading() {

    if( !this.isLoading ) {

      this.loadingCtrl.create({
        message: 'Cargando registros...'
      }).then(
        ( spinner ) => {

          this.loading = spinner;
          this.loading.present();

        }
      );
    }
  }

  private dismissLoading() {

    if( this.loading ) {

      this.loading.dismiss().then(() => {

        this.isLoading = false;

      });

    }
  }


  // *** Record ops *** //
  private setRecordsOpsEventsHandlers() {

    this.removedRecords$ = this.recordsS
    .getRemovedRecords$()
    .subscribe( () => {
        this.loadRecords();
    });

  }


  // *** New record *** //
  private setUpdatedRecordEventHandlers() {

    this.updatedRecord$ = this.recordsS
    .getUpdatedRecordObservable()
    .subscribe( record => {
      this.onUpdatedRecord( record );
    });
  }

  private onUpdatedRecord( record ) {

    this.lastRecord = record;

    let recordMoment = moment( record.date );
    let currentMoment = moment( this.recordsS.getCurrentDate() );

    if( recordMoment.isSame( currentMoment )) {

      this.loadRecords();

    } else {

      this.updatedRecord = true;

      this.recordsS.setCurrentDate( recordMoment.toDate() );

    }



  }


  // *** Records list *** //
  public getRecordsInPos( pos ) {

    return this.records[pos];

  }

  private loadTimeEntries() {

    this.timeEntries = [];

    let h = 0;

    while( h < 24 ) {

      this.timeEntries.push( h + ' : 00');
      this.timeEntries.push( h + ' : 30');

      h++;

    }
  }

  private loadEmptyEntries() {

    let iniFlag = false;

    let timeEntriesLength = this.timeEntries.length;

    this.iniTimeEntries = [];
    this.endTimeEntries = [];

    for( let i = 0; i < timeEntriesLength; i++ ) {

      if( iniFlag === false && this.records[i].length === 0 ) {

        iniFlag = true;

        this.iniTimeEntries.push( i );

      } else if( iniFlag === true  && this.records[i].length > 0 ) {

        iniFlag = false;

        if( i > 0 ) {

          this.endTimeEntries.push( i - 1 );
        }

      } else if( i === timeEntriesLength -1 && iniFlag === true  ) {

          this.endTimeEntries.push( i );
          this.timeEntries[i] = '23 : 59'
      }
    }
  }

  private visibleEmptyEntry( pos ) {

    let visible = false;

    if(
      this.iniTimeEntries.includes( pos )
      || this.endTimeEntries.includes( pos ) ) {

      visible = true;

    } else {

      visible = false;

    }

    return visible;

  }

  public getCurrentTimeEntryYOffset() {

    let index = this.nowIndex;

    //let el = document.getElementById('entry-' + index);
    let el = document.getElementById('entry-' + index);

    let offsetTop = 0;

    if( el ) {

      offsetTop = el.offsetTop - this.SCROLL_OFFSET;

    }

    return offsetTop;

  }

  public getLastRecordYOffset() {



    let index = this.dateIndex( new Date(this.lastRecord.time) );

    //let el = document.getElementById('entry-' + index);
    let el = document.getElementById('entry-' + index);

    let offsetTop = 0;

    if( el ) {

      offsetTop = el.offsetTop - this.SCROLL_OFFSET;

    }

    return offsetTop;

  }

  public setNowIndex() {

    let now = new Date();

    this.nowIndex = this.dateIndex(now);

  }

  private dateIndex( date ) {

    let hour = date.getHours();
    let min = date.getMinutes();

    let index = hour * 2;

    if( min >= 30 ) {

      index++;

    }


    return index;

  }


  // *** Helpers *** //
  public isCurrentDateToday() {

    return this.isToday;

  }

}
