import { Component, OnInit, Input } from '@angular/core';

import { Record } from '../../../entities/record';
import { RecordType } from '../../../entities/record-type';
import { CESFPlace } from '../../../entities/cesf-place';


@Component({
  selector: 'app-record-weekly-card-info-icons',
  templateUrl: './record-weekly-card-info-icons.component.html',
  styleUrls: ['./record-weekly-card-info-icons.component.scss'],
})
export class RecordWeeklyCardInfoIconsComponent implements OnInit {

  @Input() record: Record;

  public visible: boolean = false;
  public icons = [];

  // *** C
  constructor() { }


  // *** Lifecycle
  ngOnInit() {


    this.setVisibility();

    this.setIcons();

  }





  // *** icons
  private setIcons() {

    switch( this.record.type ) {

      case RecordType.CONTROL_ESFINTER:

        this.setCESFIcons();

        break;

    }

  }


  private setCESFIcons() {

    if( this.record.recordInfo.place === CESFPlace.DIAPER ) {

      this.icons.push('esfinter/diaper');

    } else if( this.record.recordInfo.place === CESFPlace.WC ) {

      this.icons.push('esfinter/wc');

    }  else if( this.record.recordInfo.place === CESFPlace.SELF ) {

      this.icons.push('esfinter/angry');

    }


    if( this.record.recordInfo.pis ) {

      this.icons.push('esfinter/pis');

    }

    if( this.record.recordInfo.caca ) {

      this.icons.push('esfinter/caca');

    }

    if( this.record.recordInfo.nada ) {

      this.icons.push('esfinter/not-applicable');
    }
  }




  // *** Visibility
  private setVisibility() {

    if( this.record.type === RecordType.CONTROL_ESFINTER ) {

      this.visible = true;

    }
  }

}
