import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, PickerController, NavParams }  from '@ionic/angular';
import { PickerOptions, PickerButton } from '@ionic/core';

import { Validators, FormBuilder, FormGroup } from '@angular/forms';

import { RecordsService } from '../../../services/records.service';
import { UsersService } from '../../../services/users.service';
import { ProfilesService } from '../../../services/profiles.service';
import { InterventionsService } from '../../../services/interventions.service';

import { RecordValoration } from '../../../entities/record-valoration';
import { RecordType } from '../../../entities/record-type';
import { CESFPlace } from '../../../entities/cesf-place';
import { Record } from '../../../entities/record';
import { CESFRecordInfo } from '../../../entities/cesf-record-info';
import { InterventionType } from '../../../entities/intervention-type';

import { Paths } from '../../../navigation/paths';

@Component({
  selector: 'app-control-esfinter-record-modal',
  templateUrl: './control-esfinter-record-modal.component.html',
  styleUrls: ['./control-esfinter-record-modal.component.scss'],
})
export class CESFRecordModal implements OnInit {


  // *** VARs *** //
  public recordForm: FormGroup;
  public submitted: boolean = false;

  private valorationTXT = [];
  private placeTXT = [];

  private record: Record;
  //private clone: boolean = false;

  public submitBtnTxt = 'CREAR';

  public enabledDateField: boolean = false;
  public hiddenCloneButton: boolean = true;

  // *** C *** //
  constructor(
    public modalCtrl: ModalController,
    public navParams: NavParams,
    private pickerCtrl: PickerController,
    private formBuilder: FormBuilder,
    private recordsS: RecordsService,
    private profilesS: ProfilesService,
    private interventionsS: InterventionsService,
    private usersS: UsersService,
    private router: Router
  ) {


    this.initValorationTXT();
    this.initPlaceTXT();
    this.readModalParams();
    this.createForm();

   }

  // *** Lifecycle events -->
  ngOnInit() {}


  // ** convenience getter for easy access to form fields ** //
  private get f() {
    return this.recordForm.controls;
  }


  // *** modal params
  public readModalParams() {

    this.record = this.navParams.get('record');

    if( this.record ) {

      this.submitBtnTxt = 'MODIFICAR';
      this.enabledDateField = true;
      this.hiddenCloneButton = false;

    }

  }


  // *** init literals
  private initValorationTXT() {

    this.valorationTXT[RecordValoration.EXCELENT] = 'Excelente';
    this.valorationTXT[RecordValoration.GOOD] = 'Bien';
    this.valorationTXT[RecordValoration.NOT_BAD] = 'Casi bien';
    this.valorationTXT[RecordValoration.BAD] = 'Otra véz será';

  }

  private initPlaceTXT() {

    this.placeTXT[CESFPlace.WC] = 'En el WC';
    this.placeTXT[CESFPlace.DIAPER] = 'En el pañal';
    this.placeTXT[CESFPlace.SELF] = 'Se manchó';

  }


  // *** form ops *** //
  private createForm() {

    let recordDate;
    let recordTime;

    let valoracion = '';
    let valoration = -1;
    let pis = false;
    let caca = false;
    let nada = false;
    let donde = '';
    let place = -1;
    let observations = '';

    if( !this.record ) {

      let now = new Date()

      recordDate = this.recordsS.getCurrentDate();
      recordTime = recordDate;

    } else {

      recordDate = this.record.date;
      recordTime = this.record.time;
      valoration = this.record.valoration;
      valoracion = this.valorationTXT[valoration];
      pis = this.record.recordInfo.pis;
      caca = this.record.recordInfo.caca;
      nada = this.record.recordInfo.nada;
      place = this.record.recordInfo.place;
      donde = this.placeTXT[place];
      observations = this.record.observations;
    }

    this.recordForm = this.formBuilder.group({

      date: [recordDate.toString(), Validators.required],
      time: [recordTime.toString(), Validators.required],
      valoracion: [valoracion],
      valoration: [valoration],
      pis: [pis],
      caca: [caca],
      nada: [nada],
      donde: [donde],
      place: [place],
      comentarios: [observations]

    });

    this.setFormEventsHandlers();

  }

  private setFormEventsHandlers() {


    this.recordForm.get('pis').valueChanges.subscribe(

      ( val ) => {

        if( val === true ) {

          this.recordForm.controls['nada'].setValue(false);

        }

      }

    );
    this.recordForm.get('caca').valueChanges.subscribe(

      ( val ) => {


        if( val === true  ) {

          this.recordForm.controls['nada'].setValue(false);
        }


      }

    );
    this.recordForm.get('nada').valueChanges.subscribe(

      ( val ) => {

        if( val === true ) {

          this.recordForm.controls['pis'].setValue(false);
          this.recordForm.controls['caca'].setValue(false);
        }


      }
    );
    this.recordForm.get('comentarios').valueChanges.subscribe(

      ( val ) => {

        if( val.length === 0 ) {

          let commentsField = this.recordForm.controls['comentarios'];

        }
      }

    );
  }

  public async onSubmit() {

    if( this.record ) {

      this.onSubmitModifyRecord();

    } else {

      this.onSubmitNewRecord();
    }
  }

  public onClone() {

      this.onSubmitNewRecord();

  }

  public onSubmitNewRecord() {

    this.submitted = true;

    if( this.recordForm.invalid ) {

      this.submitted = false;

      return;

    } else {

      let values = this.getRecordValues();

      let newRecord = new Record( values );

      this.recordsS.storeNewRecord$( newRecord )
      .then(
        ( data ) => {

          this.interventionsS.loadInterventions$( this.usersS.getCurrentUserID(), this.profilesS.getCurrentProfileID() )
          .then(
            () => {

              if( this.interventionsS.isInterventionActive( InterventionType.CONTROL_ESFINTER )) {

                this.deactivateCESFIntervention().then(
                  () => {
                      this.activateCESFIntervention();
                  }
                );
              }

              this.modalCtrl.dismiss();
            }
          )
        }
      )
      .catch(
        err => {

          let errJson = JSON.stringify(err);

          this.router.navigate(['/' + Paths.ERROR, errJson]);

          let data = {
            error: 'error'
          }

          this.submitted = false;

          this.modalCtrl.dismiss( data );
        }
      );
    }

  }

  public onSubmitModifyRecord() {

    this.submitted = true;

    if( this.recordForm.invalid ) {

      this.submitted = false;

      return;

    } else {

      this.updateRecord();


      this.recordsS.updateRecord$( this.record )
      .then(
        ( data ) => {

          this.modalCtrl.dismiss();
        }
      )
      .catch(
        err => {

          let errJson = JSON.stringify(err);

          this.router.navigate(['/' + Paths.ERROR, errJson]);

          let data = {
            error: 'error'
          }

          this.submitted = false;

          this.modalCtrl.dismiss( data );
        }
      );
    }


  }

  private getRecordValues() {

    let recordInfo: CESFRecordInfo = new CESFRecordInfo(
      {
        pis: this.recordForm.value.pis,
        caca: this.recordForm.value.caca,
        nada: this.recordForm.value.nada,
        place: parseInt(this.recordForm.value.place)
      }
    )

    let isoDate = new Date( this.recordForm.value.date ).toISOString();
    let isoTime = new Date( this.recordForm.value.time ).toISOString();


    let values = {
      type: RecordType.CONTROL_ESFINTER,
      date: isoDate,
      time: isoTime,
      valoration: this.recordForm.value.valoration,
      observations: this.recordForm.value.comentarios,
      author: this.usersS.getCurrentUserID(),
      profile: this.profilesS.getCurrentProfileID(),
      recordInfo: recordInfo
    }

    return values;

  }


  // record ops
  public updateRecord() {

    let values = this.getRecordValues();

    let recordID = this.record._id;

    this.record = new Record( values );

    this.record._id = recordID;

  }


  // pis, caca & nada
  public onWhatChange() {

    this.updateValoration();
  }


  // *** Valoración
  public onClickValoracion() {

    this.showValoracionPicker();

  }

  private updateValoration() {

    let values = this.getRecordValues();

    let valoration = values.recordInfo.getValoration();

    this.recordForm.controls['valoration']
    .setValue( valoration );

    this.recordForm.controls['valoracion']
    .setValue( this.valorationTXT[valoration] );

  }


  // src: https://ionicacademy.com/how-to-ion-picker-component/
  private async showValoracionPicker() {
    let opts: PickerOptions = {
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel'
        },
        {
          text: 'Hecho',
          role: 'done',
          handler: (data) => {
            this.onValoracionSelected( data );
          }
        }
      ],
      columns: [
        {
          name: 'valoracion',
          options: [
            {
              text:  this.valorationTXT[RecordValoration.EXCELENT],
              value: RecordValoration.EXCELENT
            },
            {
              text: this.valorationTXT[RecordValoration.GOOD],
              value: RecordValoration.GOOD
            },
            {
              text: this.valorationTXT[RecordValoration.NOT_BAD],
              value: RecordValoration.NOT_BAD
            },
            {
              text: this.valorationTXT[RecordValoration.BAD],
              value: RecordValoration.BAD
            }
          ]
        }
      ]
    };



    let picker = await this.pickerCtrl.create(opts);

    picker.present();

    //picker.onDidDismiss().then(async data => {});
  }

  private onValoracionSelected( data ) {

    let valoracion = data.valoracion;
    let text = valoracion.text;
    let val = valoracion.value;

    this.recordForm.controls['valoracion'].setValue(text);
    this.recordForm.controls['valoration'].setValue(val);

  }


  // *** lugar OPs
  public onClickLugar() {

    this.showLugarPicker();

  }

  // src: https://ionicacademy.com/how-to-ion-picker-component/
  private async showLugarPicker() {


    let opts: PickerOptions = {
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel'
        },
        {
          text: 'Hecho',
          role: 'done',
          handler: (data) => {
            this.onLugarSelected( data );
          }
        }
      ],
      columns: [
        {
          name: 'lugar',
          options: [
            {
              text:  this.placeTXT[CESFPlace.WC],
              value: CESFPlace.WC
            },
            {
              text:  this.placeTXT[CESFPlace.DIAPER],
              value: CESFPlace.DIAPER
            },
            {
              text:  this.placeTXT[CESFPlace.SELF],
              value: CESFPlace.SELF
            },
          ]
        }
      ]
    };


    let picker = await this.pickerCtrl.create(opts);

    picker.present();

  }

  private onLugarSelected( data ) {

    let lugar = data.lugar;
    let text = lugar.text;
    let val = lugar.value;

    this.recordForm.controls['donde'].setValue(text);
    this.recordForm.controls['place'].setValue(val);

    this.updateValoration();

  }


  // *** cesf intervention
  private deactivateCESFIntervention() {

    return new Promise(( resolve, reject ) => {

      this.interventionsS.deactivateCESFIntervention()
      .then(
        () => {

          resolve();
        }
      )
      .catch((err) => {
        reject( err );
      });


    });


  }

  private activateCESFIntervention() {

    this.interventionsS.activateCESFIntervention()
    .then().catch( (err) => {
      let errJson = JSON.stringify(err);
      this.router.navigate(['/' + Paths.ERROR, errJson]);
    });


  }


  // *** time
  public onClickNow() {

    let now = new Date();

    this.recordForm.controls['date'].setValue(now.toString());
    this.recordForm.controls['time'].setValue(now.toString());

  }


  // *** modal OPS *** //
  public onClickCloseModal() {

    this.modalCtrl.dismiss();

  }

}
