import { RecordValoration } from './record-valoration';

export class EventRecordInfo {

  eventName: string;
  eventDescription?: string;
  eventReaction?: string;


  constructor(obj) {
    obj && Object.assign(this, obj);
  }


  public getValoration() {

    return RecordValoration.UNDEFINED;

  }
}
