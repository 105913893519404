import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';


import {
  Router,
  ActivatedRoute,
  NavigationStart,
  NavigationEnd,
  UrlTree,
  UrlSegmentGroup,
  UrlSegment
} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private previousURL$ = new Subject<any>();
  private currentURL$ = new Subject<any>();

  private urlStack: string[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navCtrl: NavController
  ) {

    this.initRouterChangeDetection();
  }


  // *** observables
  getPreviousURLObservable(): Observable<any> {

    return this.previousURL$.asObservable();

  }

  getCurrentURLObservable(): Observable<any> {

    return this.currentURL$.asObservable();

  }

  getCurrentURL$(): Observable<any> {

    return this.currentURL$.asObservable();

  }


  // *** navigation
  push( url ) {

    if( this.urlStack.length > 0 ) {

      const currentURL = this.urlStack[ this.urlStack.length - 1]

      this.previousURL$.next( currentURL );

    }


    this.urlStack.push( url );

    this.router.navigate([url]);

  }

  ghostPush( url ) {

    if( this.urlStack.length > 1 ) {

      const currentURL = this.urlStack[ this.urlStack.length - 2]

      this.previousURL$.next( currentURL );

    }

    // src: https://angular.io/api/router/NavigationExtras#replaceUrl
    this.router.navigate([url], { replaceUrl: true });

  }

  pop() {

    if( this.urlStack.length > 0 ) {

      this.urlStack.pop();

    }

    if( this.urlStack.length > 0 ) {

      const url = this.urlStack[this.urlStack.length - 1];

      this.navCtrl.navigateBack( url );

    }

    if( this.urlStack.length > 1 ) {

      const url = this.urlStack[this.urlStack.length - 2];

      this.previousURL$.next( url);

    }

  }

  // *** PRIVATE *** //
  private initRouterChangeDetection() {

    // src: https://stackoverflow.com/questions/33520043/how-to-detect-a-route-change-in-angular?rq=1
    this.router.events.forEach( ( evt ) => {

      if( evt instanceof NavigationStart ) {

        //this.onNavigationStart( evt );
      }
      if( evt instanceof NavigationEnd ) {

        this.onNavigationEnd( evt );
      }
    });

  }

  private onNavigationStart( evt ) {

    // src: https://angular.io/api/router/Navigation
    // src: https://angular.io/api/router/UrlTree
    // current URL
    const urlTree = this.router.parseUrl(this.router.url);

    if( urlTree.root.children['primary'] ) {

      const previousURL = urlTree.root.children['primary'].segments.map(it => it.path).join('/');

      this.previousURL$.next( previousURL );
    }

  }

  private onNavigationEnd( evt ) {

    // src: https://angular.io/api/router/Navigation
    // src: https://angular.io/api/router/UrlTree
    // current URL

    // currentRoute
    const urlTree = this.router.parseUrl(this.router.url);

    if( urlTree.root.children['primary'] ) {

      const currentURL = urlTree.root.children['primary'].segments.map(it => it.path).join('/');

      this.currentURL$.next( currentURL );
    }




  }





}
