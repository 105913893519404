

export class RCUI {

  static MODES = {

    LAST: 0,
    MOST_USED: 1
  };
}
