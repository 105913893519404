import { BehaviorType } from './behavior-type';


export class Behavior {

    static UNDEFINED = -1;

    // *** SELF_INJURIOUS
    static BITEHIMSELF = 0;
    static HIT_HIS_EYES = 1;
    static HIT_HIS_HEAD = 2;
    static HIT_HIS_HEAD_WITH_OBJECT = 3;
    static HIT_HIMSELF = 4;
    static PULL_HIS_HAIR = 5;
    static HORN_HIMSELF = 6;
    static PINCH_HIMSELF = 7;
    static POUND_HIMSELF = 8;

    // *** AGGRESIVE
    static BITE = 30;
    static GRAB_TIGHT = 31;
    static PULL_OTHERS_HAIR = 32;
    static BUTT_OTHER = 33;
    static HIT_OTHER = 34;
    static KICK_OTHER = 35;
    static PINCH_OTHER = 36;
    static PUSH_OTHER = 37;
    static HORN_OTHER = 38;
    static POUND_OTHER = 39;
    static THROW_OBJECT_TO_OTHER = 40;

    // *** DISTURBANCE
    static HIT_OBJECTS = 60;
    static REMOVE_OBJECTS_FROM_TABLE = 61;
    static KICK_OBJECTS = 62;
    static SCREAM = 63;
    static BAWL = 64;
    static BREAK_OBJECTS = 65;
    static THROW_OBJECTS = 66;

    // *** NON_COMPLIANCE
    static SCREECH_WHEN_ASKED = 90;
    static BECOME_AGGRESSIVE_WHEN_ASKED = 91;
    static UPSET_WHEN_ASKED = 92;
    static GET_DOWN_ON_THE_FLOOR = 93;
    static HARM_HIMSELF_WHEN_ASKED = 94;

    // *** OTHER
    static OTHER = 999;



}
