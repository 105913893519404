import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { RecordsService } from '../../../services/records.service';
import { ProfilesService } from '../../../services/profiles.service';
import { RecordsWeeklyHelper } from '../../../helpers/records-weekly-helper';

import { Paths } from '../../../navigation/paths';


@Component({
  selector: 'app-records-weekly-timetable',
  templateUrl: './records-weekly-timetable.component.html',
  styleUrls: ['./records-weekly-timetable.component.scss'],
})
export class RecordsWeeklyTimetableComponent implements OnInit {

  public weeklyRecords;

  private currentDate: any;
  private currentDate$: Subscription;

  private records = Array(7);

  private isLoading = false;
  private loading: any;


  // *** C
  constructor(
    private recordsS: RecordsService,
    public loadingCtrl: LoadingController,
    private profilesS: ProfilesService,
    private cd: ChangeDetectorRef,
    private router: Router
  ) {

    this.setCurrentDateEventHandlers();

  }


  // *** lifecycle
  ngOnInit() {
    this.loadRecords();
  }


  ngOnDestroy() {

    this.currentDate$.unsubscribe();


  }

  // *** Current Date *** //
  private setCurrentDateEventHandlers() {

    this.currentDate$ = this.recordsS
    .getCurrentDateObservable().subscribe( currentDate => {

      this.currentDate = currentDate;

      this.loadRecords();
    })
  }


  // *** records
  private loadRecords() {


    let profileID = this.profilesS.getCurrentProfileID();

    this.presentLoading();

    this.recordsS.getCurrentDateWeeklyRecords$( profileID )
    .then(
      ( records: any ) => {

        this.onLoadRecords( records );
      }

    )
    .catch( err => {

      this.onLoadRecordsError( err );


    });

  }

  private onLoadRecords( records ) {

    this.records = records;

    this.initWeeklyRecords();

    this.cd.detectChanges();


    this.dismissLoading();

  }

  private onLoadRecordsError( err ) {

    let errJson = {};

    try {

      if( err ) {

        errJson = JSON.stringify(err);
      }
    } catch(error) {};


    this.dismissLoading();
    this.router.navigate(['/' + Paths.ERROR, errJson]);

  }


  // *** weekly records
  public initWeeklyRecords() {

    this.weeklyRecords = RecordsWeeklyHelper.createWeeklyRecordsList( this.records );
  }

  public getTimeCol( i ) {

    return this.weeklyRecords[i];
  }

  public getRecordsCol( i, k ) {

    return this.weeklyRecords[i][k];
  }


  // *** loading
  private presentLoading() {

    if( !this.isLoading ) {

      this.loadingCtrl.create({
        message: 'Cargando registros...'
      }).then(
        ( spinner ) => {

          this.loading = spinner;
          this.loading.present();

        }
      );
    }
  }

  private dismissLoading() {

    if( this.loading ) {

      this.loading.dismiss().then(() => {

        this.isLoading = false;

      });

    }
  }

}
