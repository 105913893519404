import { Component, OnInit, Input } from '@angular/core';

import{ RCInfoField } from '../../../entities/rc-info-field';
import{ RCTInfoField } from '../../../entities/rct-info-field';
import{ RCTInfoFieldType } from '../../../entities/rct-info-field-type';

@Component({
  selector: 'app-rc-card-select-info-field',
  templateUrl: './rc-card-select-info-field.component.html',
  styleUrls: ['./rc-card-select-info-field.component.scss'],
})
export class RCCardSelectInfoFieldComponent implements OnInit {

  @Input() infoField: RCInfoField;
  @Input() rctInfoField: RCTInfoField;
  @Input() index: number;

  private values: any;

  public labels: any[] = [];

  // *** C
  constructor() {}


  // *** Lifecycle
  ngOnInit() {

    this.init();

  }


  // *** initialization
  private init() {

    this.initValues();
    this.initIcons();

  }


  // *** values
  private initValues() {

    switch( this.rctInfoField.type ) {

      case RCTInfoFieldType.SELECT_ONE:

        if( !this.values ) {

          this.values = [];
        }

        this.values.push( this.infoField.value); // viene un solo número y
                                                // hay que meterlo en un array
        break;
      case RCTInfoFieldType.SELECT_MULTI:
        this.values = this.infoField.value; // ya viene un array
    }

  }


  // icons
  private initIcons() {

    if( this.values && this.values !== null && Array.isArray(this.values) ) {

      this.values.forEach((value) => {


        this.labels.push( this.getOptionLabel(value));

      });
    }


  }

  private getOptionLabel( value ) {

    let label;

    this.rctInfoField.options.forEach((option) => {

      if( option.value === value && option.icon != null  ) {

        label = {

          icon: option.icon,
          txt: option.label
        }
      }
    });

    return label;
  }

}
