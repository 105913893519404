import { CESFPlace } from './cesf-place';
import { RecordValoration } from './record-valoration';

export class CESFRecordInfo {

  pis: boolean = false;
  caca: boolean = false;
  nada: boolean = false;

  place: number = CESFPlace.UNDEFINED;

  constructor(obj) {
    obj && Object.assign(this, obj);
  }


  public getValoration() {

    let valoration = RecordValoration.UNDEFINED;

    if( this.place === CESFPlace.SELF ) {

      valoration = RecordValoration.BAD;


    } else if( this.place === CESFPlace.DIAPER ) {

      if( this.pis && this.caca ) { // pis y caca

        valoration = RecordValoration.NOT_BAD;

      } else if( this.pis || this.caca ) { // pis o caca

        valoration = RecordValoration.NOT_BAD;

      } else {

        valoration = RecordValoration.UNDEFINED;
      }


    }  else if( this.place === CESFPlace.WC ) { // EN EL WC

      if( this.pis && this.caca ) { // pis y caca

        valoration = RecordValoration.EXCELENT;

      } else if( this.pis || this.caca ) { // pis o caca

        valoration = RecordValoration.GOOD;

      } else {

        valoration = RecordValoration.UNDEFINED;
      }

    }

    return valoration;
  }

}
