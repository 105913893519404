import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { ProfilesService } from './profiles.service';
import { DataService } from './data.service';

import { RC } from '../entities/rc'

import { environment } from '../../environments/environment';

import * as moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class RCsService {

  private currentDatetime: Date = new Date();
  private currentDatetime$: Subject<any> = new Subject();
  private updatedRCs$: Subject<any> = new Subject();

  // *** C
  constructor(
    private profilesS: ProfilesService,
    private dataS: DataService
  ) {}


  // *** RCs daily
  private getRCs$( date ) {

    return new Promise(( resolve, reject ) => {

      let profileID = this.profilesS.getCurrentProfileID();
      let dateISO = date.toISOString();

      let q = environment.API_RC + '/'
      + environment.ALL
      + '/' + profileID
      + '/' + dateISO;

      this.dataS.get$( q )
      .then(
        ( response: any ) => {

          if( response.status === 'success' ) {

            let rcs = response.data;

            this.onGetRCsResponse( rcs, resolve );

          } else {

            this.onGetRCsError( reject );
          }
        }
      )
    });
  }

  private onGetRCsResponse( rcs, resolve ) {

      resolve( rcs );
  }

  private onGetRCsError( reject ) {

    let err = new Error( 'Error al descargar registros');

    reject();
  }


  // *** RCs weekly
  private getWeeklyRCs$( date ) {

    return new Promise(( resolve, reject ) => {

      let profileID = this.profilesS.getCurrentProfileID();
      let dateISO = date.toISOString();

      let q = environment.API_RC + '/'
      + environment.ALL + '/'
      + environment.WEEKLY + '/'
      + profileID + '/'
      + dateISO;

      this.dataS.get$( q )
      .then(
        ( response: any ) => {

          if( response.status === 'success' ) {

            let rcs = response.data;

            this.onGetWeeklyRCsResponse( rcs, resolve );

          } else {

            this.onGetWeeklyRCsError( reject );
          }
        }
      )
    });
  }

  private onGetWeeklyRCsResponse( rcs, resolve ) {

      resolve( rcs );
  }

  private onGetWeeklyRCsError( reject ) {

    let err = new Error( 'Error al descargar registros');

    reject();
  }


  // *** current date time RCs
  public getCurrentDateTimeRCs$() {

    return this.getRCs$( this.currentDatetime );

  }

  // *** current week RCs
  public getCurrentWeekRCs$() {

    return this.getWeeklyRCs$( this.currentDatetime );

  }

  // *** last rcs ( by profile )
  public getLastRCs$( limit ) {

    return new Promise( ( resolve, reject ) => {

      let profileID = this.profilesS.getCurrentProfileID();

      let q = environment.API_RC + '/' + environment.LAST
      + '/' + profileID + '/' + limit;

      this.dataS.get$( q )
      .then(
        ( response: any ) => {

          if( response.status === 'success' ) {

            let rcs = response.data;

            this.onGetLastRCsResponse( rcs, resolve );

          } else {

            this.onGetLastRCsError( reject );
          }
        }
      )
      .catch( err => {

        reject( err );
      });
    });
  }

  private onGetLastRCsResponse( rcs, resolve ) {

      resolve( rcs );
  }

  private onGetLastRCsError( reject ) {

    let error = new Error();

    error.name = 'Error en get Last used RCs';
    error.message = 'Error indefinido';

    reject( error );
  }


  // *** most used rcs ( by profile )
  public getMostUsedRCs$( limit ) {

    return new Promise( ( resolve, reject ) => {

      let profileID = this.profilesS.getCurrentProfileID();

      let q = environment.API_RC + '/' + environment.MOST_USED
      + '/' + profileID + '/' + limit;

      this.dataS.get$( q )
      .then(
        ( response: any ) => {

          if( response.status === 'success' ) {

            let rcs = response.data;

            this.onGetMostUsedRCsResponse( rcs, resolve );

          } else {

            this.onGetMostUsedRCsError( reject );
          }
        }
      )
      .catch( err => {

        reject( err );
      });
    });
  }

  private onGetMostUsedRCsResponse( rcs, resolve ) {

      resolve( rcs );
  }

  private onGetMostUsedRCsError( reject ) {

    let error = new Error();

    error.name = 'Error en get mnost used RCs';
    error.message = 'Error indefinido';

    reject( error );
  }


  // *** count by template ( & profile )
  public getRCsByTemplateCount$( templateRef ) {

    return new Promise( ( resolve, reject ) => {

      // test
      resolve( 1 );

      /*
      let profileID = this.profilesS.getCurrentProfileID();

      let q = environment.API_RC + '/' + environment.TEMPLATE + '/'
      + environment.COUNT + '/' + profileID + '/' + templateRef;

      this.dataS.get$( q )
      .then(
        ( response: any ) => {

          if( response.status === 'success' ) {

            let count = response.data;

            this.onGetRCsByTemplateCountResponse( count, resolve );

          } else {

            this.onGetRCsByTemplateCountError( reject );
          }
        }
      )
      .catch( err => {

        reject( err );
      });*/
    });
  }

  private onGetRCsByTemplateCountResponse( count, resolve ) {

    resolve( count );
  }

  private onGetRCsByTemplateCountError( reject ) {

    let error = new Error();

    error.name = 'Error en get rcs by template count';
    error.message = 'Error indefinido';

    reject( error );
  }


  // *** count by rcType ( & profile )
  public getRCsByRCTypeCount$( rcType ) {

    return new Promise( ( resolve, reject ) => {

      let profileID = this.profilesS.getCurrentProfileID();

      let q = environment.API_RC + '/' + environment.RC_TYPE + '/'
      + environment.COUNT + '/' + profileID + '/' + rcType;

      this.dataS.get$( q )
      .then(
        ( response: any ) => {

          if( response.status === 'success' ) {

            let count = response.data;

            this.onGetRCsByRCTypeCountResponse( count, resolve );

          } else {

            this.onGetRCsByRCTypeCountError( reject );
          }
        }
      )
      .catch( err => {

        reject( err );
      });
    });
  }

  private onGetRCsByRCTypeCountResponse( count, resolve ) {

    resolve( count );
  }

  private onGetRCsByRCTypeCountError( reject ) {

    let error = new Error();

    error.name = 'Error en get rcs by rctype count';
    error.message = 'Error indefinido';

    reject( error );
  }


  // *** RCs save
  public saveRC$( rc ) {

    return new Promise( (resolve, reject) => {

      let q: string = environment.API_RC;

      this.dataS.post$( q, rc )
      .then(
        ( response: any ) => {

          if( response.status === 'success' ) {

            let savedRC: RC = response.data;

            this.onSaveRCResponse( resolve, savedRC );

          } else {

            this.onSaveRCError( reject );
          }
        }
      )
      .catch( err => {

        reject( err );
      });
    });
  }

  private onSaveRCResponse( resolve, savedRC ) {

    this.currentDatetime = new Date(savedRC.dateTime);

    this.currentDatetime$.next(savedRC);

    resolve( savedRC );
  }

  private onSaveRCError( reject ) {

    let error = new Error();

    error.name = 'Error on save RC';
    error.message = 'Error indefinido';

    reject( error );

  }


  // *** RC UPDATE
  public updateRC$( rc ) {

    return new Promise( (resolve, reject) => {

      let q: string = environment.API_RC;

      this.dataS.put$( q, rc )
      .then(
        ( response: any ) => {

          if( response.status === 'success' ) {

            this.onUpdateRCResponse( resolve, rc );

          } else {

            this.onUpdateRCError( reject );
          }
        }
      )
      .catch( err => {

        reject( err );
      });
    });
  }

  private onUpdateRCResponse( resolve, rc) {

    this.currentDatetime = new Date(rc.dateTime);

    this.currentDatetime$.next(rc);

    resolve( rc );
  }

  private onUpdateRCError( reject ) {

    let error = new Error();

    error.name = 'Error on update RC';
    error.message = 'Error indefinido';

    reject( error );

  }


  // *** RC RM
  public removeRCs$( rcsID ) {

    return new Promise( ( resolve, reject ) => {


      let q: string =
        environment.API_RC + '/' + environment.DELETE_OP;

        let body = {
          rcs: JSON.stringify(rcsID),
        }

      this.dataS.post$( q, body )
      .then(
        ( response: any ) => {

          if( response.status === 'success' ) {

            this.onRemoveRCsResponse( resolve );



          } else {

            this.onRemoveRCsError( reject );

          }

        }
      )
      .catch( err => { reject( err )});

    });
  }

  private onRemoveRCsResponse( resolve ) {

    resolve();
  }


  private onRemoveRCsError( reject ) {

    let error = new Error();

    error.name = 'Error en la eliminación de RCs';
    error.message = 'Error indefinido';


    reject( error );
  }

  // *** current datetime
  public getCurrentDatetime() {

    return this.currentDatetime;
  }

  public setCurrentDatetime( date ) {

    this.currentDatetime = date;

    this.currentDatetime$.next( this.currentDatetime );
  }

  public setCurrentDatetimeGhost( date ) {

    this.currentDatetime = date;
  }

  public resetCurrentDatetime() {

    this.setCurrentDatetime( new Date());
  }

  public increaseDay() {

    let currentDatetimeM = moment( this.currentDatetime );

    currentDatetimeM.add( 1, 'days' );

    this.setCurrentDatetime( currentDatetimeM.toDate());
  }

  public decreaseDay() {

    let currentDatetimeM = moment( this.currentDatetime );

    currentDatetimeM.subtract( 1, 'days' );

    this.setCurrentDatetime( currentDatetimeM.toDate());
  }


  // *** current week
  public initWeek() {

    this.currentDatetime$.next( this.currentDatetime );
  }

  public increaseWeek() {

    let currentDatetimeM = moment( this.currentDatetime );

    currentDatetimeM.add( 7, 'days' );

    this.setCurrentDatetime( currentDatetimeM.toDate());
  }

  public decreaseWeek() {

    let currentDatetimeM = moment( this.currentDatetime );

    currentDatetimeM.subtract( 7, 'days' );

    this.setCurrentDatetime( currentDatetimeM.toDate());
  }


  // *** observables
  public getCurrentDatetime$() {

    return this.currentDatetime$.asObservable();
  }

  public getUpdatedRCs$() {

    return this.updatedRCs$.asObservable();
  }
}
