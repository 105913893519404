import { Injectable } from '@angular/core';

import {Observable, Subject} from 'rxjs';

import { ProfilesService } from './profiles.service';
import { DataService } from './data.service';

import{ Interest } from '../entities/interest';
import{ InterestValue } from '../entities/interest-value';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InterestsService {

  private updatedInterests$ = new Subject<any>();

  private interests: Interest[] = [];
  private likes: Interest[] = [];
  private dislikes: Interest[] = [];

  private loading;


  // *** C
  constructor(
    private profilesS: ProfilesService,
    private dataS: DataService
  ) {}


  // *** Interests ops
  public loadInterests$() {

    return new Promise((resolve, reject) => {

      let q: string =
        environment.API_INTEREST
        + '/' + this.profilesS.getCurrentProfileID();

      this.dataS.get$( q )
      .then(
        ( response: any ) => {

          if( response.status === 'success' ) {

            this.onLoadInterestResponse( response.data, resolve );

          } else {

            this.onLoadInterestError( reject );

          }

        }
      )
      .catch( err => {

        reject( err );

      });

    });
  }

  public createInterest$( interest ) {

    return new Promise(( resolve, reject ) => {



      this.storeInterest$( interest ).then(
        ( newInterest ) => {

          this.updateInterestsLists( newInterest );

          this.updatedInterests$.next(interest);

          resolve( interest );

        });

    });


  }

  public removeInterest$( interest ) {

    return new Promise(( resolve, reject ) => {

      let ls;

      switch( interest.value ) {

        case InterestValue.LIKES:
          ls = this.likes;
          break;
        case InterestValue.DISLIKES:
          ls = this.dislikes;
          break;
        case InterestValue.INTERESTED:
          ls = this.interests;
          break;
      }

      let index = ls.indexOf( interest );

      if ( index  > -1) {

        ls.splice(index, 1);
      }

      this.updatedInterests$.next(interest);

      this.deleteInterest$( interest ).then(
        () => {

          resolve();
        }
      );
    });


  }


  // *** interest storage ops
  private storeInterest$( interest ) {

    return new Promise((resolve, reject) => {

      let q: string = environment.API_INTEREST;

      this.dataS.post$( q, interest )
      .then(
        ( response: any ) => {

          if( response.status === 'success' ) {

            this.onStoreInterestResponse( response.data, resolve );

          } else {

            this.onStoreInterestError( reject );

          }

        }
      )
      .catch( err => {

        reject( err );

      });

    });
  }

  private deleteInterest$( interest ) {

    return new Promise( ( resolve, reject ) => {

      let q: string =
      environment.API_INTEREST + '/' + interest._id;

      this.dataS.delete$( q )
      .then(
        ( response: any ) => {

          if( response.status === 'success' ) {

            this.onDeleteInterestResponse( resolve );

          } else {

            this.onDeleteInterestError( reject );

          }

        }
      )
      .catch( err => {

        reject( err );

      });
    })
  }


  // *** load interest
  private onLoadInterestResponse( interests, resolve ) {

    this.interests = [];
    this.likes = [];
    this.dislikes = [];

    interests.forEach(( interest ) => {

      this.updateInterestsLists( interest );
    });

    this.updatedInterests$.next();

    resolve();
  }


  private onLoadInterestError( reject ) {

    let error = new Error();

    error.name = 'Error en la carga de intereses';
    error.message = 'Error indefinido';

    reject( error );

  }

  // *** store interest
  private onStoreInterestResponse( interest, resolve ) {

    resolve( interest );
  }

  private onStoreInterestError( reject ) {

    let error = new Error();

    error.name = 'Error en el almacenamiento de interes';
    error.message = 'Error indefinido';


    reject( error );
  }


  // *** delete interest
  private onDeleteInterestResponse( resolve ) {

    resolve();
  }

  private onDeleteInterestError( reject ) {

    let error = new Error();

    error.name = 'Error en el borrado de interes';
    error.message = 'Error indefinido';


    reject( error );
  }



  // *** interests lists
  private updateInterestsLists( interest ) {

    switch( interest.value ) {

      case InterestValue.LIKES:
        this.likes.push( interest );
        break;
      case InterestValue.DISLIKES:
        this.dislikes.push( interest );
        break;
      case InterestValue.INTERESTED:
        this.interests.push( interest );
        break;
    }

  }


  // *** interest public
  public getInterests() {

    return this.interests;
  }

  public getLikes() {

    return this.likes;
  }

  public getDislikes() {

    return this.dislikes;
  }


  // *** observable
  public getUpdatedInterests$() {

    return this.updatedInterests$.asObservable();
  }

}
