import { Component, OnInit, Input } from '@angular/core';
import { ModalController }  from '@ionic/angular';


import { ProfileSensoryProfile } from '../../../entities/profile-sensory-profile';
import { SensoryProfilesService } from '../../../services/sensory-profiles.service';

import { ProfileSensoryProfileSpec } from '../../../entities/profile-sensory-profile-spec';
import { ProfileSensoryProfileType } from '../../../entities/profile-sensory-profile-type';
import { ProfileSensoryProfileState } from '../../../entities/profile-sensory-profile-state';

import { SensoryProfileModalComponent } from '../sensory-profile-modal/sensory-profile-modal.component';

import{ SensoryProfileUI } from '../sensory-profile-ui';


@Component({
  selector: 'app-sensory-profile-info-card',
  templateUrl: './sensory-profile-info-card.component.html',
  styleUrls: ['./sensory-profile-info-card.component.scss'],
})
export class SensoryProfileInfoCardComponent implements OnInit {

  @Input() sensoryProfileType: number;
  @Input() sensoryProfileSpec: number;

  private sensoryProfileModal: any;


  public UPDATED = ProfileSensoryProfileState.UPDATED;
  public UPDATE_NEEDED = ProfileSensoryProfileState.UPDATE_NEEDED;
  public REVIEW_NEEDED = ProfileSensoryProfileState.REVIEW_NEEDED;

  public pSensoryProfile: ProfileSensoryProfile;

  public icon: string = 'cubes';

  public reviewState: number = ProfileSensoryProfileState.UPDATE_NEEDED;
  public days: number = 999;
  public stateMsg: string = 'No se ha actualizado nunca';
  public score: number = 0;


  // *** C
  constructor(
    private sensoryProfilesS: SensoryProfilesService,
    private modalCtrl: ModalController
  ) { }


  // *** Lifecycle
  ngOnInit() {

    this.setPSensoryProfile();

  }


  // *** actions
  public onClickCard() {

    this.openProgressModal();


  }

  private async openProgressModal() {

    // src: https://forum.ionicframework.com/t/ionic-v4-modal-parameters/137326/2
    this.sensoryProfileModal= await this.modalCtrl.create({
      component: SensoryProfileModalComponent,
      componentProps: {
         pSensoryProfile: this.pSensoryProfile
      }
    })

    await this.sensoryProfileModal.present();
  }


  // ** Profile sensory profile
  private setPSensoryProfile() {

    switch( this.sensoryProfileType ) {

    case ProfileSensoryProfileType.RANGE:

        this.setRangePSensoryProfile();
        this.setIcon();

        break;
    }
  }


  private setRangePSensoryProfile() {

    this.pSensoryProfile = this.sensoryProfilesS.getPSensoryProfiles( this.sensoryProfileSpec );

    /*
    switch( this.sensoryProfileSpec ) {

      case ProfileSensoryProfileSpec.AUDITORY_PROCESS:

        this.pSensoryProfile = this.sensoryProfilesS.getAuditoryPSensoryP();

        break;

      case ProfileSensoryProfileSpec.VISUAL_PROCESS:

        this.pSensoryProfile = this.sensoryProfilesS.getVisualPSensoryP();

        break;

      case ProfileSensoryProfileSpec.VESTIBULAR_PROCESS:

        this.pSensoryProfile = this.sensoryProfilesS.getVestibularPSensoryP();

        break;
    }*/
}


  // *** Icon
  private setIcon() {

    this.icon = SensoryProfileUI.SENSORY_PROFILES[this.sensoryProfileType][this.sensoryProfileSpec].icon;
  }


}
