import { Component, OnInit } from '@angular/core';
import { ModalController }  from '@ionic/angular';

import { RecordsOpsService } from '../../../services/records-ops.service';

@Component({
  selector: 'app-records-ops-modal',
  templateUrl: './records-ops-modal.component.html',
  styleUrls: ['./records-ops-modal.component.scss'],
})
export class RecordsOpsModalComponent implements OnInit {

  // *** C *** //
  constructor(
    private modalCtrl: ModalController,
    private recordsOpsS: RecordsOpsService
  ) { }


  // *** Lifecycle Events *** //
  ngOnInit() {}

  // *** PRIVATE *** //
  public onClickCloseModal() {

    this.modalCtrl.dismiss();

  }

  public onlickRemoveRecords() {

    this.modalCtrl.dismiss();

    this.recordsOpsS.toogleRemoveRecordsActive();

  }



}
