import { Component, OnInit } from '@angular/core';
import { ModalController }  from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { RecordCreationCenterModalComponent } from '../record-creation-center-modal/record-creation-center-modal.component';
import { CalendarOpsModalComponent } from '../../calendar/calendar-ops-modal/calendar-ops-modal.component';
import { RecordsOpsModalComponent } from '../../records/records-ops-modal/records-ops-modal.component';
import { RecordsService } from '../../../services/records.service';
import { EfaStatesService } from '../../../services/efa-states.service';
import { ProfilesService } from '../../../services/profiles.service';
import { RecordsOpsService } from '../../../services/records-ops.service';
import { NavigationService } from '../../../services/navigation.service';

import { RecordType } from '../../../entities/record-type';

import { Paths } from '../../../navigation/paths';


@Component({
  selector: 'app-records-actions',
  templateUrl: './records-actions.component.html',
  styleUrls: ['./records-actions.component.scss'],
})
export class RecordsActionsComponent implements OnInit {

  private modal: any;
  private removeRecordsActive$: Subscription;
  public removeRecordsActive: boolean = false;

  private recordsSelected$: Subscription;
  public recordsSelected: boolean = false;

  private currentURL$: Subscription;

  public recordsOpActive: boolean = false;

  private removeFlag: boolean = false;
  private removeSelectedRecordsConfirm;

  public recordEditionDisabled: boolean = false;

  public calendarBtnVisible: boolean = false;
  public todayBtnVisible: boolean = false;
  public recordsBtnVisible: boolean = false;
  public addRecordBtnVisible: boolean = false;


  constructor(

    private modalCtrl: ModalController,
    public alertController: AlertController,
    private recordsS: RecordsService,
    private efaStatesS: EfaStatesService,
    private profilesS: ProfilesService,
    private navS: NavigationService,
    private recordsOpsS: RecordsOpsService,
    private router: Router

  ) {

    this.setRecordsOpsEventsHandlers();

    this.setEditionPermissions();

    this.setButtonsVisibility();

    this.setNavigationEventsHandlers();

  }

  ngOnInit() {

  }

  ngOnDestroy() {
      // unsubscribe to ensure no memory leaks¡
      this.removeRecordsActive$.unsubscribe();

      this.recordsSelected$.unsubscribe();

      this.currentURL$.unsubscribe();
  }


  // *** PRIVATE *** //

  // *** modals
  private onClickAddRecord() {

    this.openAddRecordCenterModal();

    // src: https://medium.com/@david.dalbusco/how-to-declare-and-use-modals-in-ionic-v4-4d3f42ac30a3
  }

  private onClickToday() {

    this.recordsS.setToday();

  }

  private onClickCalendar() {

    this.openCalendarOpsModal();
  }

  private onClickRecordsOps() {

    this.openRecordOpsModal();
  }

  private async openAddRecordCenterModal() {

    this.modal = await this.modalCtrl.create({
      component: RecordCreationCenterModalComponent
    });

    await this.modal.present();
  }

  private async openCalendarOpsModal() {

    this.modal = await this.modalCtrl.create({
      component: CalendarOpsModalComponent
    });

    await this.modal.present();



  }

  private async openRecordOpsModal() {

    this.modal = await this.modalCtrl.create({
      component: RecordsOpsModalComponent
    });

    await this.modal.present();



  }

  // *** record ops
  private setRecordsOpsEventsHandlers() {

    this.removeRecordsActive$ = this.recordsOpsS
    .getRemoveRecordsActiveObservable()
    .subscribe( active => {
      this.removeRecordsActive = active;
      this.recordsOpActive = active;
      this.recordsSelected = this.recordsOpsS.isAnyRecordSelected();


    });

    this.recordsSelected$ = this.recordsOpsS
    .getRecordsSelected$Observable()
    .subscribe( selected => {
      this.recordsSelected = selected;
    });



  }

  private onCLickRemoveRecordsActive() {

    this.recordsOpsS.toogleRemoveRecordsActive();

  }

  private onCLickRemoveSelectedRecords() {


    this.presentRemoveSelectedRecordsConfirm();
  }

  private async presentRemoveSelectedRecordsConfirm() {

    let selectedRecords = this.recordsOpsS.getSelectedRecords();

    this.removeSelectedRecordsConfirm = await this.alertController.create({
      header: 'Se borrarán ' + selectedRecords.length + ' registros',
      message: 'Esta operación no se puede deshacer.',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            this.onRemoveRecordsCancel();
            return;

          }
        }, {
          text: 'Borrar',
          role: 'accept',
          cssClass: 'secondary',
          handler: () => {
            this.removeFlag = true;
            this.onRemoveRecordsConfirm();
            return;
          }
        }
      ]
    });

    this.removeSelectedRecordsConfirm.onDidDismiss( ( data ) => {

      if( this.removeFlag === true ) {

        this.onRemoveRecordsConfirm();
      }
    });

    await this.removeSelectedRecordsConfirm.present();
  }

  private async onRemoveRecordsConfirm() {

    let selectedRecords = this.recordsOpsS.getSelectedRecords();
    let isEfaRecordInclude = this.recordsOpsS.isRecordTypeSelected( RecordType.EFA );

    this.recordsS.removeRecords$( selectedRecords )
    .then(
      () => {

        if( isEfaRecordInclude ) {

          this.efaStatesS.loadEFAStates$().then();
        }

        this.recordsOpsS.toogleRemoveRecordsActive();
      })
      .catch( err => {

        let errJson = JSON.stringify(err);

        this.router.navigate(['/error', errJson]);
      });
  }

  private async onRemoveRecordsCancel() {

    this.recordsOpsS.toogleRemoveRecordsActive();
  }


  // *** calendar view ops
  private setNavigationEventsHandlers() {

    // on Current URL changes
    this.currentURL$ = this.navS
    .getCurrentURLObservable()
    .subscribe( url => {
      this.onCurrentURLChange();
    });
  }


  private onCurrentURLChange() {

    this.setButtonsVisibility();
  }

  // edition permissions
  private setEditionPermissions() {

    this.recordEditionDisabled = !this.profilesS.isCurrentUserEditor();
  }


  // buttons visibility
  private setButtonsVisibility() {

    // get current view :
    // src: https://stackoverflow.com/a/37909349
    const currentURL= this.router.url

    switch( currentURL ) {

      case '/' + Paths.MEMBERS + '/' + Paths.RECORDS + '/' + Paths.RECORDS_DAILY:
      case '/' + Paths.MEMBERS + '/' + Paths.RECORDS + '/'
          + Paths.RECORDS_DAILY + '/' + Paths.NOT_TODAY:

        this.calendarBtnVisible = true;
        this.todayBtnVisible = true;
        this.recordsBtnVisible = true;
        this.addRecordBtnVisible = true;

        break;

      case '/' + Paths.MEMBERS + '/' + Paths.RECORDS + '/' + Paths.RECORDS_WEEKLY:

        this.calendarBtnVisible = true;
        this.todayBtnVisible = false;
        this.recordsBtnVisible = false;
        this.addRecordBtnVisible = false;

        break;
    }
  }
}
