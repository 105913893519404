// ** User ** //
import { RecordValoration } from './record-valoration';
import { RecordType } from './record-type';
import { CESFRecordInfo } from './cesf-record-info';
import { BehaviorRecordInfo } from './behavior-record-info';
import { ActionRecordInfo } from './action-record-info';
import { EfaRecordInfo } from './efa-record-info';
import { EventRecordInfo } from './event-record-info';
import { CustomRecordInfo } from './custom-record-info';

export class Record {

  _id?: string;
  type: number;
  date: Date;
  time: Date;
  durationTime?: number;
  durationUnit?: number;
  author: string;
  profile: string;
  observations?: string;
  valoration: number = RecordValoration.UNDEFINED;
  recordInfo?: any;
  authorDisplayName?: string;
  bgColor?: string;

  constructor(obj) {

    //obj && Object.assign(this, obj);

    if( obj ) {

      Object.assign(this, obj);

      this.updateValoration();
    }

  }

  // TODO: Ajustar estos valores a la edad del profile
  private updateValoration() {

    if( !this.valoration || this.valoration === RecordValoration.UNDEFINED ) {

      if( this.recordInfo ) {

        let recordInfo;

        switch( this.type ) {

          case RecordType.CONTROL_ESFINTER:
            recordInfo = new CESFRecordInfo( this.recordInfo );
            break;

          case RecordType.BEHAVIOR:
            recordInfo = new BehaviorRecordInfo( this.recordInfo );
            break;

          case RecordType.ACTION:
            recordInfo = new ActionRecordInfo( this.recordInfo );
            break;

          case RecordType.EFA:
            recordInfo = new EfaRecordInfo( this.recordInfo );
            break;

          case RecordType.EVENT:
            recordInfo = new EventRecordInfo( this.recordInfo );
            break;

          case RecordType.CUSTOM:
            recordInfo = new CustomRecordInfo( this.recordInfo );
            break;
      }

        this.valoration = recordInfo.getValoration();

      }
    }
  }
}
