import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { NavigationService } from '../../../services/navigation.service';
import { ProfilesService } from '../../../services/profiles.service';
import { Paths } from '../../../navigation/paths';

@Component({
  selector: 'app-nav-tools',
  templateUrl: './nav-tools.component.html',
  styleUrls: ['./nav-tools.component.scss'],
})
export class NavToolsComponent implements OnInit {

  private previousURL;
  public icon = 'app/broken-robot';
  private $previousURL: Subscription;

  constructor(
    private navS: NavigationService,
    private profilesS: ProfilesService
  ) {

    this.setNavEventHandlers();
  }

  ngOnInit() {}


  ngOnDestroy() {
      // unsubscribe to ensure no memory leaks¡
      this.$previousURL.unsubscribe();
  }


  // *** PRIVATE *** //
  public onClickBack() {

    this.navS.pop();

  }

  // *** PRIVATE *** //
  private setNavEventHandlers() {

    // src: https://jasonwatmore.com/post/2019/02/07/angular-7-communicating-between-components-with-observable-subject
    // on Current URL changes
    this.$previousURL = this.navS
    .getPreviousURLObservable()
    .subscribe( url => {
      this.onPreviousURLChange( url );
    });

  }

  private onPreviousURLChange( url ) {


    if ( url ) {

      this.previousURL = url;

      this.setPreviousURLIcon();

    }
  }


  private setPreviousURLIcon() {

    switch( this.previousURL ) {

      case '/' + Paths.MEMBERS + '/' + Paths.PROFILES:

        this.icon = 'app/agenda-min';

        break;

      case '/' + Paths.MEMBERS + '/' + Paths.RECORDS + '/' + Paths.RECORDS_DAILY:
      case '/' + Paths.MEMBERS + '/' + Paths.RECORDS
            + '/' + Paths.RECORDS_DAILY + '/' + Paths.NOT_TODAY:
      case '/' + Paths.MEMBERS + '/' + Paths.RECORDS + '/' + Paths.RECORDS_WEEKLY:

        this.icon = 'app/agenda-min';

        break;

      default:
        this.icon = 'persons/' + this.profilesS.getCurrentProfile().image;

        break;
    }

  }

}
