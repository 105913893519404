import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';

import { NavigationService } from './navigation.service'

import { Paths } from '../navigation/paths';


@Injectable({
  providedIn: 'root'
})
export class ScreenService {

  private currentURL$: Subscription;

  // *** C
  constructor(
    private screenOrientation: ScreenOrientation,
    private navS: NavigationService
  ) {


  }


  public init() {

    this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);

    this.initSubscriptions();
  }


  // *** subscriptions
  private initSubscriptions() {



    this.currentURL$ = this.navS.getCurrentURL$()
    .subscribe(
      ( url ) => {

        if( url.indexOf(Paths.RC_DAILY) > -1 || url.indexOf(Paths.RC_WEEKLY ) > -1 ) {



          this.screenOrientation.unlock();

        } else {



          this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);

        }



      }
    );

  }
}
