export class SensoryProfileConf {

  static QUESTION_MAXIMUN_SCORE = 4;

  static NEVER = 0;
  static RARELY = 1;
  static SOMETIMES = 2;
  static FRECUENTLY = 3;
  static ALWAYS = 4;

  static LOW_STATE_MAX = 3;
  static MEDIUM_STATE_MAX = 8;
  static HIGH_STATE_MAX = 10;

  static UPDATE_NEEDED_DAYS = 30;
  static REVIEW_NEEDED_DAYS = 15;
  static UPDATED_DAYS = 0;

}
