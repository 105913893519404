export class ProfileProgressSpec {
  static UNDEFINED = -1;
  static RECEPTIVE_COMM = 1;
  static EXPRESIVE_COMM = 2;
  static SOCIAL_SKILLS = 3;
  static IMITATION = 4;
  static COGNITIVE_SKILLS = 5;
  static GAME_SKILLS = 6;
  static EAT_AUTONOMY = 7;
  static DRESS_AUTONOMY = 8;
  static HYGIENE_AUTONOMY = 9;
  static HOUSEWORK_AUTONOMY = 10;
}
