import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, PickerController }  from '@ionic/angular';
import { PickerOptions, PickerButton } from '@ionic/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { RecordsService } from '../../../services/records.service';
import { UsersService } from '../../../services/users.service';
import { ProfilesService } from '../../../services/profiles.service';

import { Record } from '../../../entities/record';
import { RecordType } from '../../../entities/record-type';
import { ActionRecordInfo } from '../../../entities/action-record-info';
import { RecordTimeUnit } from '../../../entities/record-time-unit';

import { Paths } from '../../../navigation/paths';

@Component({
  selector: 'app-action-record-modal',
  templateUrl: './action-record-modal.component.html',
  styleUrls: ['./action-record-modal.component.scss'],
})
export class ActionRecordModal implements OnInit {

  // *** form
  public recordForm: FormGroup;
  private submitted: boolean = false;
  public disabledSubmit: boolean = true;
  private clone: boolean = false;
  public submitBtnTxt = 'CREAR';
  public enabledDateField: boolean = false;
  public hiddenCloneButton: boolean = true;


  // *** Record
  private record: Record;

  // *** color
  public bgColors: number[] = [];
  public selectedBGColor;


  // *** C
  constructor(
    public modalCtrl: ModalController,
    public navParams: NavParams,
    private pickerCtrl: PickerController,
    private router: Router,
    private formBuilder: FormBuilder,
    private recordsS: RecordsService,
    private profilesS: ProfilesService,
    private usersS: UsersService,
  ) { }

  // *** LIFECYCLE EVENTS
  ngOnInit() {
    this.readModalParams();
    this.createForm();
    this.initBGColors();
  }


  // ** convenience getter for easy access to form fields ** //
  private get f() {
    return this.recordForm.controls;
  }


  // *** modal params
  public readModalParams() {

    this.record = this.navParams.get('record');

    if( this.record ) {

      this.submitBtnTxt = 'MODIFICAR';
      this.enabledDateField = true;
      this.hiddenCloneButton = false;

    }

  }

  // *** form ops *** //
  private createForm() {

    let recordDate;
    let recordTime;
    let durationTXT;
    let durationTime = -1;
    let durationUnit = RecordTimeUnit.UNDEFINED;

    let valoracion = '';
    let valoration = -1;

    let actionName = null;
    let actionDescription = null;
    let actionReaction = null;
    let observations = null;
    let bgColor;

    if( !this.record ) {

      let now = new Date();

      recordDate = this.recordsS.getCurrentDate();
      recordTime = recordDate;

    } else {

      recordDate = this.record.date;
      recordTime = this.record.time;

      durationTime = this.record.durationTime;
      durationUnit = this.record.durationUnit;
      durationTXT = this.getDurationTXT( durationTime, durationUnit );

      actionName = this.record.recordInfo.actionName;
      actionDescription = this.record.recordInfo.actionDescription;
      actionReaction = this.record.recordInfo.actionReaction;
      observations = this.record.observations;

      bgColor = this.record.bgColor;

    }


    this.recordForm = this.formBuilder.group({

      date: [recordDate.toString(), Validators.required],
      time: [recordTime.toString(), Validators.required],
      durationTXT: [durationTXT],
      durationTime: durationTime,
      durationUnit: durationUnit,
      actionName: [actionName, Validators.required],
      actionDescription: [actionDescription],
      actionReaction: [actionReaction],
      observations: [observations],
      bgColor: [bgColor]
    });

    this.setFormEventsHandlers();
  }

  private setFormEventsHandlers() {

    this.recordForm.valueChanges.subscribe(
      ( value ) => {

        this.disabledSubmit = !this.validFormValues();
      }
    );
  }

  private getRecordValues() {

    let recordInfo: ActionRecordInfo = new ActionRecordInfo(
      {
        actionName: this.recordForm.value.actionName,
        actionDescription: this.recordForm.value.actionDescription,
        actionReaction: this.recordForm.value.actionReaction
      }
    )

    let isoDate = new Date( this.recordForm.value.date ).toISOString();
    let isoTime = new Date( this.recordForm.value.time ).toISOString();

    let values = {
      type: RecordType.ACTION,
      date: isoDate,
      time: isoTime,
      durationTime: this.recordForm.value.durationTime,
      durationUnit: this.recordForm.value.durationUnit,
      observations: this.recordForm.value.observations,
      author: this.usersS.getCurrentUserID(),
      profile: this.profilesS.getCurrentProfileID(),
      recordInfo: recordInfo,
      bgColor: this.recordForm.value.bgColor
    }

    return values;
  }

  private validFormValues() {

    let valid: boolean = false;

    if( !this.recordForm.invalid ) {

      valid = true;

    }

    return valid;
  }

  public async onSubmit() {

    if( this.record ) {

      this.onSubmitModifyRecord();

    } else {

      this.onSubmitNewRecord();
    }
  }

  public onClone() {
    this.onSubmitNewRecord();
  }

  private onSubmitNewRecord() {

    this.submitted = true;

    if( !this.validFormValues() ) {

      this.submitted = false;

      return;

    } else {

      let values = this.getRecordValues();

      let newRecord = new Record( values );


      this.recordsS.storeNewRecord$( newRecord )
      .then(
        ( data ) => {

          this.modalCtrl.dismiss();
        }
      )
      .catch(
        err => {

          let errJson = JSON.stringify(err);

          this.router.navigate(['/' + Paths.ERROR, errJson]);

          let data = {
            error: 'error'
          }

          this.submitted = false;

          this.modalCtrl.dismiss( data );
        }
      );
    }
  }

  private onSubmitModifyRecord() {

    this.submitted = true;

    if( !this.validFormValues()) {

      this.submitted = false;

      return;

    } else {

      this.updateRecord();


      this.recordsS.updateRecord$( this.record )
      .then(
        ( data ) => {

          this.modalCtrl.dismiss();
        }
      )
      .catch(
        err => {

          let errJson = JSON.stringify(err);

          this.router.navigate(['/' + Paths.ERROR, errJson]);

          let data = {
            error: 'error'
          }

          this.submitted = false;

          this.modalCtrl.dismiss( data );
        }
      );
    }

  }


  // record ops
  public updateRecord() {

    let values = this.getRecordValues();

    let recordID = this.record._id;

    this.record = new Record( values );

    this.record._id = recordID;

}


  // *** time
  public onClickNow() {

    let now = new Date();

    this.recordForm.controls['date'].setValue(now.toString());
    this.recordForm.controls['time'].setValue(now.toString());

  }


  // *** duration
  public onClickDuration() {

    this.showDurationPicker();

  }

  private async showDurationPicker() {

    let timeValues = [];

    for( let timeValue = 1; timeValue <= 60; timeValue++ ) {

      let time = {
        text: timeValue + "",
        value: timeValue
      }

      timeValues.push( time );
    }

    let opts: PickerOptions = {
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel'
        },
        {
          text: 'Sin duración',
          handler: () => {
            this.onDurationReset();
          }
        },
        {
          text: 'Hecho',
          role: 'done',
          handler: (data) => {
            this.onDurationSelected( data );
          }
        }
      ],
      columns: [
        {
          name: 'time',
          options: timeValues
        },
        {
          name: 'units',
          options: [
            {
              value: RecordTimeUnit.MINUTE,
              text: 'minutos'
            },
            {
              value: RecordTimeUnit.HOUR,
              text: 'horas'
            },
            {
              value: RecordTimeUnit.DAY,
              text: 'dias'
            },
          ]
        }
      ]
    };



    let picker = await this.pickerCtrl.create(opts);

    picker.present();

    //picker.onDidDismiss().then(async data => {});

  }

  private onDurationSelected( durationData ) {

    let time = durationData.time.value;
    let unit = durationData.units.value;

    this.recordForm.controls['durationTime'].setValue(time);
    this.recordForm.controls['durationUnit'].setValue(unit);

    this.setDurationTXT( time, unit );

  }

  private onDurationReset() {

    this.recordForm.controls['durationTime'].setValue(-1);
    this.recordForm.controls['durationUnit'].setValue(RecordTimeUnit.UNDEFINED);

    this.recordForm.controls['durationTXT'].setValue(null);

  }

  private setDurationTXT( time, unit ) {

    let txt;

    if( time !== -1 && unit !== RecordTimeUnit.UNDEFINED ) {

      txt = time + ' ' + this.getDurationUnitTXT( time, unit );

      this.recordForm.controls['durationTXT'].setValue(txt);
    }

  }

  private getDurationTXT( time, unit ) {

    let txt;

    if(
      typeof time !== 'undefined' &&
      typeof unit !== 'undefined' &&
      time !== -1 &&
      unit !== RecordTimeUnit.UNDEFINED ) {

      txt = time + ' ' + this.getDurationUnitTXT( time, unit );

    }

    return txt;
  }

  private getDurationUnitTXT( time, unit ) {

    let txt;

    switch( unit ) {

      case RecordTimeUnit.MINUTE:
        txt = 'minuto';
        break;
      case RecordTimeUnit.HOUR:
        txt = 'hora';
        break;
      case RecordTimeUnit.DAY:
        txt = 'dia';
        break;
    }

    if( time > 1 ) {

      txt += 's';
    }

    return txt;
  }

  // *** color
  private initBGColors() {

    this.bgColors = Array(15).fill(1).map((x,i)=>i); // [0,1,2,3,4]

  }

  private setSelectedBGColor() {

    this.selectedBGColor = this.recordForm.value.bgColor;

  }

  private onSelectBGColor( color ) {

      this.recordForm.controls['bgColor'].setValue( color );

      this.setSelectedBGColor();
  }


  // *** modal OPS *** //
  public onClickCloseModal() {

    this.modalCtrl.dismiss();

  }
}
