

export class Interest {

  txt: string;
  value: number;
  profile: string;


  constructor(obj) {
    obj && Object.assign(this, obj);
  }

}
