import { BehaviorType } from './behavior-type';
import { Behavior } from './behavior';

import { RecordValoration } from './record-valoration';

export class BehaviorRecordInfo {

  behavior: number = Behavior.UNDEFINED;
  behaviorType: number = BehaviorType.UNDEFINED;
  otherBehavior?: string;
  detonating?: string;
  description?: string;


  constructor(obj) {
    obj && Object.assign(this, obj);
  }


  public getValoration() {

    return RecordValoration.UNDEFINED;

  }
}
