
import { RCTTag } from './rct-tag';
import {  RCTIcon } from './rct-icon';
import {  RCTTime } from './rct-time';
import {  RCTInfo } from './rct-info';
import {  RCTColor } from './rct-color';
import { RCTInfoField } from './rct-info-field';

import { User } from './user';


export class RCTemplate {

  // *** Attrs
  name: string;
  title: boolean = false;
  defaultTemplate: boolean = true;
  tags: string[] = [];
  ref: string;
  label: string;
  icon: RCTIcon;
  time: RCTTime;
  info?: RCTInfo;
  color?: RCTColor;
  reaction: boolean = false;

  owner?: User;
  profileID?: string


  // *** C
  constructor( obj ) {

    if( obj ) {

      Object.assign(this, obj);
    }
  }

  public haveBGColor() {

    let have = false;

    if( this.color !== null
        && this.color.bgColor !== null
        && this.color.bgColor.length > 0 ) {

      have = true;
    }

    return have;
  }

  // *** get info field
  public getInfoField( name ): RCTInfoField {

    let infoField = null;

    this.info.fields.forEach(( field ) => {

      if( typeof field !== 'undefined'
        && field != null
        && field.name === name ) {

        infoField = new RCTInfoField( field );


      }
    });


    return infoField;
  }
}
