import { Injectable } from '@angular/core';

import { UsersService } from './users.service';
import { ProfilesService } from './profiles.service';
import { RCTemplatesCacheService } from '../cache/rc-templates-cache.service';
import { DataService } from './data.service';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RCTemplatesService {


  private TAG_MAX_AGE = 30; // days

  // *** C
  constructor(
    private usersS: UsersService,
    private profilesS: ProfilesService,
    private dataS: DataService,
    private rcTemplatesCache: RCTemplatesCacheService
  ) {}


  // *** get visible tags ( by user ID & profile ID )
  public getRCVisibleTags$() {

    return new Promise( (resolve, reject ) => {

      let userID = this.usersS.getCurrentUserID();
      let profileID = this.profilesS.getCurrentProfileID();

      let q = environment.API_RC_TEMPLATE_TAG
      + '/' + userID + '/' + profileID;

      this.dataS.get$( q )
      .then(
        ( response: any ) => {

          if( response.status === 'success' ) {

            let tags = response.data;

            this.onGetRCVisibleTagsResponse( tags, resolve );

          } else {

            this.onGetRCVisibleTagsError( reject );
          }
        }
      )
      .catch( err => {

        reject( err );
      });
    });
  }

  private onGetRCVisibleTagsResponse( tags, resolve ) {

    resolve( tags );
  }

  private onGetRCVisibleTagsError( reject ) {

    let error = new Error();

    error.name = 'Error en la carga de tags visibles';
    error.message = 'Error indefinido';

    reject( error );

  }


  // *** tenplates by tag ( & user ID & profile ID)
  public getRCTemplatesByTag$( tag ) {

    return new Promise((resolve, reject) => {


      if( this.rcTemplatesCache.containsTag( tag )) {

        let templates = this.rcTemplatesCache.getTemplates( tag );

        resolve( templates );

      } else {

        console.log('GETTING TEMPLATEs ' + tag.ref );

        let userID = this.usersS.getCurrentUserID();
        let profileID = this.profilesS.getCurrentProfileID();

        let q = environment.API_RC_TEMPLATE + '/' + environment.TAG
        + '/' + userID + '/' + profileID + '/' + tag.ref;

        this.dataS.get$( q )
        .then(
          ( response: any ) => {

            if( response.status === 'success' ) {

              let templates = response.data;

              this.onGetRCTemplatesByTagResponse( tag, templates, resolve );

            } else {

              this.onGetRCTemplatesByTagError( reject );
            }
          }
        )
        .catch( err => {

          reject( err );
        });
      }


    });
  }

  private onGetRCTemplatesByTagResponse( tag, templates, resolve ) {

    this.rcTemplatesCache.addTemplates( tag, templates );

    resolve( templates );
  }

  private onGetRCTemplatesByTagError( reject ) {

    let error = new Error();

    error.name = 'Error en la carga de tags visibles';
    error.message = 'Error indefinido';

    reject( error );
  }


  // *** templates by ref
  public getRCTemplateByRef$_BORRAR( ref ) {

    return new Promise( (resolve, reject ) => {

      let q = environment.API_RC_TEMPLATE + '/' + environment.REF + '/' + ref;

      this.dataS.get$( q )
      .then(
        ( response: any ) => {

          if( response.status === 'success' ) {

            let template = response.data;

            this.onGetRCTemplateByRefResponse( template, resolve );

          } else {

            this.onGetRCTemplateByRefError( reject );
          }
        }
      )
      .catch( err => {

        reject( err );
      });
    });
  }

  private onGetRCTemplateByRefResponse( template, resolve ) {

    resolve( template );
  }

  private onGetRCTemplateByRefError( reject ) {

    let error = new Error();

    error.name = 'Error en la carga de tags visibles';
    error.message = 'Error indefinido';

    reject( error );
  }


  // *** profile Templates

  public loadTemplates$() {

    return new Promise((resolve, reject) => {

      this.rcTemplatesCache.reset();

      this.getRCVisibleTags$()
      .then(
        ( tags: any ) => {

          let tasks = [];

          tags.forEach((tag) => {

            tasks.push(this.getRCTemplatesByTag$( tag ));


          });

          tasks.push(this.getRCTemplatesByProfile$());

          Promise.all(tasks)
          .then(
            () => {

              resolve();
            }
          ).catch((err) => {
            reject(err);
          });
        }
      )
      .catch((err) => {
        reject(err);
      });
    });
  }

  private getRCTemplatesByProfile$() {

    return new Promise((resolve, reject) => {

      let profileID = this.profilesS.getCurrentProfileID();

      let q = environment.API_RC_TEMPLATE + '/'
        + environment.PROFILE
        + '/' + profileID;

      this.dataS.get$( q )
      .then(
        ( response: any ) => {

          if( response.status === 'success' ) {

            let templates = response.data;

            this.onGetRCTemplatesByProfileResponse( templates, resolve );

          } else {

            this.onGetRCTemplatesByProfileError( reject );
          }
        }
      )
      .catch( err => {

        reject( err );
      });

    });
  }


  private onGetRCTemplatesByProfileResponse( templates, resolve ) {

    this.rcTemplatesCache.appendTemplates( templates );

    resolve( templates );
  }


  private onGetRCTemplatesByProfileError( reject ) {

    let error = new Error();

    error.name = 'Error en la carga de tags visibles';
    error.message = 'Error indefinido';

    reject( error );
  }
}
