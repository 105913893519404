import { ProfileProgressSpec } from '../../entities/profile-progress-spec';
import { ProfileProgressType } from '../../entities/profile-progress-type';

export class ProgressUI {

  static PROGRESS = {

    [ProfileProgressType.RANGE]: {

      [ProfileProgressSpec.RECEPTIVE_COMM]: {
        icon: 'receptive-comm'
      },
      [ProfileProgressSpec.EXPRESIVE_COMM]: {
        icon: 'expresive-comm'
      },
      [ProfileProgressSpec.SOCIAL_SKILLS]: {
        icon: 'social-skills'
      },
      [ProfileProgressSpec.IMITATION]: {
        icon: 'imitation'
      },
      [ProfileProgressSpec.COGNITIVE_SKILLS]: {
        icon: 'cognitive'
      },
      [ProfileProgressSpec.GAME_SKILLS]: {
        icon: 'games'
      },
      [ProfileProgressSpec.EAT_AUTONOMY]: {
        icon: 'eat'
      },
      [ProfileProgressSpec.DRESS_AUTONOMY]: {
        icon: 'dress'
      },
      [ProfileProgressSpec.HYGIENE_AUTONOMY]: {
        icon: 'bathtub'
      },
      [ProfileProgressSpec.HOUSEWORK_AUTONOMY]: {
        icon: 'housework'
      }
    }
  }
}
