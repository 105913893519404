import { EfaStateType } from '../../entities/efa-state-type';

export class EfaStateUI {

  static STATES = {

    [EfaStateType.EMOTIONAL]: [
      { // 0 --> SAD
        displayName: 'triste',
        icon: 'sad'
      },
      { // 1 --> AFRAID
        displayName: 'con miedo',
        icon: 'fear'
      },
      { // 2 --> BALANCED
        displayName: 'equilibrad@',
        icon: 'serenity'
      },
      { // 3 --> HAPPY
        displayName: 'content@',
        icon: 'happy'
      },
      { // 3 --> HAPPY
        displayName: 'muy content@',
        icon: 'very-happy'
      }

    ],
    [EfaStateType.PHYSICAL]: [
      { // 0 --> SICK
        displayName: 'enferm@',
        icon: 'sick'
      },
      { // 1 --> TIRED
        displayName: 'cansad@',
        icon: 'tired'
      },
      { // 2 --> ACTIVE
        displayName: 'activ@',
        icon: 'active'
      },
      { // 3 --> VERY ACTIVE
        displayName: 'muy activ@',
        icon: 'very-active'
      }

    ],
    [EfaStateType.ANIMICAL]: [
      { // 0 --> IN CALM
        displayName: 'calmad@',
        icon: 'calm'
      },
      { // 1 --> NERVOUS
        displayName: 'nervios@',
        icon: 'nervous'
      },
      { // 2 --> ACTIVE
        displayName: 'muy nervios@',
        icon: 'stress'
      }

    ]
  };
}
