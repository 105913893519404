import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { NavigationService } from './services/navigation.service';
import { LocalNotificationsService } from './services/local-notifications.service';
import { GeolocationService } from './services/geolocation.service';
import { ScreenService } from './services/screen.service';


import { Paths } from './navigation/paths';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnDestroy {

  public visibleHeader: boolean = false;
  public visibleFixedToolbar: boolean = false;
  public visibleRCsActionsToolbar: boolean = false;
  private $currentURL: Subscription;


  // *** C *** //
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private navS: NavigationService,
    private localNotificationsS: LocalNotificationsService,
    private geolocationS: GeolocationService,
    private screenS: ScreenService
  ) {
    this.initializeApp();
  }


  // *** Lifecycle Events ** //
  ngOnDestroy() {
        // unsubscribe to ensure no memory leaks¡
        this.$currentURL.unsubscribe();
  }


  // *** PRIVATE *** //
  private initializeApp() {

    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.setNavEventHandlers();
      this.setLocalNotificationsEventsHandlers();
      this.initGeolocation();
      this.screenS.init();
      this.hideSplashScreen();

    });
  }

  private setNavEventHandlers() {

    // src: https://jasonwatmore.com/post/2019/02/07/angular-7-communicating-between-components-with-observable-subject
    // on Current URL changes
    this.$currentURL = this.navS
    .getCurrentURLObservable()
    .subscribe( url => {
      this.onCurrentURLChange( url );
    });

  }

  private onCurrentURLChange( url ) {


    if ( url ) {
      switch( url ) {

        case Paths.MEMBERS + '/' + Paths.PROFILES:
        case Paths.MEMBERS + '/' + Paths.INTERVENTIONS:
        case Paths.MEMBERS + '/' + Paths.INTERVENTIONS + '/' + Paths.INTERVENTIONS_MAIN:
        case Paths.MEMBERS + '/' + Paths.PROGRESS:
        case Paths.MEMBERS + '/' + Paths.PROGRESS + '/' + Paths.PROGRESS_MAIN:
        case Paths.MEMBERS + '/' + Paths.SENSORY_PROFILES:
        case Paths.MEMBERS + '/' + Paths.SENSORY_PROFILES + '/' + Paths.SENSORY_PROFILES_MAIN:
        case Paths.MEMBERS + '/' + Paths.INTERESTS:
        case Paths.MEMBERS + '/' + Paths.INTERESTS + '/' + Paths.INTERESTS_MAIN:
        case Paths.MEMBERS + '/' + Paths.DOCUMENTS:
        case Paths.MEMBERS + '/' + Paths.DOCUMENTS + '/' + Paths.DOCUMENTS_MAIN:
        case Paths.MEMBERS + '/' + Paths.GUIDES:
        case Paths.MEMBERS + '/' + Paths.GUIDES + '/' + Paths.GUIDES_MAIN:
          this.visibleHeader = true;
          this.visibleFixedToolbar = false;
          this.visibleRCsActionsToolbar = false;
          break;
        case Paths.MEMBERS + '/' + Paths.RECORDS + '/' + Paths.RECORDS_DAILY:
        case Paths.MEMBERS + '/' + Paths.RECORDS + '/'
          + Paths.RECORDS_DAILY + '/' + Paths.NOT_TODAY:
        case Paths.MEMBERS + '/' + Paths.RECORDS + '/' + Paths.RECORDS_WEEKLY:
          this.visibleHeader = true;
          this.visibleFixedToolbar = true;
          this.visibleRCsActionsToolbar = false;
          break;
        case Paths.MEMBERS + '/' + Paths.RC + '/' + Paths.RC_DAILY:
        case Paths.MEMBERS + '/' + Paths.RC + '/'
          + Paths.RC_DAILY + '/' + Paths.NOT_TODAY:
        case Paths.MEMBERS + '/' + Paths.RC + '/' + Paths.RC_WEEKLY:
          this.visibleHeader = true;
          this.visibleRCsActionsToolbar = true;
          this.visibleFixedToolbar = false;
          break;
        default:
          this.visibleHeader = false;
          this.visibleFixedToolbar = false;
          this.visibleRCsActionsToolbar = false;

          this.setHeadersVisibility( url )

          break;


      }

    }
  }

  private setHeadersVisibility( url ) {

    if( url.indexOf( Paths.PROFILE + '/' ) > -1 ) {

        this.visibleHeader = true;

    }

  }

  private setLocalNotificationsEventsHandlers() {

    this.platform.pause.subscribe((result) => {

      //this.localNotificationsS.scheduleInterventionNotifications();
    });

    this.platform.resume.subscribe((result) => {

      //this.localNotificationsS.cancelAll();
    });
  }

  private initGeolocation() {

    this.geolocationS.init$()
    .then(
      () => {

        let coords = this.geolocationS.getCurrentCoords();
        
      }
    );
  }

  private hideSplashScreen() {

    this.splashScreen.hide();

  }


}
