import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import {RoundProgressModule} from 'angular-svg-round-progressbar';


import { MainHeaderComponent } from './headers/main-header/main-header.component';
import { ProfileHeaderComponent } from './headers/profile-header/profile-header.component';
import { ViewHeaderComponent } from './headers/view-header/view-header.component';
import { AccessFormComponent } from './forms/access-form/access-form.component';
import { NavToolsComponent } from './header-components/nav-tools/nav-tools.component';
import { ProfileToolsComponent } from './header-components/profile-tools/profile-tools.component';
import { RecordsActionsComponent } from './records/records-actions/records-actions.component';
import { RecordsDailyTimetableComponent } from './records-daily/records-daily-timetable/records-daily-timetable.component';
import { RecordsWeeklyTimetableComponent } from './records-weekly/records-weekly-timetable/records-weekly-timetable.component';
import { RecordsDateNavComponent } from './records/records-date-nav/records-date-nav.component';
import { RecordsWeekNavComponent } from './records/records-week-nav/records-week-nav.component';
import { RecordCreationCenterModalComponent } from './records/record-creation-center-modal/record-creation-center-modal.component';
import { CESFRecordModal } from './records/control-esfinter-record-modal/control-esfinter-record-modal.component';
import { RecordDailyCardComponent } from './records-daily/record-daily-card/record-daily-card.component';
import { RecordWeeklyCardComponent } from './records-weekly/record-weekly-card/record-weekly-card.component';
import { RecordWeeklyCardIconComponent } from './records-weekly/record-weekly-card-icon/record-weekly-card-icon.component';
import { RecordWeeklyCardInfoComponent } from './records-weekly/record-weekly-card-info/record-weekly-card-info.component';
import { RecordWeeklyCardInfoIconsComponent } from './records-weekly/record-weekly-card-info-icons/record-weekly-card-info-icons.component';
import { CalendarOpsModalComponent } from './calendar/calendar-ops-modal/calendar-ops-modal.component';
import { RecordsOpsModalComponent } from './records/records-ops-modal/records-ops-modal.component';
import { EfaStateInfoCardComponent } from './efa-state/efa-state-info-card/efa-state-info-card.component';
import { EfaStateCardActionComponent } from './efa-state/efa-state-card-action/efa-state-card-action.component';
import { EfaCenterModalComponent } from './efa-state/efa-center-modal/efa-center-modal.component';
import { ProgressInfoCardComponent } from './progress/progress-info-card/progress-info-card.component';
import { ProgressModalComponent } from './progress/progress-modal/progress-modal.component';
import { BehaviorRecordModal } from './records/behavior-record-modal/behavior-record-modal.component';
import { ActionRecordModal } from './records/action-record-modal/action-record-modal.component';
import { EfaRecordModal } from './records/efa-record-modal/efa-record-modal.component';
import { EventRecordModal } from './records/event-record-modal/event-record-modal.component';
import { CustomRecordModal } from './records/custom-record-modal/custom-record-modal.component';
import { ProgressQuestionCardComponent } from './progress/progress-question-card/progress-question-card.component';
import { SensoryProfileInfoCardComponent } from './sensory-profiles/sensory-profile-info-card/sensory-profile-info-card.component';
import { SensoryProfileModalComponent } from './sensory-profiles/sensory-profile-modal/sensory-profile-modal.component';
import { SensoryProfileQuestionCardComponent } from './sensory-profiles/sensory-profile-question-card/sensory-profile-question-card.component';
import { InterestCardComponent } from './interests/interest-card/interest-card.component';
import { DocumentCardComponent } from './documents/document-card/document-card.component';
import { GuideCardComponent } from './guides/guide-card/guide-card.component';
import { GuideModalComponent } from './guides/guide-modal/guide-modal.component';
import { GuideContentCardComponent } from './guides/guide-content-card/guide-content-card.component';
import { RCsActionsComponent } from './rcs/rcs-actions/rcs-actions.component';
import { RCCreationModalComponent } from './rcs/rc-creation-modal/rc-creation-modal.component';
import { RCCreationActionsComponent } from './rcs/rc-creation-actions/rc-creation-actions.component';
import { RCRCTCreationActionComponent } from './rcs/rc-rct-creation-action/rc-rct-creation-action.component';
import { RCRCTModalComponent } from './rcs/rc-rct-modal/rc-rct-modal.component';
import { RCDatetimeFieldComponent } from './rc-form/rc-datetime-field/rc-datetime-field.component';
import { RCDurationFieldComponent } from './rc-form/rc-duration-field/rc-duration-field.component';
import { RCColorPickerFieldComponent } from './rc-form/rc-color-picker-field/rc-color-picker-field.component';
import { RCTemplateFieldComponent } from './rc-form/rc-template-field/rc-template-field.component';
import { RCTemplateSelectFieldComponent } from './rc-form/rc-template-select-field/rc-template-select-field.component';
import { RCsDailyTimetableComponent } from './rcs-daily/rcs-daily-timetable/rcs-daily-timetable.component';
import { RCsDateNavComponent } from './rcs/rcs-date-nav/rcs-date-nav.component';
import { RCsWeeklyTimetableComponent } from './rcs-weekly/rcs-weekly-timetable/rcs-weekly-timetable.component';
import { RCTimetableCardComponent } from './rcs-cards/rc-timetable-card/rc-timetable-card.component';
import { RCCardInfoFieldComponent } from './rcs-cards/rc-card-info-field/rc-card-info-field.component';
import { RCCardTxtInfoFieldComponent } from './rcs-cards/rc-card-txt-info-field/rc-card-txt-info-field.component';
import { RCCardSelectInfoFieldComponent } from './rcs-cards/rc-card-select-info-field/rc-card-select-info-field.component';

@NgModule({
  declarations: [
    MainHeaderComponent,
    ProfileHeaderComponent,
    ViewHeaderComponent,
    AccessFormComponent,
    NavToolsComponent,
    ProfileToolsComponent,
    RecordsActionsComponent,
    RecordsDailyTimetableComponent,
    RecordsWeeklyTimetableComponent,
    RecordsDateNavComponent,
    RecordsWeekNavComponent,
    RecordCreationCenterModalComponent,
    RecordDailyCardComponent,
    RecordWeeklyCardComponent,
    RecordWeeklyCardIconComponent,
    RecordWeeklyCardInfoComponent,
    RecordWeeklyCardInfoIconsComponent,
    CESFRecordModal,
    CalendarOpsModalComponent,
    RecordsOpsModalComponent,
    EfaStateInfoCardComponent,
    EfaStateCardActionComponent,
    EfaCenterModalComponent,
    ProgressInfoCardComponent,
    ProgressModalComponent,
    BehaviorRecordModal,
    ActionRecordModal,
    EfaRecordModal,
    EventRecordModal,
    CustomRecordModal,
    ProgressQuestionCardComponent,
    SensoryProfileInfoCardComponent,
    SensoryProfileModalComponent,
    SensoryProfileQuestionCardComponent,
    InterestCardComponent,
    DocumentCardComponent,
    GuideCardComponent,
    GuideModalComponent,
    GuideContentCardComponent,
    RCsActionsComponent,
    RCCreationModalComponent,
    RCCreationActionsComponent,
    RCRCTCreationActionComponent,
    RCRCTModalComponent,
    RCDatetimeFieldComponent,
    RCDurationFieldComponent,
    RCColorPickerFieldComponent,
    RCTemplateFieldComponent,
    RCTemplateSelectFieldComponent,
    RCsDailyTimetableComponent,
    RCsDateNavComponent,
    RCsWeeklyTimetableComponent,
    RCTimetableCardComponent,
    RCCardInfoFieldComponent,
    RCCardTxtInfoFieldComponent,
    RCCardSelectInfoFieldComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    RoundProgressModule
  ],
  exports: [
    //MainHeaderComponent,
    //ProfileHeaderComponent,
    ViewHeaderComponent,
    AccessFormComponent,
    RecordsActionsComponent,
    RecordsDailyTimetableComponent,
    RecordsWeeklyTimetableComponent,
    RecordsDateNavComponent,
    RecordsWeekNavComponent,
    EfaStateInfoCardComponent,
    ProgressInfoCardComponent,
    SensoryProfileInfoCardComponent,
    SensoryProfileQuestionCardComponent,
    InterestCardComponent,
    DocumentCardComponent,
    GuideCardComponent,
    GuideContentCardComponent,
    RCsActionsComponent,
    RCCreationActionsComponent,
    RCRCTCreationActionComponent,
    RCDatetimeFieldComponent,
    RCDurationFieldComponent,
    RCColorPickerFieldComponent,
    RCTemplateFieldComponent,
    RCTemplateSelectFieldComponent,
    RCsDailyTimetableComponent,
    RCsDateNavComponent,
    RCsWeeklyTimetableComponent,
    RCTimetableCardComponent,
    RCCardInfoFieldComponent,
    RCCardTxtInfoFieldComponent,
    RCCardSelectInfoFieldComponent
  ],
    entryComponents: [
        RecordCreationCenterModalComponent,
        CESFRecordModal,
        CalendarOpsModalComponent,
        RecordsOpsModalComponent,
        EfaCenterModalComponent,
        ProgressModalComponent,
        BehaviorRecordModal,
        ActionRecordModal,
        EfaRecordModal,
        EventRecordModal,
        CustomRecordModal,
        SensoryProfileModalComponent,
        GuideModalComponent,
        RCCreationModalComponent,
        RCRCTModalComponent
  ]
})
export class ViewComponentsModule { }
