import { Behavior } from '../../../entities/behavior';
import { BehaviorType } from '../../../entities/behavior-type';

export class BehaviorUI {

  static BEHAVIOR_TYPES = {

    [BehaviorType.SELF_INJURIOUS]: {

      displayName: 'Dirigida a si mismo (autolesiva)'
    },
    [BehaviorType.AGGRESIVE]: {

      displayName: 'Dirigida a otra persona (agresiva)'
    },
    [BehaviorType.DISTURBANCE]: {

      displayName: 'Dirigida a objetos (perturbación)'
    },
    [BehaviorType.NON_COMPLIANCE]: {

      displayName: 'Desobediencia'
    },
    [BehaviorType.SELF_STIMULATION]: {

      displayName: 'Auto-estimulación'
    },
    [BehaviorType.OTHER]: {

      displayName: 'Otro tipo de conducta'
    }
  };


  static BEHAVIORS = {


    // *** SELF_INJURIOUS
    [Behavior.BITEHIMSELF]: {

      displayName: 'Se mordio a si mismo'
    },
    [Behavior.HIT_HIS_EYES]: {

      displayName: 'Golpeó sus ojos'
    },
    [Behavior.HIT_HIS_HEAD]: {

      displayName: 'Golpeó su cabeza'
    },
    [Behavior.HIT_HIS_HEAD_WITH_OBJECT]: {

      displayName: 'Golpeó su cabeza con un objeto'
    },
    [Behavior.HIT_HIMSELF]: {

      displayName: 'Se golpeo a si mismo'
    },
    [Behavior.PULL_HIS_HAIR]: {

      displayName: 'Se tiró del pelo'
    },
    [Behavior.HORN_HIMSELF]: {

      displayName: 'Se dió trompadas'
    },
    [Behavior.PINCH_HIMSELF]: {

      displayName: 'Se pellizcó a si mismo'
    },
    [Behavior.POUND_HIMSELF]: {

      displayName: 'Se aporreo a si mismo'
    },


    // *** AGRESIVE
    [Behavior.BITE]: {

      displayName: 'Mordió a otro'
    },
    [Behavior.GRAB_TIGHT]: {

      displayName: 'Tiro fuerte de otro'
    },
    [Behavior.PULL_OTHERS_HAIR]: {

      displayName: 'Tiro del pelo a otro'
    },
    [Behavior.BUTT_OTHER]: {

      displayName: 'Dió topetazos a otro'
    },
    [Behavior.HIT_OTHER]: {

      displayName: 'Golpeó a otro'
    },
    [Behavior.KICK_OTHER]: {

      displayName: 'Pateó a otro'
    },
    [Behavior.PINCH_OTHER]: {

      displayName: 'Pellizcó a otro'
    },
    [Behavior.PUSH_OTHER]: {

      displayName: 'Empujo a otro'
    },
    [Behavior.HORN_OTHER]: {

      displayName: 'Dió trompadas a otro'
    },
    [Behavior.POUND_OTHER]: {

      displayName: 'Aporreó a otro'
    },
    [Behavior.THROW_OBJECT_TO_OTHER]: {

      displayName: 'Tiró objetos a otro'
    },


    // *** DISTURBANCE
    [Behavior.HIT_OBJECTS]: {

      displayName: 'Golpear objetos'
    },
    [Behavior.REMOVE_OBJECTS_FROM_TABLE]: {

      displayName: 'Guitó objetos de la mesa'
    },
    [Behavior.KICK_OBJECTS]: {

      displayName: 'Pateó objetos'
    },
    [Behavior.SCREAM]: {

      displayName: 'Gritó'
    },
    [Behavior.BAWL]: {

      displayName: 'Berreó'
    },
    [Behavior.BREAK_OBJECTS]: {

      displayName: 'Rompió objetos'
    },
    [Behavior.THROW_OBJECTS]: {

      displayName: 'Tiró objetos'
    },



    // *** NON_COMPLIANCE
    [Behavior.SCREECH_WHEN_ASKED]: {

      displayName: 'Protestó'
    },
    [Behavior.BECOME_AGGRESSIVE_WHEN_ASKED]: {

      displayName: 'Se puso agresivo'
    },
    [Behavior.UPSET_WHEN_ASKED]: {

      displayName: 'Se disgustó'
    },
    [Behavior.GET_DOWN_ON_THE_FLOOR]: {

      displayName: 'Se tiró al suelo'
    },
    [Behavior.HARM_HIMSELF_WHEN_ASKED]: {

      displayName: 'Se autolesionó'
    },

    [Behavior.OTHER]: {

      displayName: 'Otra conducta'
    }
  }
}
