import { Injectable } from '@angular/core';

import { DataService } from './data.service';

import{ Guide } from '../entities/guide';
import{ GuideClass } from '../entities/guide-class';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GuidesService {

  private undefinedGuides = [];
  private behaviorGuides = [];
  private educationGuides = [];
  private relationsGuides = [];


  // *** C
  constructor(
    private dataS: DataService
  ) { }



  // *** guides
  public loadGuides$() {

    return new Promise((resolve, reject) => {

      let q = environment.API_GUIDE;

      this.dataS.get$( q )
      .then(
        ( response: any ) => {

          if( response.status === 'success' ) {

            this.onLoadGuidesResponse( response.data, resolve );

          } else {

            this.onLoadGuidesError( reject );

          }

        }
      )
      .catch( err => {

        reject( err );

      });


    })

  }

  private onLoadGuidesResponse( guides, resolve ) {

    this.undefinedGuides = [];
    this.behaviorGuides = [];
    this.educationGuides = [];
    this.relationsGuides = [];

    guides.forEach(( guide ) => {

      switch( guide.class ) {

        case GuideClass.UNDEFINED:
          this.undefinedGuides.push( guide );
          break;
        case GuideClass.BEHAVIOUR:
          this.behaviorGuides.push( guide );
          break;
        case GuideClass.EDUCATION:
          this.educationGuides.push( guide );
          break;
        case GuideClass.RELATIONS:
          this.relationsGuides.push( guide );
          break;
      }

    });


    resolve();
  }

  private onLoadGuidesError( reject ) {

    let error = new Error();

    error.name = 'Error en la carga de guideos';
    error.message = 'Error indefinido';

    reject( error );

  }


  // *** guide
  public getGuideContents$( guideRef ) {

    return new Promise(( resolve, reject ) => {

      let q = environment.API_GUIDE_CONTENT + '/' + guideRef;

      this.dataS.get$( q ).then(
        ( response: any ) => {

          if( response.status === 'success' ) {

            this.onGetGuideContentsResponse( response.data, resolve );

          } else {

            this.onGetGuideContentsError( reject );
          }

        }).catch(
          (err) => {


          }
        );


    });

  }

  private onGetGuideContentsResponse( guideContents, resolve ) {

    resolve( guideContents );

  }

  private onGetGuideContentsError( reject ) {

    let error = new Error();

    error.name = 'Error en la carga de contenidos de guia';
    error.message = 'Error indefinido';

    reject( error );
  }


  // *** guides accesors
  public getUndefinedGuides() {

    return this.undefinedGuides;
  }

  public getBehaviorGuides() {

    return this.behaviorGuides;
  }

  public getEducationGuides() {

    return this.educationGuides;
  }

  public getRelationGuides() {

    return this.relationsGuides;
  }
}
