import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RCActionsService {

  private rcActionsMode$: Subject<any> = new Subject();

  public static DAILY = 0;
  public static WEEKLY = 1;

  public static REMOVE_OP = 0;

  private dateMode = RCActionsService.DAILY;

  // *** C
  constructor() { }


  // *** date mode
  public setDateMode( mode ) {

    this.dateMode = mode;

    this.rcActionsMode$.next( this.dateMode );
  }

  public getDateMode() {

    return this.dateMode;
  }


  // *** OPs
  public cancelOPs() {}


  // *** observables
  public getRCActionsMode$() {

    return this.rcActionsMode$.asObservable();
  }
}
