import { GuideClass } from './guide-class';

export class Guide {

  title: string;
  ref: string;
  class: number = GuideClass.UNDEFINED;
  creation: Date = new Date();
  description: string;


  constructor(obj) {
    obj && Object.assign(this, obj);
  }

}
