import{ ProfileSensoryProfileQuestion } from './profile-sensory-profile-question';


export class ProfileSensoryProfile {

  _id: string;
  lastUpdate?: Date;
  sensoryProfile: {
    type: number;
    spec: number;
    title: string;
    totalScore: number;
    normalPerformance: {
      max: number;
      min: number;
    };
    probableDifference: {
      max: number;
      min: number;
    };
    definitiveDifference: {
      max: number;
      min: number;
    };
  };
  questions?: ProfileSensoryProfileQuestion[];



  public getScore() {

    let score = 0;

    if( Array.isArray(this.questions)) {


      this.questions.forEach((question) => {

        switch( question.answerValue ) {

          case 0:
            score += 5;
            break;
          case 1:
            score += 4;
            break;
          case 2:
            score += 3;
            break;
          case 3:
            score += 2;
            break;
          case 4:
            score += 1;
            break;
        }

      });

    }

    return score;

  }


  public getValoration() {

    let valoration = {

      normalPerformance: false,
      probableDifference: false,
      probableDifferenceScore: 0,
      definitiveDifference: false

    }

    let score = this.getScore();

    if( score <= this.sensoryProfile.normalPerformance.max && score >= this.sensoryProfile.normalPerformance.min ) {

      valoration.normalPerformance = true;
    }

    if( score <= this.sensoryProfile.probableDifference.max && score >= this.sensoryProfile.probableDifference.min ) {

      valoration.probableDifference = true;

      valoration.probableDifferenceScore = this.sensoryProfile.probableDifference.max - score;
    }

    if( score <= this.sensoryProfile.definitiveDifference.max && score >= this.sensoryProfile.definitiveDifference.min ) {

      valoration.definitiveDifference = true;
    }


    return valoration;


  }


  constructor(obj) {
    obj && Object.assign(this, obj);
  }

}
