import { Component, OnInit, Input } from '@angular/core';
import { ModalController, NavParams }  from '@ionic/angular';

import { ProgressUI } from '../progress-ui';

import { ProfileProgress } from '../../../entities/profile-progress';
import { ProfileProgressQuestion } from '../../../entities/profile-progress-question';

@Component({
  selector: 'app-progress-modal',
  templateUrl: './progress-modal.component.html',
  styleUrls: ['./progress-modal.component.scss'],
})
export class ProgressModalComponent implements OnInit {


  public progress: ProfileProgress;
  public questions: ProfileProgressQuestion[] = [];

  public modalTitle: string = 'Progresos';
  public modalIcon: string = 'expresive-comm';

  public scoreTXT: string;

  // *** C *** //
  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams,
  ) {}


  // ** Lifecycle ** //
  ngOnInit() {

    this.initProgressType();
  }


  // ** progress type set **//
  private initProgressType() {

    this.progress =  this.navParams.get('progress');
    this.questions = this.progress.questions;

    this.modalTitle = this.progress.progress.title;

    let progressType = this.progress.progress.type;
    let progressSpec = this.progress.progress.spec;


    this.modalIcon =
      ProgressUI.PROGRESS[progressType][progressSpec].icon;

  }

  // *** modal UI *** //
  public onClickCloseModal() {
    this.modalCtrl.dismiss();
  }


}
