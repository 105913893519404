

import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

import { RCTInfoField } from '../../../entities/rct-info-field';
import { RCTInfoFieldType } from '../../../entities/rct-info-field-type';

import { Validators, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-rc-template-field',
  templateUrl: './rc-template-field.component.html',
  styleUrls: ['./rc-template-field.component.scss'],
})
export class RCTemplateFieldComponent implements OnInit {


  @Input() infoField: RCTInfoField;
  @Input() value: any;
  @Input() edition: boolean;

  @Output() value$ = new EventEmitter<any>();

  public rcForm: FormGroup;


  public TXT: number = RCTInfoFieldType.TXT;
  public TXT_AREA: number = RCTInfoFieldType.TXT_AREA;
  public SELECT_ONE: number = RCTInfoFieldType.SELECT_ONE;
  public SELECT_MULTI: number = RCTInfoFieldType.SELECT_MULTI;


  // *** C
  constructor(
    private formBuilder: FormBuilder
  ) { }


  // *** Lifecycle
  ngOnInit() {

    this.init();
  }


  // *** initialization
  private init() {

    this.initType();


    if( this.edition ) {

      this.initEdition();

    } else {

      this.initInfo();
    }

  }

  private initType() {
    /*
    switch( this.infoField.type ) {

      case TXT:
        this.initTxtField();
        break;
      case TXT_AREA:
        this.initTxtAreaField();
        break;
      case SELECT_ONE:
        this.initSelectOneField();
        break;
      case SELECT_MULTI:
        this.initSelectMultiField();
        break;

    }*/
  }

  private initEdition() {

    this.initForm();
  }

  private initInfo() {}


  // *** form
  private initForm() {

    let fldValue = [this.value];

    this.rcForm = this.formBuilder.group({
      value: [this.value]
    });
  }

  public onValueChange() {

    this.value = this.rcForm.value.value;

    if( this.value.length === 0 ) {

      this.value = null;
    }

    this.value$.emit( this.value );
  }


  // *** TXT Field
  private initTxtField() {}

}
