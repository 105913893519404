import { RCTInfoFieldType } from './rct-info-field-type';
import { RCTInfoFieldOption } from './rct-info-field-option';


export class RCTInfoField {


  // *** Attrs
  name?: string = null;
  label: string = null;
  index?: number;
  required?: boolean;
  type: number = RCTInfoFieldType.UNDEFINED;
  options?: RCTInfoFieldOption[];


  // *** C
  constructor( obj ) {

    if( obj ) {

      Object.assign(this, obj);
    }
  }


  public getOptionByValue( value ) {

    let option = null;

    this.options.forEach((optionR: RCTInfoFieldOption) => {

      if( optionR.value === value ) {

        option = optionR;
      }
    });

    return option;
  }
}
