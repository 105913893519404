export class ProfileSensoryProfileSpec {
  static UNDEFINED = -1;
  static AUDITORY_PROCESS = 1;
  static VISUAL_PROCESS = 2;
  static VESTIBULAR_PROCESS = 3;
  static TACTIL_PROCESS = 4;
  static MULTISENSORIAL_PROCESS = 5;
  static ORAL_SENSORIAL_PROCESS = 6;
  static RESISTANCE_RELATED_SENSORY_PROCESS = 7;
  static POSITION_MOVEMENT_RELATED_MODULATION = 8;
  static ACTIVITY_LEVEL_MOVEMENT_MODULATION = 9;
  static SENSORIAL_INFORMATION_EMOTIONAL_MODULATION = 10;
  static VISUAL_INFORMATION_EMOTIONAL_MODULATION = 11;
  static EMOTIONAL_SOCIAL_REACTIONS = 12;
  static SENSORIAL_PROCESS_BEHAVIOUR = 13;
  static RESPONSE_UMBRAL = 14;
}
