import { Injectable } from '@angular/core';

import { DataService } from './data.service';
import { ProfilesService } from './profiles.service';

import { ProfileSensoryProfile } from '../entities/profile-sensory-profile';
import { ProfileSensoryProfileType } from '../entities/profile-sensory-profile-type';
import { ProfileSensoryProfileSpec } from '../entities/profile-sensory-profile-spec';

import { ProfileSensoryProfileQuestion } from '../entities/profile-sensory-profile-question';


import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SensoryProfilesService {

  private auditoryPSensoryP;
  private visualPSensoryP;
  private vestibularPSensoryP;

  private pSensoryProfiles: any = [];

  // *** C *** //
  constructor(
    private profilesS: ProfilesService,
    private dataS: DataService
  ) {}


  // *** GET p sensory p ** //
  public getPSensoryProfiles( sensoryProfileSpec ) {

    return this.pSensoryProfiles[sensoryProfileSpec];
  }


  public getAuditoryPSensoryP() {

    return this.auditoryPSensoryP;
  }

  public getVisualPSensoryP() {

    return this.visualPSensoryP;
  }

  public getVestibularPSensoryP() {

    return this.vestibularPSensoryP;
  }


  // *** load profile psensoryp ** //
  public loadProfileSensoryProfiles$() {

    return new Promise((resolve, reject) => {

      let profileID = this.profilesS.getCurrentProfileID();

      let tasks = [];

      tasks.push( this.loadPSensoryProfile$( profileID, ProfileSensoryProfileSpec.AUDITORY_PROCESS ));
      tasks.push( this.loadPSensoryProfile$( profileID, ProfileSensoryProfileSpec.VISUAL_PROCESS ));
      tasks.push( this.loadPSensoryProfile$( profileID, ProfileSensoryProfileSpec.VESTIBULAR_PROCESS ));
      tasks.push( this.loadPSensoryProfile$( profileID, ProfileSensoryProfileSpec.TACTIL_PROCESS ));
      tasks.push( this.loadPSensoryProfile$( profileID, ProfileSensoryProfileSpec.MULTISENSORIAL_PROCESS ));
      tasks.push( this.loadPSensoryProfile$( profileID, ProfileSensoryProfileSpec.ORAL_SENSORIAL_PROCESS ));
      tasks.push( this.loadPSensoryProfile$( profileID, ProfileSensoryProfileSpec.RESISTANCE_RELATED_SENSORY_PROCESS ));
      tasks.push( this.loadPSensoryProfile$( profileID, ProfileSensoryProfileSpec.POSITION_MOVEMENT_RELATED_MODULATION ));
      tasks.push( this.loadPSensoryProfile$( profileID, ProfileSensoryProfileSpec.ACTIVITY_LEVEL_MOVEMENT_MODULATION ));
      tasks.push( this.loadPSensoryProfile$( profileID, ProfileSensoryProfileSpec.SENSORIAL_INFORMATION_EMOTIONAL_MODULATION ));
      tasks.push( this.loadPSensoryProfile$( profileID, ProfileSensoryProfileSpec.VISUAL_INFORMATION_EMOTIONAL_MODULATION ));
      tasks.push( this.loadPSensoryProfile$( profileID, ProfileSensoryProfileSpec.EMOTIONAL_SOCIAL_REACTIONS ));
      tasks.push( this.loadPSensoryProfile$( profileID, ProfileSensoryProfileSpec.SENSORIAL_PROCESS_BEHAVIOUR ));
      tasks.push( this.loadPSensoryProfile$( profileID, ProfileSensoryProfileSpec.RESPONSE_UMBRAL ));

      Promise.all( tasks )
      .then(
        () => {

          //this.logPSensoryP();

          resolve();
        }
      )
      .catch( err => reject(err));

    })

  }

  private loadPSensoryProfile$( profileID, pSensoryProfileSpec ) {

    return new Promise(( resolve, reject ) => {

      let q: string =
        environment.API_PROFILE_SENSORY_PROFILE
        + '/' + profileID + '/'
        + ProfileSensoryProfileType.RANGE
        + '/' + pSensoryProfileSpec;

        this.dataS.get$( q )
        .then(
          ( response: any ) => {

            if( response.status === 'success' ) {

              let responseData: any = response.data;

              let psensoryp = new ProfileSensoryProfile( responseData );

              this.loadPSensoryPQuestions$( psensoryp._id)
              .then( ( questions: ProfileSensoryProfileQuestion[] ) => {

                psensoryp.questions = questions;

                this.pSensoryProfiles[pSensoryProfileSpec] = psensoryp;

                resolve();

              })
              .catch((err) => {

                reject(err);

              });

            } else {

              this.onLoadProfileSensoryProfileError( reject );
            }
          }
        )
        .catch( err => {

          reject( err );
        });

    });

  }



  // ** SENSORY PROFILE QUESTIONs *** //
  private loadPSensoryPQuestions$( sensoryProfileID ) {

    return new Promise((resolve, reject) => {

      let q: string =
        environment.API_PROFILE_SENSORY_PROFILE_QUESTIONS
        + '/' + sensoryProfileID;

        this.dataS.get$( q )
        .then(
          ( response: any ) => {

            if( response.status === 'success' ) {

              let responseData: any = response.data;

              let questions: ProfileSensoryProfileQuestion[] = responseData;

              resolve( questions );

            } else {

              this.onLoadProfileSensoryProfileError( reject );
            }
          }
        )
        .catch( err => {

          reject( err );
        });

    });

  }

  public updateQuestion$( question) {

    return new Promise( (resolve, reject ) => {

      let q: string = environment.API_PROFILE_SENSORY_PROFILE;

      this.dataS.put$( q, question )
      .then(
        ( response: any ) => {

          if( response.status === 'success' ) {

            this.onUpdateQuestionResponse( question, resolve, reject );

          } else {

            this.onUpdateQuestionError( response, reject );
          }
        }
      )
      .catch( err => {

        reject( err );

      });
    });
  }

  private onUpdateQuestionResponse( question, resolve, reject ) {

    //this.newRecord$.next( record );
    //this.updatedRecord$.next( record );

    resolve();
  }

  private onUpdateQuestionError( resolve, reject ) {

    let error = new Error();

    error.name = 'Error en la actualización de respuesta de perfil sensorial';
    error.message = 'Error indefinido';


    reject( error );
  }

  // *** ON RESPOSE ERROR ***//
  private onLoadProfileSensoryProfileError( reject ) {

    let error = new Error();

    error.name = 'Error en obtención de perfiles sensoriales';

    error.message = 'Error indefinido';

    reject( error );
  }
}
