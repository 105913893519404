import{ ProfileProgressQuestion } from './profile-progress-question';


export class ProfileProgress {

  _id: string;
  lastUpdate?: Date
  progress: {
    type: number
    spec: number;
    title: string;
  };
  questions?: ProfileProgressQuestion[];

  constructor(obj) {
    obj && Object.assign(this, obj);
  }
}
