import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavParams }  from '@ionic/angular';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';

import { EfaStateUI } from '../../efa-state/efa-state-ui';

import { RecordsService } from '../../../services/records.service';
import { UsersService } from '../../../services/users.service';
import { ProfilesService } from '../../../services/profiles.service';
import { EfaStatesService } from '../../../services/efa-states.service';

import { Record } from '../../../entities/record';
import { RecordTimeUnit } from '../../../entities/record-time-unit';
import { RecordType } from '../../../entities/record-type';
import { EfaRecordInfo } from '../../../entities/efa-record-info';
import { EfaStateType } from '../../../entities/efa-state-type';

import { Paths } from '../../../navigation/paths';

@Component({
  selector: 'app-efa-record-modal',
  templateUrl: './efa-record-modal.component.html',
  styleUrls: ['./efa-record-modal.component.scss'],
})
export class EfaRecordModal implements OnInit {

  // *** modal
  public modalTitle: string = 'N/A';
  public modalIcon: string = 'remorse';

  // *** efa
  private efaType: string;
  public efaStates: any = [];
  private efaValue: any = -1;

  // *** date field
  public enabledDateField: boolean = false;

  // *** buttons
  public hiddenCloneButton: boolean = true;
  public submitBtnTxt = 'CREAR';
  public disabledSubmit: boolean = true;

  // *** form
  public recordForm: FormGroup;
  private submitted: boolean = false;

  // *** record
  private record: Record;


  // *** C
  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams,
    private formBuilder: FormBuilder,
    private recordsS: RecordsService,
    private profilesS: ProfilesService,
    private efaStatesS: EfaStatesService,
    private usersS: UsersService,
    private router: Router
  ) { }


  // *** Lifecycle events
  ngOnInit() {

    this.readModalParams();
    this.initEfaStates();
    this.createForm();
    this.initUI();

  }


  // ** convenience getter for easy access to form fields ** //
  private get f() {
    return this.recordForm.controls;
  }


  // *** modal UI
  public onClickCloseModal() {
    this.modalCtrl.dismiss();
  }

  private initUI() {

    switch( this.efaType ) {

      case EfaStateType.EMOTIONAL:
        this.modalIcon = 'efa-emotional';
        this.modalTitle = 'Estado emocional';
        break;
      case EfaStateType.PHYSICAL:
        this.modalIcon = 'efa-physical';
        this.modalTitle = 'Estado físico';
        break;
      case EfaStateType.ANIMICAL:
        this.modalIcon = 'efa-animical';
        this.modalTitle = 'Estado anímico';
        break;

    }

  }

  public async onSubmit() {

    if( this.record ) {

      this.onSubmitModifyRecord();

    } else {

      this.onSubmitNewRecord();
    }
  }

  public onClone() {
    this.onSubmitNewRecord();
  }


  private onSubmitNewRecord() {

    this.submitted = true;

    if( !this.validFormValues() ) {

      this.submitted = false;

      return;

    } else {

      let values = this.getRecordValues();

      let newRecord = new Record( values );


      this.recordsS.storeNewRecord$( newRecord )
      .then(
        ( data ) => {

          this.updateEfaState()
          .then(
            () => {
              this.modalCtrl.dismiss();
            }
          )
          .catch( err => {
            let errJson = JSON.stringify(err);

            this.router.navigate(['/' + Paths.ERROR, errJson]);

            let data = {
              error: 'error'
            }

            this.submitted = false;

            this.modalCtrl.dismiss( data );
          });


        }
      )
      .catch(
        err => {

          let errJson = JSON.stringify(err);

          this.router.navigate(['/' + Paths.ERROR, errJson]);

          let data = {
            error: 'error'
          }

          this.submitted = false;

          this.modalCtrl.dismiss( data );
        }
      );
    }
  }

  private onSubmitModifyRecord() {

    this.submitted = true;

    if( !this.validFormValues()) {

      this.submitted = false;

      return;

    } else {

      this.updateRecord();


      this.recordsS.updateRecord$( this.record )
      .then(
        ( data ) => {

          this.updateEfaState()
          .then(
            () => {
              this.modalCtrl.dismiss();
            }
          )

        }
      )
      .catch(
        err => {

          let errJson = JSON.stringify(err);

          this.router.navigate(['/' + Paths.ERROR, errJson]);

          let data = {
            error: 'error'
          }

          this.submitted = false;

          this.modalCtrl.dismiss( data );
        }
      );
    }

  }

  // *** form
  private createForm() {

    let date;
    let time;
    let durationTXT;
    let durationTime = -1;
    let durationUnit = RecordTimeUnit.UNDEFINED;

    let efaValue = -1;

    let observations = null;


    if( !this.record ) {

      let now = new Date();

      date = this.recordsS.getCurrentDate();
      time = date;

    } else {

      durationTime = this.record.durationTime;
      durationUnit = this.record.durationUnit;

      date = this.record.date;
      time = this.record.time;

      efaValue = this.efaValue;

      observations = this.record.observations;
    }



    this.recordForm = this.formBuilder.group({

      date: [date.toString(), Validators.required],
      time: [time.toString(), Validators.required],
      durationTXT: [durationTXT],
      durationTime: durationTime,
      durationUnit: durationUnit,
      efaValue: efaValue,
      observations: [observations]
    });

    this.disabledSubmit = !this.validFormValues();

    this.setFormEventsHandlers();
  }

  private setFormEventsHandlers() {

    this.recordForm.valueChanges.subscribe(
      ( value ) => {

        this.disabledSubmit = !this.validFormValues();
      }
    );
  }

  private getRecordValues() {

    let recordInfo: EfaRecordInfo = new EfaRecordInfo(
      {
        type: this.efaType,
        value: parseInt(this.recordForm.value.efaValue)
      }
    )

    let isoDate = new Date( this.recordForm.value.date ).toISOString();
    let isoTime = new Date( this.recordForm.value.time ).toISOString();

    let values = {
      type: RecordType.EFA,
      date: isoDate,
      time: isoTime,
      durationTime: this.recordForm.value.durationTime,
      durationUnit: this.recordForm.value.durationUnit,
      observations: this.recordForm.value.observations,
      author: this.usersS.getCurrentUserID(),
      profile: this.profilesS.getCurrentProfileID(),
      recordInfo: recordInfo
    }

    return values;
  }

  private validFormValues() {

    let valid = false;

    if( !this.recordForm.invalid ) {

      let efaValue = parseInt(this.recordForm.value.efaValue);

      if( efaValue !== -1 ) {

        valid = true;

      }

    }

    return valid;
  }

  // *** efa states
  private initEfaStates() {

    this.efaStates = EfaStateUI.STATES[ this.efaType ];

  }

  public onClickEfaState( index ) {

    this.efaValue = index;

    this.recordForm.controls['efaValue'].setValue(this.efaValue);

    switch( this.efaType ) {

      case EfaStateType.EMOTIONAL:
        //this.efaServiceS.setEmotionalState( this.efaValue );
        break;

      case EfaStateType.ANIMICAL:
        //this.efaServiceS.setAnimicalState( this.efaValue );
        break;

      case EfaStateType.PHYSICAL:
        //this.efaServiceS.setPhysicalState( this.efaValue );
        break;
    }

  }

  private updateEfaState() {

    return new Promise( (resolve, reject ) => {

      switch( this.efaType ) {

        case EfaStateType.EMOTIONAL:
          this.efaStatesS.loadEmotionalState()
          .then(
            () => {
              resolve();
            }
          )
          .catch( err => { reject(err)});
          break;
        case EfaStateType.PHYSICAL:
          this.efaStatesS.loadPhysicalState()
          .then(
            () => {
              resolve();
            }
          )
          .catch( err => { reject(err)});
          break;
        case EfaStateType.ANIMICAL:
          this.efaStatesS.loadAnimicalState()
          .then(
            () => {
              resolve();
            }
          )
          .catch( err => { reject(err)});
          break;

      }

    });


  }


  // *** record ops
  public updateRecord() {

    let values = this.getRecordValues();

    let recordID = this.record._id;

    this.record = new Record( values );

    this.record._id = recordID;

  }


  // *** time
  public onClickNow() {

    let now = new Date();

    this.recordForm.controls['date'].setValue(now.toString());
    this.recordForm.controls['time'].setValue(now.toString());

  }


  // *** modal params
  public readModalParams() {

    this.record = this.navParams.get('record');

    if( this.record ) {

      this.efaType = this.record.recordInfo.type;
      this.efaValue = this.record.recordInfo.value;

      this.submitBtnTxt = 'MODIFICAR';
      this.enabledDateField = true;
      this.hiddenCloneButton = false;

    } else {


      this.efaType = this.navParams.get('efaType');
    }



  }




}
