import { Component, OnInit, Input } from '@angular/core';

import { Record } from '../../../entities/record';
import { RecordType } from '../../../entities/record-type';
import { RecordValoration } from '../../../entities/record-valoration';

@Component({
  selector: 'app-record-weekly-card',
  templateUrl: './record-weekly-card.component.html',
  styleUrls: ['./record-weekly-card.component.scss'],
})
export class RecordWeeklyCardComponent implements OnInit {


  @Input() record: Record;

  public recordType: number = RecordType.UNDEFINED;

  public bgColorClass: string;

  // *** C
  constructor() { }


  // *** Lifecycle
  ngOnInit() {

    this.setType();
    this.setBgColor();
  }


  // *** Record
  private setType() {

    switch( this.record.type ) {

      case RecordType.CONTROL_ESFINTER:
        this.recordType = RecordType.CONTROL_ESFINTER;
        break;
      case RecordType.BEHAVIOR:
        this.recordType = RecordType.BEHAVIOR;
        break;
      case RecordType.ACTION:
        this.recordType = RecordType.ACTION;
        break;
      case RecordType.EFA:
        this.recordType = RecordType.EFA;
        break;
      case RecordType.EVENT:
        this.recordType = RecordType.EVENT;
        break;
      case RecordType.CUSTOM:
        this.recordType = RecordType.CUSTOM;
        break;
    }
  }


  // *** bg color
  public setBgColor() {

    switch( this.recordType ) {

      case RecordType.CONTROL_ESFINTER:
        this.setValorationBgColor();
        break;
      case RecordType.BEHAVIOR:
      case RecordType.ACTION:
      case RecordType.EFA:
      case RecordType.EVENT:
      case RecordType.CUSTOM:

        this.setDefaultBgColor();
        break;
    }
  }

  public setValorationBgColor() {

    switch( this.record.valoration ) {

      case RecordValoration.EXCELENT:
        this.bgColorClass = 'excelent';
        break;

      case RecordValoration.GOOD:
        this.bgColorClass = 'good';
        break;

      case RecordValoration.NOT_BAD:
        this.bgColorClass = 'not-bad';
        break;

      case RecordValoration.BAD:
        this.bgColorClass = 'bad';
        break;


    }

  }

  public setDefaultBgColor() {

    let bgColor = this.record.bgColor;

    if( typeof bgColor !== 'undefined'
      && bgColor !== null
      && parseInt(bgColor) > -1 ) {

      this.bgColorClass = 'record-card-bg-color-' + bgColor;

    }

  }


}
