import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams }  from '@ionic/angular';

import { EfaStateUI } from '../efa-state-ui';
import { EfaStateType } from '../../../entities/efa-state-type';
import { EfaStatesService } from '../../../services/efa-states.service';

@Component({
  selector: 'app-efa-center-modal',
  templateUrl: './efa-center-modal.component.html',
  styleUrls: ['./efa-center-modal.component.scss'],
})
export class EfaCenterModalComponent implements OnInit {

  private efaType: string = EfaStateType.EMOTIONAL;
  private efaValue: any = -1;
  public efaStates: any = [];

  public modalTitle: string = 'N/A';
  public modalIcon: string = 'remorse';

  // *** C *** //
  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams,
    private efaServiceS: EfaStatesService
  ) {

    this.efaType = this.navParams.get('efaType');
    this.efaValue = this.navParams.get('efaValue');

    this.setEfaType();
  }


  // *** lifecycle *** //
  ngOnInit() {}

  private setEfaType() {

    switch( this.efaType ) {

      case EfaStateType.EMOTIONAL:
        this.modalTitle = 'Estado emocional';
        this.modalIcon = 'efa-emotional';
        break;

      case EfaStateType.ANIMICAL:
        this.modalTitle = 'Estado animico';
        this.modalIcon = 'efa-animical';
        break;

      case EfaStateType.PHYSICAL:
        this.modalTitle = 'Estado físico';
        this.modalIcon = 'efa-physical';
        break;
    }

    this.efaStates = EfaStateUI.STATES[ this.efaType ];
  }

  // *** modal UI *** //
  public onClickCloseModal() {
    this.modalCtrl.dismiss();
  }

  public onClickEfaState( index ) {

    this.efaValue = index;

    switch( this.efaType ) {

      case EfaStateType.EMOTIONAL:
        this.efaServiceS.setEmotionalState( this.efaValue );
        break;

      case EfaStateType.ANIMICAL:
        this.efaServiceS.setAnimicalState( this.efaValue );
        break;

      case EfaStateType.PHYSICAL:
        this.efaServiceS.setPhysicalState( this.efaValue );
        break;
    }

  }
}
