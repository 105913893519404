import { NgModule } from '@angular/core';
import {
  PreloadAllModules,
  RouterModule,
  Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard'

import { Paths } from './navigation/paths';


const routes: Routes = [
  {
    path: '',
    redirectTo: Paths.ACCESS,
    pathMatch: 'full' },
  {
    path: Paths.ACCESS,
    loadChildren: './views/access-view/access-view.module#AccessViewPageModule' },
  {
    path: Paths.MEMBERS,
    canActivate: [AuthGuard],
    loadChildren: './navigation/members-routing.module#MembersRoutingModule'
  },
  {
    path: Paths.ERROR + '/:errorData',
    loadChildren: './views/error-view/error-view.module#ErrorViewPageModule'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        preloadingStrategy: PreloadAllModules
      })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
