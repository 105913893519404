

export class RCTInfoFieldType {

  static UNDEFINED:number = -1;
  static TXT: number = 0;
  static TXT_AREA: number = 1;
  static SELECT_ONE: number = 2;
  static SELECT_MULTI: number = 3;

}
