import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { RecordsService } from '../../../services/records.service';
import { ModalController } from "@ionic/angular";
import { Ionic4DatepickerModalComponent } from '@logisticinfotech/ionic4-datepicker';

import { CalendarDatePickerHelper } from '../../../helpers/calendar-date-picker-helper';


import * as moment from 'moment';

@Component({
  selector: 'app-records-date-nav',
  templateUrl: './records-date-nav.component.html',
  styleUrls: ['./records-date-nav.component.scss'],
})
export class RecordsDateNavComponent implements OnInit {

  public currentDate: Date;
  private currentDate$: Subscription;

  // src: https://github.com/logisticinfotech/ionic4-datepicker-example/blob/master/src/app/datepicker-button/datepicker-button.page.ts
  public datePickerObj: any = {};
  public selectedDate;
  // *** C *** //
  constructor(
    private recordsS: RecordsService,
    public modalCtrl: ModalController
  ) { }

  // *** Lifecycle events
  ngOnInit() {


    this.currentDate = this.recordsS.getCurrentDate();

    this.initDatePicker();

    //this.selectedDate = this.currentDate;

    this.setCurrentDateEventHandlers();

  }


  // ***  current date
  private setCurrentDateEventHandlers() {

    this.currentDate$ = this.recordsS
    .getCurrentDateObservable().subscribe( currentDate => {

      this.currentDate = this.recordsS.getCurrentDate();

      this.initDatePicker();

    })

  }

  public onClickDecreaseDate() {

    this.recordsS.decreaseCurrentDate();

  }

  public onClickIncreaseDate() {

    this.recordsS.increaseCurrentDate();
  }

  // *** date picker
  public initDatePicker() {

    this.datePickerObj =
      CalendarDatePickerHelper.getDailyCalendarPickerConf( this.currentDate );

  }

  public onClickDate() {

    this.openDatePicker();

  }

  private async openDatePicker() {

    const datePickerModal = await this.modalCtrl.create({
      component: Ionic4DatepickerModalComponent,
      cssClass: "li-ionic4-datePicker",
      componentProps: { objConfig: this.datePickerObj }
    });

    await datePickerModal.present();

    datePickerModal.onDidDismiss().then(data => {

      if( data.data.date !== 'Invalid date' ) {
        let date = data.data.date;

        this.selectedDate = date;

        let formattedDate = moment(date,'DD-MM-YYYY');

        this.recordsS.setCurrentDate( formattedDate.toDate() );
      }
    });
  }
}
