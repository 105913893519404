import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

import { RCTInfoField } from '../../../entities/rct-info-field';
import { RCTInfoFieldType } from '../../../entities/rct-info-field-type';
import { RCTInfoFieldOption } from '../../../entities/rct-info-field-option';


@Component({
  selector: 'app-rc-template-select-field',
  templateUrl: './rc-template-select-field.component.html',
  styleUrls: ['./rc-template-select-field.component.scss'],
})
export class RCTemplateSelectFieldComponent implements OnInit {

  @Input() infoField: RCTInfoField;
  @Input() value: any;
  @Input() edition: boolean;

  @Output() value$ = new EventEmitter<any>();

  public fOptions: RCTInfoFieldOption[];

  // *** C
  constructor() { }

  // *** Lifecycle
  ngOnInit() {

    this.init();
  }


  // *** initialization
  private init() {

    this.initOptions();

    if( this.infoField.type === RCTInfoFieldType.SELECT_ONE ) {

      this.initSelectOne();

    } else if( this.infoField.type === RCTInfoFieldType.SELECT_MULTI ){

      this.initSelectMulti();
    }

  }

  private initOptions() {

    this.fOptions = this.infoField.options;
  }

  private initSelectOne() {}

  private initSelectMulti() {

    if( !this.value || this.value === null ) {

      this.value = [];
    }

  }


  // *** actions
  public onOptionClick( value ) {

    if( this.infoField.type === RCTInfoFieldType.SELECT_ONE ) {

      this.updateSelectOne( value );

    } else if( this.infoField.type === RCTInfoFieldType.SELECT_MULTI ){

      this.updateSelectMulti( value );
    }


  }


  // *** Option selection
  public isSelectedOption( value ) {

    let isSelected = false;

    if( this.infoField.type === RCTInfoFieldType.SELECT_ONE ) {

      isSelected = ( value === this.value );

    } else if( this.infoField.type === RCTInfoFieldType.SELECT_MULTI ){

      if( this.value !== null ) {

        isSelected = ( this.value.includes(value) );
      }


    }

    return isSelected;
  }

  private updateSelectOne( sValue ) {

    if( sValue !== this.value ) {

      this.value = sValue;

    } else {

      this.value = null;
    }



    this.value$.emit( this.value );

  }

  private updateSelectMulti( sValue ) {

    if( this.value === null ) {

      this.value = [];
    }

    if( this.value.includes( sValue )) {

      var index = this.value.indexOf(sValue);

      if (index > -1) {

        this.value.splice(index, 1);
      }
    } else {

      this.value.push( sValue );
    }

    if( this.value.length === 0 ) {

      this.value = null;
    }

    this.value$.emit( this.value );
  }




}
