import { Component, OnInit, Input } from '@angular/core';

import { Record } from '../../../entities/record';
import { RecordType } from '../../../entities/record-type';

import { EfaStateUI } from '../../efa-state/efa-state-ui';
import { EfaStateType } from '../../../entities/efa-state-type';

@Component({
  selector: 'app-record-weekly-card-info',
  templateUrl: './record-weekly-card-info.component.html',
  styleUrls: ['./record-weekly-card-info.component.scss'],
})
export class RecordWeeklyCardInfoComponent implements OnInit {


  @Input() record: Record;

  public visible: boolean = true;

  public recordType: number = RecordType.UNDEFINED;
  public title: string = 'Registro no definindo';

  // *** C
  constructor() { }


  // *** Lifecycle
  ngOnInit() {

    this.setType();

    this.setVisibility();
  }


  // *** record
  private setType() {

    switch( this.record.type ) {

      case RecordType.CONTROL_ESFINTER:
        this.title = 'Control de esfinter';
        this.recordType = RecordType.CONTROL_ESFINTER;
        break;
      case RecordType.BEHAVIOR:
        this.title = 'Conducta';
        this.recordType = RecordType.BEHAVIOR;
        break;
      case RecordType.ACTION:
        this.title = 'Acción';
        this.recordType = RecordType.ACTION;
        break;
      case RecordType.EFA:
        this.setEfaType();
        this.recordType = RecordType.EFA;
        break;
      case RecordType.EVENT:
        this.title = this.record.recordInfo.eventName;
        this.recordType = RecordType.EVENT;
        break;
      case RecordType.CUSTOM:
        this.title = this.record.recordInfo.recordName;
        this.recordType = RecordType.CUSTOM;
        break;
    }

  }

  private setEfaType() {

    let recordEfaType = this.record.recordInfo.type;

    let vals = EfaStateUI.STATES[ recordEfaType ];

    let efaStateInfo = vals[ this.record.recordInfo.value ];


    switch( this.record.recordInfo.type ) {

      case EfaStateType.EMOTIONAL:
        this.title = efaStateInfo.displayName;
        break;
      case EfaStateType.PHYSICAL:
        this.title = efaStateInfo.displayName;
        break;
      case EfaStateType.ANIMICAL:
        this.title = efaStateInfo.displayName;
        break;

    }

  }




  // *** Visibility
  private setVisibility() {

    if( this.recordType === RecordType.CONTROL_ESFINTER ) {

      this.visible = false;

    }
  }




}
