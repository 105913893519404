import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalController, LoadingController  }  from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ProfilesService } from '../../../services/profiles.service';
import { RCActionsService } from '../../../services/rc-actions.service';
import { RCsService } from '../../../services/rcs.service';
import { RCsOpsService } from '../../../services/rcs-ops.service';
import { NavigationService } from '../../../services/navigation.service';
import { Paths } from '../../../navigation/paths';
import { RCCreationModalComponent } from '../rc-creation-modal/rc-creation-modal.component';

@Component({
  selector: 'app-rcs-actions',
  templateUrl: './rcs-actions.component.html',
  styleUrls: ['./rcs-actions.component.scss'],
})
export class RCsActionsComponent implements OnInit, OnDestroy {


  private rcActionsMode$: Subscription;
  private selection$: Subscription;

  public addRCEnabled: boolean = false;
  public addRCVisible: boolean = false;
  public removeRCsEnabled: boolean = false;
  public removeRCsVisible: boolean = false;
  public manageRCsEnabled: boolean = false;
  public manageRCsVisible: boolean = false;
  public cancelOpEnabled: boolean = false;
  public cancelOpVisible: boolean = false;
  public acceptOpEnabled: boolean = false;
  public acceptOpVisible: boolean = false;
  public todayVisible: boolean = false;
  public weekCalendarVisible: boolean = false;
  public dailyCalendarVisible: boolean = false;

  public editionEnabled: boolean = false;

  private modal: any;
  private loadingAlert: any;

  public visible: boolean = true;


  // *** C
  constructor(
    private modalCtrl: ModalController,
    private loadingCtrl: LoadingController,
    private profilesS: ProfilesService,
    private rcActionsS: RCActionsService,
    private rcsS: RCsService,
    private navS: NavigationService,
    private rcsOpsS: RCsOpsService

  ) {

    this.setEditionMode();
  }


  // *** Lifecycle
  ngOnInit() {

    this.init();
  }

  ngOnDestroy() {

    this.rcActionsMode$.unsubscribe();
    this.selection$.unsubscribe();

    this.resetActions();
  }


  // *** initialization
  private init() {

    this.initSubscriptions();

    setTimeout(
      () => {
        this.initDateMode();
      },
      500
    );
  }


  // *** actions
  public onClickAddRC() {

    this.openAddRCModal();
  }

  public onClickDailyView() {

    let url = Paths.MEMBERS + '/' + Paths.RC + '/' + Paths.RC_DAILY;

    this.navS.ghostPush(url);
  }

  public onClickWeeklyView() {

    let url = Paths.MEMBERS + '/' + Paths.RC + '/' + Paths.RC_WEEKLY;

    this.navS.ghostPush(url);
  }

  public onClickManageRCs() {

    this.initManageRCs();

    this.rcsOpsS.toogleSelectionActive();
  }

  private initManageRCs() {

    this.addRCEnabled = false;
    this.addRCVisible = false;
    this.removeRCsEnabled = true;
    this.removeRCsVisible = false;
    this.manageRCsEnabled = false;
    this.manageRCsVisible = false;
    this.cancelOpEnabled = true;
    this.cancelOpVisible = true;
    this.acceptOpEnabled = false;
    this.acceptOpVisible = false;
    this.todayVisible = false;
    this.weekCalendarVisible = false;
    this.dailyCalendarVisible = false;

  }

  public onClickCancel() {

    this.rcActionsS.cancelOPs();

    this.rcsOpsS.toogleSelectionActive();

    this.initDateMode();
  }

  public onClickToday() {

    this.rcsS.setCurrentDatetime( new Date());
  }

  public onClickRemoveRC() {

    this.showLoadingAlert$('borrando registros')
    .then(
      () => {

        this.rcsOpsS.rmSelected$()
        .then(
          () => {

            this.dismissLoadingAlert$(100)
            .then(
              () => {
                this.rcsS.setCurrentDatetime( this.rcsS.getCurrentDatetime());
              }
            )
          }
        )
        .catch(
          (err) => {

            this.dismissLoadingAlert$(0)
            .then(
              () => {
                throw new Error(err);
              }
            )
          }
        );
      }
    )
  }


  // *** modal
  private async openAddRCModal() {

    this.modal = await this.modalCtrl.create({
      component: RCCreationModalComponent
    })

    await this.modal.present();
  }


  // *** edition mode
  private setEditionMode() {

    this.editionEnabled = this.profilesS.isCurrentUserEditor();
  }


  // *** subscriptions
  private initSubscriptions() {

    this.rcActionsMode$ = this.rcActionsS.getRCActionsMode$()
    .subscribe(
      ( mode ) => {

        this.visible = false;

        setTimeout(
          () => {
            switch( mode ) {
              case RCActionsService.DAILY:
                this.initDailyMode();
                break;
              case RCActionsService.WEEKLY:
                this.initWeeklyMode();
                break;
            }

            this.visible = true;
          },
          1000
        );


      }
    );

    this.selection$ = this.rcsOpsS.getSelection$()
    .subscribe(
      ( val ) => {

        if( this.rcsOpsS.getSelectedCount() === 0 ) {

          this.removeRCsVisible = false;

        } else {

          this.removeRCsVisible = true;
        }

      }
    );
  }


  // *** date mode
  private initDateMode() {

    switch( this.rcActionsS.getDateMode()) {

      case RCActionsService.DAILY:
        this.initDailyMode();
        break;

      case RCActionsService.WEEKLY:
        this.initWeeklyMode();
        break;
    }
  }

  private initDailyMode() {

    this.addRCEnabled = true;
    this.addRCVisible = true;

    this.removeRCsEnabled = false;
    this.removeRCsVisible = false;

    this.manageRCsEnabled = true;
    this.manageRCsVisible = true;

    this.cancelOpEnabled = false;
    this.cancelOpVisible = false;

    this.acceptOpEnabled = false;
    this.acceptOpVisible = false;

    this.todayVisible = true;

    this.weekCalendarVisible = true;

    this.dailyCalendarVisible = false;
  }

  private initWeeklyMode() {

    this.addRCEnabled = false;
    this.addRCVisible = false;

    this.removeRCsEnabled = false;
    this.removeRCsVisible = false;

    this.manageRCsEnabled = false;
    this.manageRCsVisible = false;

    this.cancelOpEnabled = false;
    this.cancelOpVisible = false;

    this.acceptOpEnabled = false;
    this.acceptOpVisible = false;

    this.todayVisible = true;

    this.weekCalendarVisible = false;

    this.dailyCalendarVisible = true;
  }

  private resetActions() {

    this.addRCEnabled = false;
    this.addRCVisible = false;

    this.removeRCsEnabled = false;
    this.removeRCsVisible = false;

    this.manageRCsEnabled = false;
    this.manageRCsVisible = false;

    this.cancelOpEnabled = false;
    this.cancelOpVisible = false;

    this.acceptOpEnabled = false;
    this.acceptOpVisible = false;

    this.todayVisible = false;

    this.weekCalendarVisible = false;

    this.dailyCalendarVisible = false;
  }

  // *** loading alert
  // *** loading alert
  private showLoadingAlert$( msg ) {

    return new Promise((resolve, reject) => {

      this.loadingCtrl.create({
        message: msg
      }).then(
        ( spinner ) => {

          this.loadingAlert = spinner;
          this.loadingAlert.present();

          resolve();

        }
      );
    });
  }

  private dismissLoadingAlert$( timeout ) {

    return new Promise((resolve, reject) => {

      setTimeout(
        () => {
          this.loadingAlert.dismiss()
          .then(() => { resolve(); });
        },
        timeout
      );


    });
  }

}
