import { Component, OnInit, Input } from '@angular/core';

import { GuideContent } from '../../../entities/guide-content';

@Component({
  selector: 'app-guide-content-card',
  templateUrl: './guide-content-card.component.html',
  styleUrls: ['./guide-content-card.component.scss'],
})
export class GuideContentCardComponent implements OnInit {


  @Input() content: GuideContent;

  public cardContent: string;

  // *** C
  constructor() { }


  // *** Lifecycle
  ngOnInit() {

    this.setCardContent();
  }



  // *** card content
  public setCardContent() {

    this.cardContent = "";

    this.content.blocks.forEach((block) => {

      this.cardContent += block;
    });
  }

}
