export class ProgressConf {

  static QUESTION_MAXIMUN_SCORE = 100;

  static RANGE_NOT_INTRODUCED = 0;
  static RANGE_IN_PROCCESS = 30;
  static RANGE_ADQUIRED = 60;
  static RANGE_GENERALIZED = 100;

  static LOW_STATE_MAX = 30;
  static MEDIUM_STATE_MAX = 88;
  static HIGH_STATE_MAX = 100;

  static UPDATE_NEEDED_DAYS = 30;
  static REVIEW_NEEDED_DAYS = 15;
  static UPDATED_DAYS = 0;

}
