import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';

import { DataService } from './data.service';
import { ProfilesService } from './profiles.service';

import { ProfileProgress } from '../entities/profile-progress';
import { ProfileProgressType } from '../entities/profile-progress-type';
import { ProfileProgressSpec } from '../entities/profile-progress-spec';

import { ProfileProgressQuestion } from '../entities/profile-progress-question';


import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ProgressService {



  private pProgress: any = [];

  private updatedProgress$ = new Subject<any>();




  // *** C *** //
  constructor(
    private profilesS: ProfilesService,
    private dataS: DataService
  ) {}


  // *** GET pprogress ** //
  public getPProgress( progressSpec ) {

    return this.pProgress[progressSpec];
  }



  // *** load profile progress ** //
  public loadProfileProgresses$() {

    return new Promise((resolve, reject) => {

      let profileID = this.profilesS.getCurrentProfileID();

      let tasks = [];
      /*
      tasks.push( this.loadReceptiveCommPProgress$( profileID ));
      tasks.push( this.loadExpresiveCommPProgress$( profileID ));
      tasks.push( this.loadSocialSkillPProgress$( profileID ));
      */

      tasks.push( this.loadPProgress$( profileID, ProfileProgressSpec.RECEPTIVE_COMM));
      tasks.push( this.loadPProgress$( profileID, ProfileProgressSpec.EXPRESIVE_COMM));
      tasks.push( this.loadPProgress$( profileID, ProfileProgressSpec.SOCIAL_SKILLS));
      tasks.push( this.loadPProgress$( profileID, ProfileProgressSpec.IMITATION));
      tasks.push( this.loadPProgress$( profileID, ProfileProgressSpec.COGNITIVE_SKILLS));
      tasks.push( this.loadPProgress$( profileID, ProfileProgressSpec.GAME_SKILLS));
      tasks.push( this.loadPProgress$( profileID, ProfileProgressSpec.EAT_AUTONOMY));
      tasks.push( this.loadPProgress$( profileID, ProfileProgressSpec.DRESS_AUTONOMY));
      tasks.push( this.loadPProgress$( profileID, ProfileProgressSpec.HYGIENE_AUTONOMY));
      tasks.push( this.loadPProgress$( profileID, ProfileProgressSpec.HOUSEWORK_AUTONOMY));

      Promise.all( tasks )
      .then(
        () => {

          //this.logPProgress();

          resolve();
        }
      )
      .catch( err => reject(err));

    })

  }

  private loadPProgress$( profileID, progressSpec ) {

    return new Promise(( resolve, reject ) => {

      let q: string =
        environment.API_PROFILE_PROGRESS
        + '/' + profileID + '/'
        + ProfileProgressType.RANGE
        + '/' + progressSpec

        this.dataS.get$( q )
        .then(
          ( response: any ) => {

            if( response.status === 'success' ) {

              let responseData: any = response.data;

              let pprogress = new ProfileProgress( responseData );

              this.loadPProgressQuestions$( pprogress._id)
              .then( ( questions: ProfileProgressQuestion[] ) => {

                pprogress.questions = questions;

                //this.receptiveCommPProgress = pprogress;

                this.pProgress[progressSpec] = pprogress;

                resolve();

              })
              .catch((err) => {

                reject(err);

              });

            } else {

              this.onLoadProfileProgressError( reject );
            }
          }
        )
        .catch( err => {

          reject( err );
        });

    });


  }



  // ** PROGRESS QUESTIONs *** //
  private loadPProgressQuestions$( progressID ) {

    return new Promise((resolve, reject) => {

      let q: string =
        environment.API_PROFILE_PROGRESS_QUESTIONS
        + '/' + progressID;

        this.dataS.get$( q )
        .then(
          ( response: any ) => {

            if( response.status === 'success' ) {

              let responseData: any = response.data;

              let questions: ProfileProgressQuestion[] = responseData;

              resolve( questions );

            } else {

              this.onLoadProfileProgressError( reject );
            }
          }
        )
        .catch( err => {

          reject( err );
        });

    });

  }

  public updateQuestion$( question ) {

    return new Promise( (resolve, reject ) => {

      let q: string = environment.API_PROFILE_PROGRESS;

      this.dataS.put$( q, question )
      .then(
        ( response: any ) => {

          if( response.status === 'success' ) {

            this.onUpdateQuestionResponse( question, resolve, reject );

          } else {

            this.onUpdateQuestionError( response, reject );
          }
        }
      )
      .catch( err => {

        reject( err );

      });
    });
  }

  private onUpdateQuestionResponse( question, resolve, reject ) {

    //this.newRecord$.next( record );
    //this.updatedRecord$.next( record );

    this.updatedProgress$.next(question);

    resolve();
  }

  private onUpdateQuestionError( resolve, reject ) {

    let error = new Error();

    error.name = 'Error en la actualización de registro';
    error.message = 'Error indefinido';


    reject( error );
  }


  // *** ON RESPOSE ERROR ***//
  private onLoadProfileProgressError( reject ) {

    let error = new Error();

    error.name = 'Error en obtención de progresos perfiles';

    error.message = 'Error indefinido';

    reject( error );
  }

  // *** observables
  public getUpdatedProgress$() {

    return this.updatedProgress$.asObservable();

  }

}
