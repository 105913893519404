import { Injectable } from '@angular/core';

import { EmotionalState } from '../entities/emotional-state';
import { PhysicalState } from '../entities/physical-state';
import { AnimicalState } from '../entities/animical-state';
import { EfaStateType } from '../entities/efa-state-type';

import {Observable, Subject} from 'rxjs';

import { UsersService } from './users.service';
import { ProfilesService } from './profiles.service';
import { RecordsService } from './records.service';

import { Record } from '../entities/record';
import { RecordType } from '../entities/record-type';

@Injectable({
  providedIn: 'root'
})
export class EfaStatesService {

  // current profile efa states
  private emotionalState: number = EmotionalState.BALANCED;
  private physicalState: number = PhysicalState.ACTIVE;
  private animicalState: number = AnimicalState.IN_CALM;

  // efa state change observables
  private emotionalState$: Subject<any> = new Subject<any>();
  private physicalState$: Subject<any> = new Subject<any>();
  private animicalState$: Subject<any> = new Subject<any>();



  // *** C *** //
  constructor(
    private usersS: UsersService,
    private profilesS: ProfilesService,
    private recordsS: RecordsService
  ) {

    this.loadEFAStates$().then();

  }


  // *** CURRENT PROFILE EFA STATE *** //
  public loadEFAStates$() {

      return Promise.all([
        this.loadEmotionalState(),
        this.loadPhysicalState(),
        this.loadAnimicalState()
      ]);

  }

  public loadEmotionalState() {

    return new Promise(( resolve, reject ) => {

      let profileID = this.profilesS.getCurrentProfileID();

      this.recordsS.getLastEfaRecord$( profileID, EfaStateType.EMOTIONAL )
      .then(
        ( efaRecord: any ) => {

          if( efaRecord !== null ) {

            this.emotionalState = efaRecord.recordInfo.value;
          } else {

            this.emotionalState = EmotionalState.UNDEFINED;
          }

          this.emotionalState$.next(this.emotionalState);

          resolve();
        }
      ).catch(
        err => {
          reject( err );
        }
      );
    });
  }

  public loadPhysicalState() {

    return new Promise(( resolve, reject ) => {

      let profileID = this.profilesS.getCurrentProfileID();

      this.recordsS.getLastEfaRecord$( profileID, EfaStateType.PHYSICAL )
      .then(
        ( efaRecord: any ) => {

          if( efaRecord !== null ) {

            this.physicalState = efaRecord.recordInfo.value;
          } else {

            this.physicalState = EmotionalState.UNDEFINED;
          }

          this.physicalState$.next(this.physicalState);

          resolve();
        }
      ).catch(
        err => {
          reject( err );
        }
      );
    });
  }

  public loadAnimicalState() {

    return new Promise(( resolve, reject ) => {

      let profileID = this.profilesS.getCurrentProfileID();

      this.recordsS.getLastEfaRecord$( profileID, EfaStateType.ANIMICAL)
      .then(
        ( efaRecord: any ) => {

          if( efaRecord !== null ) {

            this.animicalState = efaRecord.recordInfo.value;
          } else {

            this.animicalState = EmotionalState.UNDEFINED;
          }

          this.animicalState$.next(this.animicalState);

          resolve();
        }
      ).catch(
        err => {
          reject( err );
        }
      );
    });
  }

  public setEmotionalState( state ) {

    return new Promise((resolve, reject) => {

      let newEfaRecord = this.createNewEfaRecord( EfaStateType.EMOTIONAL, state );

      this.recordsS.storeNewRecord$( newEfaRecord )
      .then(
        ( efaRecord ) => {

          this.emotionalState = state;

          this.emotionalState$.next( this.emotionalState );

          resolve();

        }
      )
      .catch(
        ( err ) => {

          reject( err );
        }
      );
    })

  }

  public setPhysicalState( state ) {


    return new Promise((resolve, reject) => {

      let newEfaRecord = this.createNewEfaRecord( EfaStateType.PHYSICAL, state );

      this.recordsS.storeNewRecord$( newEfaRecord )
      .then(
        ( efaRecord ) => {

          this.physicalState = state;

          this.physicalState$.next( this.physicalState );

          resolve();

        }
      )
      .catch(
        ( err ) => {

          reject( err );
        }
      );
    })
  }

  public setAnimicalState( state ) {

    return new Promise((resolve, reject) => {

      let newEfaRecord = this.createNewEfaRecord( EfaStateType.ANIMICAL, state );

      this.recordsS.storeNewRecord$( newEfaRecord )
      .then(
        ( efaRecord ) => {

          this.animicalState = state;

          this.animicalState$.next( this.animicalState );

          resolve();

        }
      )
      .catch(
        ( err ) => {

          reject( err );
        }
      );
    })
  }


  // *** EFA STATE CHANGE OBSERVABLES *** //
  public getEmotionalState$() {

    return this.emotionalState$.asObservable();

  }

  public getAnimicalState$() {

    return this.animicalState$.asObservable();

  }

  public getPhysicalState$() {

    return this.physicalState$.asObservable();

  }


  // *** Efa records *** //
  private createNewEfaRecord( type, value) {


    let efaRecordInfo = {

      type: type,
      value: value

    }

    let efaRecordValues = {

      type: RecordType.EFA,
      date: new Date(),
      time: new Date(),
      author: this.usersS.getCurrentUserID(),
      profile: this.profilesS.getCurrentProfileID(),
      recordInfo: efaRecordInfo

    }

    return new Record( efaRecordValues );


  }


  // *** helpers *** //
  private getRandom( min, max ) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }


}
