import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { ModalController, NavParams, LoadingController }  from '@ionic/angular';

import { UsersService } from '../../../services/users.service';
import { ProfilesService } from '../../../services/profiles.service';
import { RCsService } from '../../../services/rcs.service';

import { RCTemplatesCacheService } from '../../../cache/rc-templates-cache.service';

import { RC } from '../../../entities/rc';
import{ RCReactionValues } from '../../../entities/rc-reaction-values'
import { RCInfoField } from '../../../entities/rc-info-field';
import { RCTemplate } from '../../../entities/rc-template';
import { RCTInfoField } from '../../../entities/rct-info-field';
import { RCTInfoFieldType } from '../../../entities/rct-info-field-type';
import { RCTInfoFieldOption } from '../../../entities/rct-info-field-option';

import { RCHelper } from '../../../helpers/rc-helper';

import * as moment from 'moment';

@Component({
  selector: 'app-rc-rct-modal',
  templateUrl: './rc-rct-modal.component.html',
  styleUrls: ['./rc-rct-modal.component.scss'],
})
export class RCRCTModalComponent implements OnInit {


  private DEF_BGCOLOR: string = '#FFFFFF';

  public template: RCTemplate;
  public infoFields: RCTInfoField[] = [];
  private rc: RC;
  public validRC: boolean = false;

  public rcValues: any = {};

  public title: string;
  public icon: string;

  public edition: boolean;
  public dateEdition: boolean = false;
  public timeEdition: boolean = true;

  public loading: any;

  public mode: number;

  public TXT: number = RCTInfoFieldType.TXT;
  public TXT_AREA: number = RCTInfoFieldType.TXT_AREA;
  public SELECT_ONE: number = RCTInfoFieldType.SELECT_ONE;
  public SELECT_MULTI: number = RCTInfoFieldType.SELECT_MULTI;

  public CREATION: number = 0;
  public EDITION: number = 1;


  // *** C
  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams,
    public loadingCtrl: LoadingController,
    private cdRef:ChangeDetectorRef,
    private usersS: UsersService,
    private profilesS: ProfilesService,
    private rcsS: RCsService,
    private rcTemplatesCache: RCTemplatesCacheService
  ) { }


  // *** Lifecycle
  ngOnInit() {

    this.readParams();
    this.init();
  }


  // *** initialization
  private readParams() {

    this.template = this.navParams.get('template');
    this.rc = this.navParams.get('rc');
    this.edition = this.navParams.get('edition');

  }

  private init() {

    this.initEditionPermission();

    this.rcValues = {};

    if( this.rc ) {

      this.initUsingRC();

    } else if( this.template ) {

      this.initUsingTemplate();
    }
  }


  // *** BY RC
  private initUsingRC() {

    this.mode = this.EDITION;
    this.dateEdition = true;
    this.timeEdition = true;

    this.initRCTemplate();

    this.icon = this.template.icon.icon;

    if( typeof this.rc.title !== 'undefined'
      && this.rc.title !== null ) {

        this.title = this.rc.title;

    } else {

      this.title = this.template.name;
    }

    this.initRCValuesUsingRC();

    this.initRCInfoFieldsUsingRC();

    this.updateRCValidity();
  }

  private initRCTemplate() {

    this.template = this.rcTemplatesCache.getTemplate( this.rc.templateRef );

    this.initTemplate();
  }

  private initRCValuesUsingRC() {

    let title = null;

    if( typeof this.rc.title !== 'undefined' && this.rc.title !== null ) {

      title = this.rc.title;
    }

    // rc stuff
    this.rcValues = {
      title: title,
      dateTime: this.rc.dateTime,
      duration: this.rc.duration,
      durationUnit: this.rc.durationUnit,
      templateRef: this.rc.templateRef,
      owner: this.rc.owner,
      profile: this.rc.profile,
      bgColor: this.rc.bgColor
    }

    // rc reactions
    if( this.rc.reaction ) {

      this.rcValues.reaction = this.rc.reaction;

    }
  }

  private initRCInfoFieldsUsingRC() {

    let fields = [];

    this.infoFields.forEach((field) => {

      let infoField = this.rc.getInfoField( field.name );

      let infoFieldValue = null;

      if( infoField !== null ) {

        infoFieldValue = infoField.value;
      }

      let rcInfoField = new RCInfoField({
        name: field.name,
        value: infoFieldValue
      })

      fields.push( new RCInfoField( rcInfoField ));

    });

    this.rcValues.info = {

      fields: fields
    }
  }


  // *** Template
  private initUsingTemplate() {

    this.mode = this.CREATION;
    this.dateEdition = false;
    this.timeEdition = true;

    // modal stuff
    this.title = this.template.name;
    this.icon = this.template.icon.icon;


    this.initTemplate();

    this.initRCValuesUsingTemplate();

    this.initRCInfoFieldsUsingTemplate();

    this.updateRCValidity();

  }

  private initTemplate() {

    this.infoFields = RCHelper.getInfoFields( this.template );
  }

  private initRCValuesUsingTemplate() {

    let nowM = moment( new Date());

    let dateTime = moment( this.rcsS.getCurrentDatetime()).hour(nowM.hour()).minute(nowM.minute()).toDate();

    // rc stuff
    this.rcValues = {
      title: null,
      dateTime: dateTime,
      templateRef: this.template.ref,
      owner: this.usersS.getCurrentUserID(),
      profile: this.profilesS.getCurrentProfileID(),
      bgColor: this.getBgColor(),
    }

    // rc reactions
    if( this.template.reaction ) {

      this.rcValues.reaction = RCReactionValues.NOT_BAD;

    }
  }

  private initRCInfoFieldsUsingTemplate() {

    let fields = [];

    this.infoFields.forEach((field) => {

      let rcInfoField = new RCInfoField({
        name: field.name,
        value: null
      })

      fields.push( new RCInfoField( rcInfoField ));

    });

    this.rcValues.info = {

      fields: fields
    }

  }


  // *** on values changed
  public onDateTimeChanged( dateTime: Date ) {

    this.rcValues.dateTime = dateTime.toISOString();

    this.updateRCValidity();

    this.cdRef.detectChanges();

    //this.logRCValues();
  }

  public onDurationChanged( data ) {

    this.rcValues.duration = data.duration;
    this.rcValues.durationUnit = data.durationUnit;

    this.updateRCValidity();

    this.cdRef.detectChanges();

    //this.logRCValues();
  }

  public onColorChanged( color ) {

    if( this.edition && this.template.color.changesAllowed ) {

      this.rcValues.bgColor = color;

      this.updateRCValidity();

      this.cdRef.detectChanges();

      //this.logRCValues();
    }

  }

  public onTitleChanged( title ) {

    if( this.edition ) {

      this.rcValues.title = title;

      this.updateRCValidity();

      this.cdRef.detectChanges();

      //this.logRCValues();
    }
  }

  public onReactionChanged( reaction ) {

    if( this.edition ) {

      this.rcValues.reaction = reaction;

      this.updateRCValidity();

      this.cdRef.detectChanges();

      //this.logRCValues();

    }
  }

  public onRCInfoFieldChanged( index, data ) {

    this.rcValues.info.fields[index].value = data;

    this.updateRCValidity();

    //this.logRCValues();

  }

  public updateRCValidity() {

    let invalidFields = RCHelper.validateRC( this.rcValues, this.template );

    if( invalidFields && invalidFields.length > 0 ) {

      this.validRC = false;
    } else {

      this.validRC = true;
    }
  }


  // *** actions
  public onCancel() {

    this.onClickCloseModal();
  }

  public onNewRCSubmit() {

    if( this.validRC ) {

      this.launchLoading$( 'guardando nuevo registro')
      .then(() => {

        let newRC = new RC( this.rcValues );

        this.rcsS.saveRC$( newRC )
        .then(() => {

          this.dismissLoading()
          .then(() => {

            let rData = {
              newRC: true
            }

            this.modalCtrl.dismiss(rData);
          });
        }).catch((err) => { throw new Error(err)});

      })
      .catch((err) => { new Error(err) });
    }
  }

  public onUpdateRCSubmit() {

    if( this.validRC ) {

      this.launchLoading$( 'actualizando registro')
      .then(() => {

        let updatedRC = new RC( this.rcValues );

        updatedRC._id = this.rc._id;

        this.rcsS.updateRC$( updatedRC )
        .then(() => {

          this.dismissLoading()
          .then(() => {

              this.modalCtrl.dismiss();
          });
        }).catch((err) => { throw new Error(err)});
      })
      .catch((err) => { new Error(err) });
    }
  }

  public onCopyRCSubmit() {

    this.onNewRCSubmit();
  }


  // *** color
  private getBgColor() {

    let bgColor = this.DEF_BGCOLOR;

    if( this.template.color ) {

      bgColor = this.template.color.bgColor;

    }

    return bgColor;
  }


  // *** title
  public getTitleInfoField() {

    return RCHelper.getTitleInfoField()
  }

  public getReactionInfoField() {

    return RCHelper.getReactionInfoField();
  }


  // *** modal ops
  public onClickCloseModal() {

    this.modalCtrl.dismiss();

  }

  private launchLoading$( msg ) {

    return new Promise((resolve, reject) => {

      this.loadingCtrl.create({
        message: msg
      }).then(
        ( spinner ) => {

          this.loading = spinner;
          this.loading.present();

          resolve();

        }
      );

    })
  }

  private dismissLoading() {

    return new Promise(( resolve, reject ) => {

      this.loading.dismiss()
      .then(
        () => {

          resolve();
        }
      );
    });
  }


  // *** set edition permission
  private initEditionPermission() {

    if( !this.edition ) {

      this.edition = this.profilesS.isCurrentUserEditor();

    }
  }

  // *** helpers
  private logRCValues() {

    console.log( `this.rcValues:
      ${JSON.stringify(this.rcValues )}`);
  }

}
