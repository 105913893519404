// ** IMPORTS *** //
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';

import { Validators, FormBuilder, FormGroup } from '@angular/forms';

import { Storage } from '@ionic/storage';

import { UsersService } from '../../../services/users.service';
import { LoadingService} from '../../../services/loading.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { NavigationService } from '../../../services/navigation.service';

import { User } from '../../../entities/user';
import { Paths } from '../../../navigation/paths';
import { StorageConf } from '../../../storage/storage-conf';


@Component({
  selector: 'app-access-form',
  templateUrl: './access-form.component.html',
  styleUrls: ['./access-form.component.scss'],
})
export class AccessFormComponent implements OnInit {


  public accessForm : FormGroup;
  public submitted: boolean = false;
  private loading;
  private users: User[];


  // ** C ** //
  constructor(

    private formBuilder: FormBuilder,
    private storage: Storage,
    private loadingS: LoadingService,
    public alertCtrl: AlertController,
    private usersS: UsersService,
    private authS: AuthenticationService,
    private navS: NavigationService,
    private router: Router

  ) {

    this.createForm();
    this.setCredentials();

  }

  // ** Life cycle ** //
  ngOnInit() {}


  // ** UI Methods ** //
  public async onSubmit() {

    this.submitted = true;

    if( this.accessForm.invalid ) {

      return;

    } else {

      let us = this.accessForm.value.us;
      let pass = this.accessForm.value.pass;


      this.getCurrentUser( us, pass );


    }


  }

  public async showInvalidCredentials() {

    const alert = await this.alertCtrl.create({
      header: 'Error en credenciales de acceso',
      subHeader: 'REGTEA proto 001',
      message: `El usuario no existe o la contraseña no es válida.
      Solicitanos unas credenciales válidas en regtea@ejemplo.com`,
      buttons: ['OK']
    });

    await alert.present();
  }

  // ** convenience getter for easy access to form fields ** //
  public get f() {
    return this.accessForm.controls;
  }


  // ** USER OPs **//
  private getCurrentUser( us, pass ) {

    this.loadingS.present({
      message: 'Accediendo...'
    }).then(
      () => this.loadCurrentUser(us,pass)
    );

    
  }

  private loadCurrentUser(us,pass) {
    this.usersS.loadCurrentUser$( us, pass )
    .then(
      (response: any) => {

        this.storeCredentials( us, pass );

        this.loadingS.dismiss()
        .then(
          () => {

            let tokenKey = response.auth;

            this.authS.login(tokenKey).then(() => {

              const nextURL = '/' + Paths.MEMBERS + '/' + Paths.PROFILES;

              this.navS.push( nextURL);

              this.router.navigate(['/' + Paths.MEMBERS + '/' + Paths.PROFILES ]);

            });

            
          }
        );
      }
    )
    .catch( err => {

      this.loadingS.dismiss().then();

      if( err.message === 'credenciales no válidas') {

        this.resetForm();

        this.showInvalidCredentials();

      } else {

        let errJson = JSON.stringify(err);

        this.router.navigate(['/' + Paths.ERROR, errJson]);
      }
    });
  }


  // ** Form methods ** //
  private createForm() {

      this.accessForm = this.formBuilder.group({

        us: ['', Validators.required],
        pass: ['', Validators.required]

      });

  }

  private resetForm() {

    this.submitted = false;

    this.accessForm.reset();
  }


  // *** stored credentials
  private setCredentials() {

    this.getStoredCredentials$()
    .then(
      ( credentials: any ) => {

        if( credentials !== null ) {

          if( typeof credentials.us !== 'undefined'
            && typeof credentials.pass !== 'undefined' ) {

              this.accessForm.controls['us'].setValue(credentials.us);
              this.accessForm.controls['pass'].setValue(credentials.pass);
            }
        }

      }
    )
    .catch( (err) => {
      console.error('Error al obtener las credenciales: ' + JSON.stringify(err) );
    })

  }

  private getStoredCredentials$() {

    return new Promise(( resolve, reject ) => {

      let credentials = null;

      this.storage.get(StorageConf.USER_NAME_KEY)
      .then(
        ( us ) => {

          this.storage.get( StorageConf.PASSWORD_KEY )
          .then(
            ( pass ) => {

              credentials = {
                us: us,
                pass: pass
              }

              resolve( credentials );
            }
          )
          .catch( (err) => { reject(err)});

        }
      )
      .catch(( err ) => { reject(err)})


    })


  }

  private storeCredentials( us, pass ) {

    this.storage.set( StorageConf.USER_NAME_KEY, us );
    this.storage.set( StorageConf.PASSWORD_KEY, pass );

  }

}
