import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { NavigationService } from '../../../services/navigation.service';

@Component({
  selector: 'app-profile-header',
  templateUrl: './profile-header.component.html',
  styleUrls: ['./profile-header.component.scss'],
})
export class ProfileHeaderComponent implements OnInit, OnDestroy {

  private previousURL: string = '';
  private $previousURL: Subscription;
  private currentURL: string = '';
  private $currentURL: Subscription;


  // *** C *** //
  constructor(
    private navS: NavigationService
  ) {


  }


  // *** Lifecycle events ** //
  ngOnInit() {

    this.setNavEventHandlers();
  }

  ngOnDestroy() {

        // unsubscribe to ensure no memory leaks
        this.$previousURL.unsubscribe();
        this.$currentURL.unsubscribe();
  }


  // *** PRIVATE *** //
  private setNavEventHandlers() {

    // src: https://jasonwatmore.com/post/2019/02/07/angular-7-communicating-between-components-with-observable-subject

    // on previousURL changes
    this.$previousURL = this.navS
    .getPreviousURLObservable()
    .subscribe( url => {
      this.onPreviousURLChange( url );
    });



    // on Current URL changes
    this.$currentURL = this.navS
    .getCurrentURLObservable()
    .subscribe( url => {
      this.onCurrentURLChange( url );
    });

  }


  private onPreviousURLChange( url ) {

    if ( url ) {
      this.previousURL = url;
    }
  }

  private onCurrentURLChange( url ) {

    if ( url ) {
      this.currentURL = url;
    }
  }
}
