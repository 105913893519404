import{ Behavior } from './behavior';
import{ BehaviorType } from './behavior-type';

export class BehaviorByType {

  static BEHAVIORS = {

    [BehaviorType.SELF_INJURIOUS]: [
      Behavior.BITEHIMSELF,
      Behavior.HIT_HIS_EYES,
      Behavior. HIT_HIS_HEAD,
      Behavior.HIT_HIS_HEAD_WITH_OBJECT,
      Behavior.HIT_HIMSELF,
      Behavior.PULL_HIS_HAIR,
      Behavior.HORN_HIMSELF,
      Behavior.PINCH_HIMSELF,
      Behavior.POUND_HIMSELF

    ],
    [BehaviorType.AGGRESIVE]: [
      Behavior.BITE,
      Behavior.GRAB_TIGHT,
      Behavior.PULL_OTHERS_HAIR,
      Behavior.BUTT_OTHER,
      Behavior.HIT_OTHER,
      Behavior.KICK_OTHER,
      Behavior.PINCH_OTHER,
      Behavior.PUSH_OTHER,
      Behavior.HORN_OTHER,
      Behavior.POUND_OTHER,
      Behavior.THROW_OBJECT_TO_OTHER
    ],
    [BehaviorType.DISTURBANCE]: [
      Behavior.HIT_OBJECTS,
      Behavior.REMOVE_OBJECTS_FROM_TABLE,
      Behavior.KICK_OBJECTS,
      Behavior.SCREAM,
      Behavior.BAWL,
      Behavior.BREAK_OBJECTS,
      Behavior.THROW_OBJECTS


    ],
    [BehaviorType.NON_COMPLIANCE]: [
      Behavior.SCREECH_WHEN_ASKED,
      Behavior.BECOME_AGGRESSIVE_WHEN_ASKED,
      Behavior.UPSET_WHEN_ASKED,
      Behavior.GET_DOWN_ON_THE_FLOOR,
      Behavior.HARM_HIMSELF_WHEN_ASKED,
    ],
    [BehaviorType.SELF_STIMULATION]: [],

  }
}
