import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams }  from '@ionic/angular';
import { Router } from '@angular/router';

import { Guide } from '../../../entities/guide';
import { GuideClass } from '../../../entities/guide-class';
import { GuideContent } from '../../../entities/guide-content';
import { GuidesService } from '../../../services/guides.service';

import { Paths } from '../../../navigation/paths';

import { LoadingController } from '@ionic/angular';

@Component({
  selector: 'app-guide-modal',
  templateUrl: './guide-modal.component.html',
  styleUrls: ['./guide-modal.component.scss'],
})
export class GuideModalComponent implements OnInit {

  // *** guide
  public guide: Guide;
  private guideClass: number = GuideClass.UNDEFINED;

  public guideContents: GuideContent[] = [];

  // *** icon
  public icon: string = 'cubes';

  // *** loading
  private loading;

  // *** C
  constructor(
    private router: Router,
    private modalCtrl: ModalController,
    private navParams: NavParams,
    private guidesS: GuidesService,
    public loadingCtrl: LoadingController
  ) { }


  // *** Lifecycle
  ngOnInit() {


    this.readModalParams();
    this.setClass();
    this.setIcon();

    this.getGuideContents();
  }


  // *** modal params
  private readModalParams() {

    this.guide = this.navParams.get('guide');

  }


  // *** Guide type
  private setClass() {

    this.guideClass = this.guide.class;
  }

  private setIcon() {

    switch( this.guideClass ) {

      case GuideClass.UNDEFINED:
        this.icon = 'other-guides';
        break;

      case GuideClass.BEHAVIOUR:
        this.icon = 'behaviour';
        break;

      case GuideClass.EDUCATION:
        this.icon = 'education';
        break;

      case GuideClass.RELATIONS:
        this.icon = 'relations';
        break;

    }

  }


  // *** guide content
  private getGuideContents() {

    this.loadingCtrl.create({
      message: 'cargando guias...'
    }).then(
      ( spinner ) => {

        this.loading = spinner;
        this.loading.present();

      }
    );

    this.guidesS.getGuideContents$( this.guide.ref )
    .then(
      ( guideContents: GuideContent[] ) => {

        this.guideContents = guideContents;

        setTimeout(
          () => {

            this.loading.dismiss();
          },
        1000 );

      }
    )
    .catch(
      ( err ) => {

        let errJson = JSON.stringify(err);

        setTimeout(
          () => {

            this.loading.dismiss();

            this.router.navigate(['/' + Paths.ERROR, errJson]);
          },
        1000 );


      }
    );
  }


  // *** modal ops
  public onClickCloseModal() {
    this.modalCtrl.dismiss();
  }
}
