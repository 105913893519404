export class ProfileProgressState {
  static UNDEFINED = -1;
  static LOW = 0
  static MEDIUM = 1;
  static HIGHT = 2;
  static EXCELENT = 3;

  static UPDATE_NEEDED = 0;
  static REVIEW_NEEDED = 1;
  static UPDATED = 3;
}
