import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { RCTemplatesService } from '../../../services/rc-templates.service';
import { RCTemplatesCacheService } from '../../../cache/rc-templates-cache.service';
import { RCTTag } from '../../../entities/rct-tag';
import { RCTemplate } from '../../../entities/rc-template';
import { ProfilesService } from '../../../services/profiles.service';
import { RCUI } from '../rc-ui';

@Component({
  selector: 'app-rc-creation-actions',
  templateUrl: './rc-creation-actions.component.html',
  styleUrls: ['./rc-creation-actions.component.scss'],
})
export class RCCreationActionsComponent implements OnInit {

  @Input() rcTag: RCTTag;
  @Input() mode: number;

  @Output() modalReq$ = new EventEmitter<any>();

  public rcTemplates: RCTemplate[];

  public title;
  public availableTag: boolean = false;
  public availableMode: boolean = false;


  // *** C
  constructor(
    private rcTemplatesS: RCTemplatesService,
    private rcTemplatesCache: RCTemplatesCacheService,
    private profilesS: ProfilesService
  ) { }


  // *** Lifecycle
  ngOnInit() {

    this.init();

  }



  // *** initialization
  private init() {

    if( typeof this.rcTag !== 'undefined' && this.rcTag !== null ) {

      this.initUsingRCTag();

    } else if( typeof this.mode !== 'undefined' && this.mode !== null ) {

      this.initUsingMode();
    }
  }


  // *** initialization using tag
  private initUsingRCTag() {

    this.availableTag = true;

    this.title = this.rcTag.label;

    this.rcTemplates = this.rcTemplatesCache.getTemplates(this.rcTag);

    this.hideNotGrantedTemplates();


    /*
    if( !this.rcTemplatesCache.containsTag( this.rcTag.ref )) {

      this.loadTemplatesUsinRCTag();

    } else {

      this.rcTemplates = this.rcTemplatesCache.getTemplates(this.rcTag.ref);

    }*/



  }


  private hideNotGrantedTemplates() {

    this.rcTemplates.forEach(( template: RCTemplate ) => {

      if( !template.defaultTemplate && template.profileID ) {


        if( template.profileID !== this.profilesS.getCurrentProfileID()) {

          console.log('HIDDING TEMPLATE --> ' + template.ref );
        }

      }

    });

  }


  // *** initialization using mode
  public initUsingMode() {

    this.availableMode = true;

    switch( this.mode ) {

      case RCUI.MODES.LAST:
        this.initUsingModeLast();
        break;
      case RCUI.MODES.MOST_USED:
        this.initUsingModeMostUsed();
        break;
    }
  }

  private initUsingModeLast() {

      this.title = 'Últimos registros creados';

  }

  private initUsingModeMostUsed() {

    this.title = 'Registros más usados';
  }


  // *** templates
  private loadTemplatesUsinRCTag() {

    this.rcTemplatesS.getRCTemplatesByTag$( this.rcTag )
    .then(
      ( templates: RCTemplate[] ) => {

        this.rcTemplates = templates;

        this.rcTemplatesCache.addTemplates( this.rcTag, templates );
      }
    ).catch((err) => {

      throw new Error(err);
    });
  }


  // *** modal req
  public onModalReq( event ) {

    this.modalReq$.emit( event );
  }

}
